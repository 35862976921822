/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Action": [
      "ApiCallAction",
      "ApiCallGraphqlAction",
      "CalculationAction",
      "ChecklistAction",
      "ClinicalNoteAction",
      "ExtensionAction",
      "FormAction",
      "MessageAction",
      "PushToEmrAction"
    ],
    "ExtensionActionField": [
      "BooleanField",
      "DateField",
      "HtmlField",
      "JsonField",
      "NumericArrayField",
      "NumericField",
      "StringArrayField",
      "StringField",
      "TextField"
    ],
    "PaginationAndSortingPayload": [
      "ActivitiesPayload"
    ],
    "Payload": [
      "ActionPayload",
      "ActivateTriggerPayload",
      "AddActionToStepPayload",
      "AddChecklistToStepPayload",
      "AddConditionToRulePayload",
      "AddConstantPayload",
      "AddDataPointDefinitionToPathwayPayload",
      "AddDataPointMappingToExtensionActionPayload",
      "AddDerivedDataPointDefinitionPayload",
      "AddLabelToStepPayload",
      "AddLocationToPathwayPayload",
      "AddMappingToApiCallPayload",
      "AddMessageToStepPayload",
      "AddQuestionToFormPayload",
      "AddQuestionsToFormPayload",
      "AddRuleToQuestionPayload",
      "AddStepFromLibraryToTrackPayload",
      "AddStepToLibraryPayload",
      "AddStepToTrackPayload",
      "AddStickyNoteToTrackPayload",
      "AddTrackToPathwayPayload",
      "AddTransformationToDynamicVariablePayload",
      "AddTriggerToPathwayPayload",
      "AddTriggerToTrackPayload",
      "AddWebhookPayload",
      "AhpLinkPayload",
      "AhpLinkPayloads",
      "BaselineInfoPayload",
      "CalculationResultPayload",
      "CalculationsPayload",
      "CareflowComponentsPayload",
      "CheckpointPayload",
      "CheckpointsPayload",
      "ClearActionDueDatePayload",
      "ClearActionIdleTimePayload",
      "ClearTimingDelayPayload",
      "ClearTimingReferencePayload",
      "ClinicalNotePayload",
      "ConfigureExtensionPayload",
      "ConnectStepsPayload",
      "ConstantPayload",
      "ConstantsPayload",
      "CreateDynamicVariablePayload",
      "CreateFormPayload",
      "CreateMessagePayload",
      "CreatePathwayCasePayload",
      "CreatePathwayPayload",
      "CreateTransitionPayload",
      "DataCatalogJsonPayload",
      "DataCatalogPayload",
      "DataPointDefinitionPayload",
      "DataPointDefinitionsPayload",
      "DecisionOutputsPayload",
      "DecisionPayload",
      "DecisionsPayload",
      "DeletePathwayCasePayload",
      "DomainEventsPayload",
      "DuplicateCareflowPayload",
      "DuplicateQuestionPayload",
      "DuplicateStepPayload",
      "DynamicVariablePayload",
      "DynamicVariablesPayload",
      "ElementsPayload",
      "EmptyPayload",
      "EmrReportPayload",
      "EndTrackAfterStepPayload",
      "EvaluatedRulePayload",
      "ExecuteTestApiCallPayload",
      "ExtensionActivityRecordPayload",
      "ExtensionConfigurationsPayload",
      "ExtensionsPayload",
      "FileStorageConfigPayload",
      "FormPayload",
      "FormResponsePayload",
      "FormsPayload",
      "GeneratedClinicalNotePayload",
      "GeneratedEmrReportPayload",
      "GeneratedMessagePayload",
      "JsonPathPayload",
      "LocationsPayload",
      "MarkReleaseAsLivePayload",
      "MessagePayload",
      "OntologyNodePayload",
      "OntologyPayload",
      "PathwayCaseApiCallPayload",
      "PathwayCaseApiCallsPayload",
      "PathwayCasePayload",
      "PathwayCaseWebhookCallPayload",
      "PathwayCaseWebhookCallsPayload",
      "PathwayCasesPayload",
      "PathwayDiffPayload",
      "PathwayPayload",
      "PathwaySettingsPayload",
      "PathwayValidationStatusPayload",
      "PathwayVersionPayload",
      "PathwayVersionsPayload",
      "PathwaysPayload",
      "PublishPathwayPayload",
      "ReadMessagePayload",
      "RebuildViewModelsPayload",
      "RefreshExtensionPayload",
      "RemoveActionFromStepPayload",
      "RemoveClinicalNoteNarrativePayload",
      "RemoveConditionFromRulePayload",
      "RemoveConstantPayload",
      "RemoveDataPointMappingFromExtensionActionPayload",
      "RemoveLabelsFromStepPayload",
      "RemoveMappingFromApiCallPayload",
      "RemoveQuestionFromFormPayload",
      "RemoveRuleFromQuestionPayload",
      "RemoveStepFromTrackPayload",
      "RemoveTrackFromPathwayPayload",
      "RemoveTransformationsFromDynamicVariablePayload",
      "RemoveTransitionPayload",
      "RemoveWebhookPayload",
      "ReorderActionsPayload",
      "ReorderQuestionsPayload",
      "RepairPathwaysPayload",
      "ResetPreviewPayload",
      "RetryWebhookCallPayload",
      "RulePayload",
      "SchemaPayload",
      "SetActionCalculationIdPayload",
      "SetActionCalculationInputPayload",
      "SetActionChecklistItemsPayload",
      "SetActionDueDatePayload",
      "SetActionFormDisplayModePayload",
      "SetActionFormIdPayload",
      "SetActionIdleTimePayload",
      "SetActionMessageIdPayload",
      "SetActionStakeholdersPayload",
      "SetActionTitlePayload",
      "SetApiCallBodyPayload",
      "SetApiCallEndpointPayload",
      "SetApiCallHeadersPayload",
      "SetApiCallMethodPayload",
      "SetBrandingAccentColorPayload",
      "SetBrandingCustomThemePayload",
      "SetBrandingHostedPageAutoProgressPayload",
      "SetBrandingHostedPageAutosavePayload",
      "SetBrandingHostedPageTitlePayload",
      "SetBrandingLogoUrlPayload",
      "SetConditionOperandPayload",
      "SetConditionOperatorPayload",
      "SetConditionReferencePayload",
      "SetCustomActionFieldPayload",
      "SetDataPointDefinitionMetaDataFieldPayload",
      "SetDataPointDefinitionOptionalFieldPayload",
      "SetDataPointDefinitionPiiFieldPayload",
      "SetDateQuestionConfigPayload",
      "SetDynamicVariableDataPointDefinitionPayload",
      "SetDynamicVariableDataPointPropertyPayload",
      "SetDynamicVariableFallbackPayload",
      "SetDynamicVariableLabelPayload",
      "SetExtensionActionDataPointMappingDataPointPayload",
      "SetExtensionActionDataPointMappingKeyPayload",
      "SetExtensionActionDataPointMappingsPayload",
      "SetExtensionActionFieldPayload",
      "SetFileQuestionConfigPayload",
      "SetFormKeyPayload",
      "SetFormMetadataPayload",
      "SetFormTitlePayload",
      "SetFormTrademarkPayload",
      "SetMultipleSelectQuestionConfigPayload",
      "SetNumberQuestionConfigPayload",
      "SetPathwayCaseTitlePayload",
      "SetPathwayTitlePayload",
      "SetPhoneQuestionConfigPayload",
      "SetQuestionConfigPayload",
      "SetQuestionKeyPayload",
      "SetQuestionMetadataPayload",
      "SetQuestionOptionValueTypePayload",
      "SetQuestionsConfigPayload",
      "SetRemindersAmountPayload",
      "SetRemindersDelayPayload",
      "SetRuleBooleanOperatorPayload",
      "SetRuleTypePayload",
      "SetSliderQuestionConfigPayload",
      "SetStakeholderEmailsPayload",
      "SetStepCoordinatesPayload",
      "SetStepDocumentationPayload",
      "SetStepTitlePayload",
      "SetStickyNoteCoordinatesPayload",
      "SetTimingDelayPayload",
      "SetTimingReferencePayload",
      "SetTrackEndCoordinatesPayload",
      "SetTrackStartCoordinatesPayload",
      "SetTrackTitlePayload",
      "SetTransformationParamsPayload",
      "SetTransformationTypePayload",
      "SetTransitionDestinationPayload",
      "StakeholdersPayload",
      "StartPreviewPayload",
      "StartTrackFromStepPayload",
      "StartTransitionFromStepPayload",
      "StepLibraryPayload",
      "StepPayload",
      "StepsPayload",
      "StickyNotePayload",
      "StickyNotesPayload",
      "SubmitChecklistPayload",
      "SubmitFormResponsePayload",
      "TenantPayload",
      "TenantsPayload",
      "TestSourceControlPayload",
      "TimerPayload",
      "TimersPayload",
      "TimingPayload",
      "TrackPayload",
      "TracksPayload",
      "TransitionPayload",
      "TransitionsPayload",
      "TriggerActivationPayload",
      "TriggerActivationsPayload",
      "TriggerApiCallPayload",
      "TriggerPayload",
      "UpdateApiCallMappingDataPointPayload",
      "UpdateApiCallMappingFirstMatchOnlyPayload",
      "UpdateApiCallMappingKeyPayload",
      "UpdateCareFlowApiCallRetrySettingsPayload",
      "UpdateCareFlowSourceControlSettingsPayload",
      "UpdateClinicalNoteContextPayload",
      "UpdateConstantPayload",
      "UpdateDataPointDefinitionPayload",
      "UpdateDynamicVariablePayload",
      "UpdateEmailNotificationStatusPayload",
      "UpdateLabelTextPayload",
      "UpdateQuestionPayload",
      "UpdateStickyNoteBodyPayload",
      "UpdateWebhookEndpointPayload",
      "UpdateWebhookHeadersPayload",
      "UpdateWebhookNamePayload",
      "UpdateWebhookPayload",
      "UpdateWebhookStatusPayload",
      "UpdateWebhookSubscribedEventsPayload",
      "UpdateWebhookTestEndpointPayload",
      "UserPayload",
      "WebhookPayload"
    ],
    "TimerConfig": [
      "DataPointReferenceTimerConfig",
      "DelayTimerConfig",
      "ExtensionResourceUpdatedTimerConfig"
    ],
    "Transformation": [
      "AddDurationTransformation",
      "AddPrefixTransformation",
      "AddSuffixTransformation",
      "AddWeekdaysTransformation",
      "FormatDateTransformation",
      "FormatPhoneNumberTransformation",
      "RoundToTransformation",
      "SubtractDurationTransformation"
    ],
    "TriggerActivationReference": [
      "AdHocActivationReference",
      "DataPointCollectedActivationReference",
      "PathwayStartedActivationReference",
      "StepCompletedActivationReference",
      "TrackCompletedActivationReference",
      "TrackStartedActivationReference"
    ],
    "TriggerSettings": [
      "AdHocTriggerSettings",
      "DataPointCollectedTriggerSettings",
      "DataPointNotCollectedTriggerSettings",
      "DesignatedTriggerTimer",
      "EventBasedTriggerSettings",
      "ExtensionWebhookTriggerSettings",
      "PathwayStartedTriggerSettings",
      "StepCompletedTriggerSettings",
      "TrackCompletedTriggerSettings"
    ]
  }
};
      export default result;
    
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** Safe date scalar that can serialize string or date */
  SafeDate: any;
};

export type Action = {
  clinical_note_id?: Maybe<Scalars['ID']>;
  created: AuditTrail;
  due_date?: Maybe<DueDate>;
  emr_report_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  idle_time?: Maybe<Delay>;
  last_updated?: Maybe<AuditTrail>;
  locations?: Maybe<Array<Location>>;
  message_id?: Maybe<Scalars['ID']>;
  plugin_key?: Maybe<Scalars['String']>;
  stakeholders: Array<Stakeholder>;
  status: ConfigurationStatus;
  step: Step;
  title?: Maybe<Scalars['String']>;
  type: ActionType;
};

export type ActionComponent = {
  __typename?: 'ActionComponent';
  definition_id?: Maybe<Scalars['String']>;
  release_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ActionCustomFieldInput = {
  id: Scalars['String'];
  value: Scalars['String'];
};

export type ActionDueDateInput = {
  action_id: Scalars['String'];
  due_date?: Maybe<DueDateInput>;
};

export type ActionPayload = Payload & {
  __typename?: 'ActionPayload';
  /**
   * An action is a ? TBD ? at a specific date.
   * Actions are caused by a human(manual) or system(automated).
   * Something or someone causing actions is referred to as an agent (https://english.stackexchange.com/a/96105).
   * Action only exist in a context of a step.
   * By humans we mean patients or care teams.
   * By system we mean the Awell engine.
   * Typical actions done by humans are filing in forms and checklists.
   * Typical actions done by systems are calculations and sending messages.
   *
   * An action definition is the set of information needed to perform an action.
   * information can be:
   * - static
   * - dynamic
   *
   * Information is static when it is not expected to change during the time a pathway is active
   * Examples of static information:
   * - constants. e.g., specific email "foo@gmail.com" in a send message action
   * - gender of the patient
   * - name of the organisation
   * - preferences of the organisation like preferred reminder channel
   *
   * Information is dynamic when it is collected during an execution:
   * Examples of dynamic information:
   * - Form submissions
   * - step completion and activation dates
   * - Calculation results
   */
  action: Action;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export enum ActionType {
  ApiCall = 'API_CALL',
  ApiCallGraphql = 'API_CALL_GRAPHQL',
  Calculation = 'CALCULATION',
  Checklist = 'CHECKLIST',
  ClinicalNote = 'CLINICAL_NOTE',
  Form = 'FORM',
  Message = 'MESSAGE',
  Plugin = 'PLUGIN',
  PushToEmr = 'PUSH_TO_EMR'
}

export type ActivateTriggerInput = {
  trigger_activation_id: Scalars['String'];
};

export type ActivateTriggerPayload = Payload & {
  __typename?: 'ActivateTriggerPayload';
  activated: Scalars['Boolean'];
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  evaluated_rule_id?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ActivitiesDeleted = {
  __typename?: 'ActivitiesDeleted';
  activity_ids: Array<Scalars['String']>;
};

export type ActivitiesPayload = PaginationAndSortingPayload & {
  __typename?: 'ActivitiesPayload';
  activities: Array<Activity>;
  code: Scalars['String'];
  metadata?: Maybe<ActivityMetadata>;
  pagination?: Maybe<PaginationOutput>;
  sorting?: Maybe<SortingOutput>;
  success: Scalars['Boolean'];
};

export type Activity = {
  __typename?: 'Activity';
  action: ActivityAction;
  action_component?: Maybe<ActionComponent>;
  container_name?: Maybe<Scalars['String']>;
  context?: Maybe<PathwayContext>;
  date: Scalars['String'];
  /** Url for icon, only used by extensions custom actions */
  icon_url?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  indirect_object?: Maybe<ActivityObject>;
  isUserActivity: Scalars['Boolean'];
  label?: Maybe<ActivityLabel>;
  metadata?: Maybe<Scalars['JSON']>;
  object: ActivityObject;
  public?: Maybe<Scalars['Boolean']>;
  reference_id: Scalars['String'];
  resolution?: Maybe<ActivityResolution>;
  session_id?: Maybe<Scalars['String']>;
  stakeholders?: Maybe<Array<ActivityObject>>;
  status: ActivityStatus;
  stream_id: Scalars['String'];
  sub_activities: Array<SubActivity>;
  subject: ActivitySubject;
  track?: Maybe<ActivityTrack>;
};

export enum ActivityAction {
  Activate = 'ACTIVATE',
  Added = 'ADDED',
  Assigned = 'ASSIGNED',
  Complete = 'COMPLETE',
  Computed = 'COMPUTED',
  Delegated = 'DELEGATED',
  Deliver = 'DELIVER',
  Discarded = 'DISCARDED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  FailedToSend = 'FAILED_TO_SEND',
  Generated = 'GENERATED',
  IsWaitingOn = 'IS_WAITING_ON',
  Postponed = 'POSTPONED',
  Processed = 'PROCESSED',
  Read = 'READ',
  Remind = 'REMIND',
  Reported = 'REPORTED',
  Scheduled = 'SCHEDULED',
  Send = 'SEND',
  Skipped = 'SKIPPED',
  Stopped = 'STOPPED',
  Submitted = 'SUBMITTED'
}

export type ActivityLabel = {
  __typename?: 'ActivityLabel';
  color: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  text: Scalars['String'];
};

export type ActivityMetadata = {
  __typename?: 'ActivityMetadata';
  stakeholders?: Maybe<Array<ActivityObject>>;
};

export type ActivityObject = {
  __typename?: 'ActivityObject';
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  preferred_language?: Maybe<Scalars['String']>;
  type: ActivityObjectType;
};

export enum ActivityObjectType {
  Action = 'ACTION',
  ApiCall = 'API_CALL',
  Calculation = 'CALCULATION',
  Checklist = 'CHECKLIST',
  ClinicalNote = 'CLINICAL_NOTE',
  Decision = 'DECISION',
  EmrReport = 'EMR_REPORT',
  EmrRequest = 'EMR_REQUEST',
  EvaluatedRule = 'EVALUATED_RULE',
  Form = 'FORM',
  Message = 'MESSAGE',
  Pathway = 'PATHWAY',
  Patient = 'PATIENT',
  Plugin = 'PLUGIN',
  PluginAction = 'PLUGIN_ACTION',
  Reminder = 'REMINDER',
  Stakeholder = 'STAKEHOLDER',
  Step = 'STEP',
  Timer = 'TIMER',
  Track = 'TRACK',
  User = 'USER'
}

export enum ActivityResolution {
  Expired = 'EXPIRED',
  Failure = 'FAILURE',
  Success = 'SUCCESS'
}

export enum ActivityStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Done = 'DONE',
  Expired = 'EXPIRED',
  Failed = 'FAILED'
}

export type ActivitySubject = {
  __typename?: 'ActivitySubject';
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type: ActivitySubjectType;
};

export enum ActivitySubjectType {
  ApiCall = 'API_CALL',
  Awell = 'AWELL',
  Plugin = 'PLUGIN',
  Stakeholder = 'STAKEHOLDER',
  User = 'USER'
}

export type ActivityTrack = {
  __typename?: 'ActivityTrack';
  id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type AdHocActivationReference = TriggerActivationReference & {
  __typename?: 'AdHocActivationReference';
  type?: Maybe<TriggerActivationReferenceType>;
};

export type AdHocTriggerSettings = TriggerSettings & {
  __typename?: 'AdHocTriggerSettings';
  rule?: Maybe<Rule>;
  type?: Maybe<TriggerType>;
};

export type AddActionFromTemplateInput = {
  calculation_id?: Maybe<Scalars['String']>;
  extension?: Maybe<ExtensionActionInput>;
  stakeholder_id?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  type: ActionType;
};

export type AddActionToStepInput = {
  custom_action_id?: Maybe<Scalars['String']>;
  extension?: Maybe<ExtensionActionInput>;
  step_id: Scalars['String'];
  title: Scalars['String'];
  type: ActionType;
};

export type AddActionToStepPayload = Payload & {
  __typename?: 'AddActionToStepPayload';
  action: Action;
  api_call?: Maybe<ApiCall>;
  code: Scalars['String'];
  emr_report?: Maybe<EmrReport>;
  error?: Maybe<ErrorObject>;
  message?: Maybe<Message>;
  step: Step;
  success: Scalars['Boolean'];
};

export type AddAttachmentInput = {
  name: Scalars['String'];
  type: MessageAttachmentType;
  url: Scalars['String'];
};

export type AddChecklistToStepInput = {
  checklist: Array<Scalars['String']>;
  stakeholder_ids: Array<Scalars['String']>;
  step_id: Scalars['String'];
  /** Action title */
  title?: Maybe<Scalars['String']>;
};

export type AddChecklistToStepPayload = Payload & {
  __typename?: 'AddChecklistToStepPayload';
  action: ChecklistAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export type AddConditionToRuleInput = {
  condition?: Maybe<ConditionInput>;
  rule_id: Scalars['String'];
};

export type AddConditionToRulePayload = Payload & {
  __typename?: 'AddConditionToRulePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  rule: Rule;
  success: Scalars['Boolean'];
};

export type AddConstantInput = {
  constant: NewConstantInput;
  pathway_id: Scalars['String'];
};

export type AddConstantPayload = Payload & {
  __typename?: 'AddConstantPayload';
  code: Scalars['String'];
  constant: Constant;
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type AddCustomActionToPathwayInput = {
  custom_action: CustomActionInput;
  pathway_id: Scalars['String'];
};

export type AddCustomFieldToPathwayInput = {
  labels: Array<LabelInput>;
  pathway_id: Scalars['String'];
};

export type AddDataPointDefinitionInput = {
  category: DataPointSourceType;
  jsonPath?: Maybe<Scalars['String']>;
  jsonSchemaSource?: Maybe<JsonSchemaSourceGraphqlTypeInput>;
  key: Scalars['String'];
  optional?: Maybe<Scalars['Boolean']>;
  possibleValues?: Maybe<Array<DataPointPossibleValueInput>>;
  range?: Maybe<RangeInput>;
  unit?: Maybe<Scalars['String']>;
  valueType: DataPointValueType;
};

export type AddDataPointDefinitionToPathwayInput = {
  data_point_definition: AddDataPointDefinitionInput;
  pathway_id: Scalars['String'];
};

export type AddDataPointDefinitionToPathwayPayload = Payload & {
  __typename?: 'AddDataPointDefinitionToPathwayPayload';
  code: Scalars['String'];
  data_point_definition: DataPointDefinition;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type AddDataPointMappingToExtensionActionInput = {
  action_id: Scalars['String'];
};

export type AddDataPointMappingToExtensionActionPayload = Payload & {
  __typename?: 'AddDataPointMappingToExtensionActionPayload';
  action: ExtensionAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type AddDecisionToTrackInput = {
  coordinates: CoordinatesInput;
  decision?: Maybe<Scalars['String']>;
  track_id: Scalars['String'];
  type: DecisionType;
};

export type AddDerivedDataPointDefinitionInput = {
  derived_data_point_definition: DerivedDataPointDefinitionInput;
  pathway_id: Scalars['String'];
};

export type AddDerivedDataPointDefinitionPayload = Payload & {
  __typename?: 'AddDerivedDataPointDefinitionPayload';
  code: Scalars['String'];
  data_point_definition: DataPointDefinition;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type AddDurationParameters = {
  __typename?: 'AddDurationParameters';
  duration?: Maybe<DurationGraphQlObjectType>;
};

export type AddDurationTransformation = Transformation & {
  __typename?: 'AddDurationTransformation';
  id: Scalars['ID'];
  parameters?: Maybe<AddDurationParameters>;
  type: TransformationType;
};

export type AddLabelToStepInput = {
  label_id: Scalars['String'];
  step_id: Scalars['String'];
};

export type AddLabelToStepPayload = Payload & {
  __typename?: 'AddLabelToStepPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export type AddLocationToPathwayInput = {
  label: Scalars['String'];
  pathway_id: Scalars['String'];
};

export type AddLocationToPathwayPayload = Payload & {
  __typename?: 'AddLocationToPathwayPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  location: Location;
  success: Scalars['Boolean'];
};

export type AddMappingToApiCallInput = {
  api_call_id: Scalars['String'];
  data_point_definition_id?: Maybe<Scalars['String']>;
  response_key: Scalars['String'];
};

export type AddMappingToApiCallPayload = Payload & {
  __typename?: 'AddMappingToApiCallPayload';
  api_call: ApiCall;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type AddMessageAttachmentInput = {
  attachment: AddAttachmentInput;
  message_id: Scalars['String'];
};

export type AddMessageToStepInput = {
  /** Message body in either Slate or HTML format */
  body?: Maybe<MessageBodyInput>;
  stakeholder_ids: Array<Scalars['String']>;
  step_id: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
  /** Action title */
  title?: Maybe<Scalars['String']>;
};

export type AddMessageToStepPayload = Payload & {
  __typename?: 'AddMessageToStepPayload';
  action: MessageAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  message?: Maybe<Message>;
  step: Step;
  success: Scalars['Boolean'];
};

export type AddNarrativeToClinicalNoteInput = {
  clinical_note_id: Scalars['String'];
  key: Scalars['String'];
  title: Scalars['String'];
};

export type AddPrefixParameters = {
  __typename?: 'AddPrefixParameters';
  prefix?: Maybe<Scalars['String']>;
};

export type AddPrefixTransformation = Transformation & {
  __typename?: 'AddPrefixTransformation';
  id: Scalars['ID'];
  parameters?: Maybe<AddPrefixParameters>;
  type: TransformationType;
};

export type AddQuestionToFormInput = {
  /** The ID of the form to add the question to. */
  form_id: Scalars['String'];
  /** Use this if you want to add the question at a specific position in the form. When this is not provided, the question will be added at the end of the form. */
  previous_question_id?: Maybe<Scalars['String']>;
  /** The type of question to add. */
  user_question_type: UserQuestionType;
};

export type AddQuestionToFormPayload = Payload & {
  __typename?: 'AddQuestionToFormPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  form: Form;
  question: Question;
  success: Scalars['Boolean'];
};

export type AddQuestionsToFormInput = {
  /** The ID of the form to add the questions to. */
  form_id: Scalars['String'];
  /** The list of questions to add to the form. */
  questions: Array<QuestionInput>;
};

export type AddQuestionsToFormPayload = Payload & {
  __typename?: 'AddQuestionsToFormPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  form: Form;
  questions: Array<Question>;
  success: Scalars['Boolean'];
};

export type AddRuleToQuestionInput = {
  form_id: Scalars['String'];
  question_id: Scalars['String'];
};

export type AddRuleToQuestionPayload = Payload & {
  __typename?: 'AddRuleToQuestionPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  rule: Rule;
  success: Scalars['Boolean'];
};

export type AddStepFromLibraryToTrackInput = {
  coordinates: CoordinatesInput;
  step_id: Scalars['String'];
  track_id: Scalars['String'];
};

export type AddStepFromLibraryToTrackPayload = Payload & {
  __typename?: 'AddStepFromLibraryToTrackPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export type AddStepFromTemplateInput = {
  actions: Array<AddActionFromTemplateInput>;
  coordinates: CoordinatesInput;
  title: Scalars['String'];
  track_id: Scalars['String'];
};

export type AddStepToLibraryInput = {
  step_id: Scalars['String'];
};

export type AddStepToLibraryPayload = Payload & {
  __typename?: 'AddStepToLibraryPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export type AddStepToTrackInput = {
  coordinates: CoordinatesInput;
  track_id: Scalars['String'];
};

export type AddStepToTrackPayload = Payload & {
  __typename?: 'AddStepToTrackPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export type AddStickyNoteToTrackInput = {
  body?: Maybe<StickyNoteBodyInput>;
  coordinates: CoordinatesInput;
  track_id: Scalars['String'];
};

export type AddStickyNoteToTrackPayload = Payload & {
  __typename?: 'AddStickyNoteToTrackPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  stickyNote: StickyNote;
  success: Scalars['Boolean'];
};

export type AddSuffixParameters = {
  __typename?: 'AddSuffixParameters';
  suffix?: Maybe<Scalars['String']>;
};

export type AddSuffixTransformation = Transformation & {
  __typename?: 'AddSuffixTransformation';
  id: Scalars['ID'];
  parameters?: Maybe<AddSuffixParameters>;
  type: TransformationType;
};

export type AddTimerToTrackInput = {
  coordinates: CoordinatesInput;
  track_id: Scalars['String'];
};

export type AddTrackInput = {
  pathway_case_id: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  track_definition_id: Scalars['String'];
};

export type AddTrackToPathwayInput = {
  pathway_id: Scalars['String'];
  title: Scalars['String'];
};

export type AddTrackToPathwayPayload = Payload & {
  __typename?: 'AddTrackToPathwayPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  track: Track;
};

export type AddTransformationToDynamicVariableInput = {
  dynamic_variable_id: Scalars['String'];
  type: TransformationType;
};

export type AddTransformationToDynamicVariablePayload = Payload & {
  __typename?: 'AddTransformationToDynamicVariablePayload';
  code: Scalars['String'];
  dynamic_variable: DynamicVariable;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type AddTriggerTimerInput = {
  trigger_id: Scalars['String'];
};

export type AddTriggerToPathwayInput = {
  pathway_id: Scalars['String'];
  webhook_id: Scalars['String'];
};

export type AddTriggerToPathwayPayload = Payload & {
  __typename?: 'AddTriggerToPathwayPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  trigger: Trigger;
};

export type AddTriggerToTrackInput = {
  track_id: Scalars['String'];
};

export type AddTriggerToTrackPayload = Payload & {
  __typename?: 'AddTriggerToTrackPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  trigger: Trigger;
};

export type AddWebhookInput = {
  pathway_id: Scalars['String'];
  webhook: NewWebhookInput;
};

export type AddWebhookPayload = Payload & {
  __typename?: 'AddWebhookPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  webhook: Webhook;
};

export type AddWeekdaysParameters = {
  __typename?: 'AddWeekdaysParameters';
  days?: Maybe<Scalars['Float']>;
};

export type AddWeekdaysTransformation = Transformation & {
  __typename?: 'AddWeekdaysTransformation';
  id: Scalars['ID'];
  parameters?: Maybe<AddWeekdaysParameters>;
  type: TransformationType;
};

export type AhpLink = {
  __typename?: 'AhpLink';
  base_url: Scalars['String'];
  cancel_url?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  id: Scalars['ID'];
  language: Language;
  pathway_definition_id: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  stakeholder_definition_id: Scalars['String'];
  success_url?: Maybe<Scalars['String']>;
};

export type AhpLinkInput = {
  cancel_url?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  language: Language;
  pathway_definition_id: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
  stakeholder_definition_id: Scalars['String'];
  success_url?: Maybe<Scalars['String']>;
};

export type AhpLinkPayload = Payload & {
  __typename?: 'AhpLinkPayload';
  ahp_link: AhpLink;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type AhpLinkPayloads = Payload & {
  __typename?: 'AhpLinkPayloads';
  ahp_links: Array<AhpLink>;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export enum AllowedDatesOptions {
  All = 'ALL',
  Future = 'FUTURE',
  Past = 'PAST'
}

export type Answer = {
  __typename?: 'Answer';
  question_id: Scalars['String'];
  value: Scalars['String'];
  value_type: DataPointValueType;
};

export type AnswerInput = {
  question_id: Scalars['String'];
  value: Scalars['String'];
};

export type ApiCall = {
  __typename?: 'ApiCall';
  body?: Maybe<MessageBodyTemplate>;
  endpoint?: Maybe<Scalars['String']>;
  headers: Array<ApiCallHeader>;
  id: Scalars['ID'];
  mappings: Array<ApiCallMapping>;
  method: Scalars['String'];
  title: Scalars['String'];
};

export type ApiCallAction = Action & {
  __typename?: 'ApiCallAction';
  api_call?: Maybe<ApiCall>;
  api_call_id: Scalars['ID'];
  clinical_note_id?: Maybe<Scalars['ID']>;
  created: AuditTrail;
  due_date?: Maybe<DueDate>;
  emr_report_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  idle_time?: Maybe<Delay>;
  last_updated?: Maybe<AuditTrail>;
  locations?: Maybe<Array<Location>>;
  message_id?: Maybe<Scalars['ID']>;
  plugin_key?: Maybe<Scalars['String']>;
  stakeholders: Array<Stakeholder>;
  status: ConfigurationStatus;
  step: Step;
  title?: Maybe<Scalars['String']>;
  type: ActionType;
};

export type ApiCallBodyInput = {
  html: Scalars['String'];
  plaintext: Scalars['String'];
  slate: Scalars['String'];
};

export type ApiCallGraphqlAction = Action & {
  __typename?: 'ApiCallGraphqlAction';
  api_call?: Maybe<ApiCall>;
  api_call_id: Scalars['ID'];
  clinical_note_id?: Maybe<Scalars['ID']>;
  created: AuditTrail;
  due_date?: Maybe<DueDate>;
  emr_report_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  idle_time?: Maybe<Delay>;
  last_updated?: Maybe<AuditTrail>;
  locations?: Maybe<Array<Location>>;
  message_id?: Maybe<Scalars['ID']>;
  plugin_key?: Maybe<Scalars['String']>;
  stakeholders: Array<Stakeholder>;
  status: ConfigurationStatus;
  step: Step;
  title?: Maybe<Scalars['String']>;
  type: ActionType;
};

export type ApiCallHeader = {
  __typename?: 'ApiCallHeader';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ApiCallHeaderInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ApiCallMapping = {
  __typename?: 'ApiCallMapping';
  data_point_definition_id?: Maybe<Scalars['String']>;
  first_match_only?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  response_key: Scalars['String'];
};

export type ApiCallRequest = {
  __typename?: 'ApiCallRequest';
  body?: Maybe<Scalars['String']>;
  endpoint: Scalars['String'];
  headers: Array<ApiCallHeader>;
  method: ApiCallRequestMethod;
};

export enum ApiCallRequestMethod {
  Delete = 'DELETE',
  Get = 'GET',
  Patch = 'PATCH',
  Post = 'POST',
  Put = 'PUT'
}

export type ApiCallResponse = {
  __typename?: 'ApiCallResponse';
  body: Scalars['String'];
  date: Scalars['String'];
  status: Scalars['Float'];
};

export type ApiCallRetrySettings = {
  __typename?: 'ApiCallRetrySettings';
  enabled?: Maybe<Scalars['Boolean']>;
  initial_delay_ms?: Maybe<Scalars['Float']>;
  max_attempts?: Maybe<Scalars['Float']>;
  max_delay_ms?: Maybe<Scalars['Float']>;
};

export type ApiCallRetrySettingsInput = {
  enabled?: Maybe<Scalars['Boolean']>;
  initial_delay_ms?: Maybe<Scalars['Float']>;
  max_attempts?: Maybe<Scalars['Float']>;
  max_delay_ms?: Maybe<Scalars['Float']>;
};

export enum ApiCallStatus {
  Failed = 'Failed',
  InProgress = 'InProgress',
  Pending = 'Pending',
  PermanentlyFailed = 'PermanentlyFailed',
  Skipped = 'Skipped',
  Success = 'Success'
}

export type ApiPathwayContext = {
  __typename?: 'ApiPathwayContext';
  id: Scalars['String'];
  pathway_definition_id: Scalars['String'];
  patient_id?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['String']>;
};

export type Audit = {
  __typename?: 'Audit';
  at: Scalars['String'];
  by: Scalars['String'];
};

export type AuditTrail = {
  __typename?: 'AuditTrail';
  date: Scalars['SafeDate'];
  user_email?: Maybe<Scalars['String']>;
  user_id: Scalars['String'];
};

export type AvailableWebhook = {
  __typename?: 'AvailableWebhook';
  description?: Maybe<Scalars['String']>;
  key: Scalars['String'];
};

export type BaselineDataPoint = {
  __typename?: 'BaselineDataPoint';
  definition: DataPointDefinition;
  value?: Maybe<Scalars['String']>;
};

export type BaselineInfoInput = {
  data_point_definition_id: Scalars['String'];
  value: Scalars['String'];
};

export type BaselineInfoPayload = Payload & {
  __typename?: 'BaselineInfoPayload';
  baselineDataPoints: Array<BaselineDataPoint>;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type BooleanField = ExtensionActionField & {
  __typename?: 'BooleanField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  type: ExtensionActionFieldType;
  /** Value is kept as string because it can contain data point definition id in handlebars template, otherwise it can be "true" or "false" */
  value?: Maybe<Scalars['String']>;
};

export enum BooleanOperator {
  And = 'AND',
  Or = 'OR'
}

export type BrandingSettings = {
  __typename?: 'BrandingSettings';
  accent_color?: Maybe<Scalars['String']>;
  custom_theme?: Maybe<FormattedJson>;
  hosted_page_auto_progress?: Maybe<Scalars['Boolean']>;
  hosted_page_autosave?: Maybe<Scalars['Boolean']>;
  hosted_page_title?: Maybe<Scalars['String']>;
  logo_url?: Maybe<Scalars['String']>;
};

/**
 * Calculations are functions that take data points as input and output new data points
 * For the definition of a data point, see DataPointsPayload.ts
 *
 * Most often these calculations are scores used to diagnose patients.
 * Examples are:
 * - Mayo score
 * - PRO2 score
 * - Oxford hip score
 * - EORTC score
 * ...
 *
 * Calculations can also be customer specific algorithms that are used for example to triage patients
 * A calculation consists of scores.
 *
 * Calculation definitions include:
 * - a list of score definitions
 * - the calculation title
 * - the calculation key. This is human readable id
 *
 * A score definition consists of a:
 * - type
 * - id
 * - title
 * - if type = integer, range
 *
 * The implementation of the calculation is **not** included in the calculation definition
 */
export type Calculation = {
  __typename?: 'Calculation';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  results: Array<CalculationResult>;
  title: Scalars['String'];
};

export type CalculationAction = Action & {
  __typename?: 'CalculationAction';
  calculation_id?: Maybe<Scalars['ID']>;
  calculation_inputs: Array<CalculationInput>;
  clinical_note_id?: Maybe<Scalars['ID']>;
  created: AuditTrail;
  due_date?: Maybe<DueDate>;
  emr_report_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  idle_time?: Maybe<Delay>;
  last_updated?: Maybe<AuditTrail>;
  locations?: Maybe<Array<Location>>;
  message_id?: Maybe<Scalars['ID']>;
  plugin_key?: Maybe<Scalars['String']>;
  stakeholders: Array<Stakeholder>;
  status: ConfigurationStatus;
  step: Step;
  title?: Maybe<Scalars['String']>;
  type: ActionType;
};

export type CalculationInput = {
  __typename?: 'CalculationInput';
  calculation_input_id: Scalars['String'];
  data_point_definition_id: Scalars['String'];
};

export type CalculationResult = {
  __typename?: 'CalculationResult';
  id: Scalars['ID'];
  key: Scalars['String'];
  range?: Maybe<Range>;
  title: Scalars['String'];
  valueType: DataPointValueType;
};

export type CalculationResultInput = {
  activity_id: Scalars['String'];
  pathway_case_id: Scalars['String'];
};

export type CalculationResultPayload = Payload & {
  __typename?: 'CalculationResultPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  result: Array<CalculationSingleResult>;
  success: Scalars['Boolean'];
};

export type CalculationSingleResult = {
  __typename?: 'CalculationSingleResult';
  status?: Maybe<Scalars['String']>;
  subresult_id: Scalars['String'];
  unit?: Maybe<Scalars['String']>;
  value: Scalars['String'];
  value_type?: Maybe<DataPointValueType>;
};

export type CalculationsPayload = Payload & {
  __typename?: 'CalculationsPayload';
  calculations: Array<Calculation>;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type CareflowComponentsPayload = Payload & {
  __typename?: 'CareflowComponentsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  json: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ChecklistAction = Action & {
  __typename?: 'ChecklistAction';
  /**
   * A checklist is a set of todos for a human to complete
   * A todo is an instruction
   * A checklist is part of a checklist action
   *
   * Example of checklist:
   * - Safety checklist before surgery:
   * -- review medical file of the patient
   * -- check heart-rate
   *
   *
   * Notes regarding execution domain
   * --------------------------------
   * A checklist action is done when all the todos of the checklist are marked as completed
   * In the execution domain each todo will have a complete / not complete state
   *
   * Remaining questions to answer:
   * - Are todos simple strings or will they be more complex? e.g., to be filled in by specific role? to be completed by specific date? can be skipped? can they be grouped?
   */
  checklist?: Maybe<Array<Scalars['String']>>;
  clinical_note_id?: Maybe<Scalars['ID']>;
  created: AuditTrail;
  due_date?: Maybe<DueDate>;
  emr_report_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  idle_time?: Maybe<Delay>;
  last_updated?: Maybe<AuditTrail>;
  locations?: Maybe<Array<Location>>;
  message_id?: Maybe<Scalars['ID']>;
  plugin_key?: Maybe<Scalars['String']>;
  stakeholders: Array<Stakeholder>;
  status: ConfigurationStatus;
  step: Step;
  title?: Maybe<Scalars['String']>;
  type: ActionType;
};

export type Checkpoint = {
  __typename?: 'Checkpoint';
  careflow_id: Scalars['String'];
  description?: Maybe<FormattedText>;
  id: Scalars['ID'];
  metadata: CheckpointMetadata;
  version: Scalars['Int'];
};

export type CheckpointMessage = {
  __typename?: 'CheckpointMessage';
  checkpoint: Checkpoint;
  message: Scalars['String'];
};

export type CheckpointMetadata = {
  __typename?: 'CheckpointMetadata';
  created_at: Scalars['SafeDate'];
};

export type CheckpointPayload = Payload & {
  __typename?: 'CheckpointPayload';
  checkpoint: Checkpoint;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type CheckpointsPayload = Payload & {
  __typename?: 'CheckpointsPayload';
  checkpoints: Array<Checkpoint>;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type ChoiceRangeConfig = {
  __typename?: 'ChoiceRangeConfig';
  enabled?: Maybe<Scalars['Boolean']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type ChoiceRangeConfigInput = {
  /** Enable this flag to limit the number of choices the user can make. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The maximum number of choices the user can make. */
  max?: Maybe<Scalars['Float']>;
  /** The minimum number of choices the user has to make. */
  min?: Maybe<Scalars['Float']>;
};

export type ClearActionDueDateInput = {
  action_id: Scalars['String'];
};

export type ClearActionDueDatePayload = Payload & {
  __typename?: 'ClearActionDueDatePayload';
  action: Action;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type ClearActionIdleTimeInput = {
  action_id: Scalars['String'];
};

export type ClearActionIdleTimePayload = Payload & {
  __typename?: 'ClearActionIdleTimePayload';
  action: Action;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type ClearTimingDelayInput = {
  timing_id: Scalars['String'];
};

export type ClearTimingDelayPayload = Payload & {
  __typename?: 'ClearTimingDelayPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  timing: Timing;
};

export type ClearTimingReferenceInput = {
  timing_id: Scalars['String'];
};

export type ClearTimingReferencePayload = Payload & {
  __typename?: 'ClearTimingReferencePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  timing: Timing;
};

export type ClinicalNote = {
  __typename?: 'ClinicalNote';
  context: Array<ClinicalNoteContextField>;
  id: Scalars['ID'];
  narratives: Array<ClinicalNoteNarrative>;
};

export type ClinicalNoteAction = Action & {
  __typename?: 'ClinicalNoteAction';
  clinical_note_id?: Maybe<Scalars['ID']>;
  created: AuditTrail;
  due_date?: Maybe<DueDate>;
  emr_report_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  idle_time?: Maybe<Delay>;
  last_updated?: Maybe<AuditTrail>;
  locations?: Maybe<Array<Location>>;
  message_id?: Maybe<Scalars['ID']>;
  plugin_key?: Maybe<Scalars['String']>;
  stakeholders: Array<Stakeholder>;
  status: ConfigurationStatus;
  step: Step;
  title?: Maybe<Scalars['String']>;
  type: ActionType;
};

export type ClinicalNoteContextField = {
  __typename?: 'ClinicalNoteContextField';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ClinicalNoteContextFieldInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type ClinicalNoteNarrative = {
  __typename?: 'ClinicalNoteNarrative';
  body?: Maybe<Scalars['String']>;
  html_body?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type ClinicalNotePayload = Payload & {
  __typename?: 'ClinicalNotePayload';
  clinical_note: ClinicalNote;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type CollectionContext = {
  __typename?: 'CollectionContext';
  action?: Maybe<CollectionContextItem>;
  stakeholder?: Maybe<Stakeholder>;
  step?: Maybe<CollectionContextItem>;
  track?: Maybe<CollectionContextItem>;
  uses?: Maybe<Array<UsesContextItem>>;
};

export type CollectionContextItem = {
  __typename?: 'CollectionContextItem';
  id: Scalars['String'];
  title: Scalars['String'];
};

export type Condition = {
  __typename?: 'Condition';
  id: Scalars['ID'];
  operand?: Maybe<Operand>;
  operator?: Maybe<ConditionOperator>;
  reference?: Maybe<Scalars['String']>;
  reference_key?: Maybe<Scalars['String']>;
  status: ConfigurationStatus;
};

export type ConditionInput = {
  operand?: Maybe<OperandInput>;
  operator: ConditionOperator;
  reference: Scalars['String'];
};

export enum ConditionOperandType {
  Boolean = 'BOOLEAN',
  DataPoint = 'DATA_POINT',
  DataSource = 'DATA_SOURCE',
  Number = 'NUMBER',
  NumbersArray = 'NUMBERS_ARRAY',
  String = 'STRING',
  StringsArray = 'STRINGS_ARRAY'
}

export enum ConditionOperator {
  Contains = 'CONTAINS',
  DoesNotContain = 'DOES_NOT_CONTAIN',
  HasFileUploaded = 'HAS_FILE_UPLOADED',
  HasNoFileUploaded = 'HAS_NO_FILE_UPLOADED',
  IsAnyOf = 'IS_ANY_OF',
  IsEmpty = 'IS_EMPTY',
  IsEqualTo = 'IS_EQUAL_TO',
  IsGreaterThan = 'IS_GREATER_THAN',
  IsGreaterThanOrEqualTo = 'IS_GREATER_THAN_OR_EQUAL_TO',
  IsInRange = 'IS_IN_RANGE',
  IsLessThan = 'IS_LESS_THAN',
  IsLessThanOrEqualTo = 'IS_LESS_THAN_OR_EQUAL_TO',
  IsLessThanXDaysAgo = 'IS_LESS_THAN_X_DAYS_AGO',
  IsMoreThanXDaysAgo = 'IS_MORE_THAN_X_DAYS_AGO',
  IsNoneOf = 'IS_NONE_OF',
  IsNotEmpty = 'IS_NOT_EMPTY',
  IsNotEqualTo = 'IS_NOT_EQUAL_TO',
  IsNotTrue = 'IS_NOT_TRUE',
  IsToday = 'IS_TODAY',
  IsTrue = 'IS_TRUE'
}

export type ConfigurationStatus = {
  __typename?: 'ConfigurationStatus';
  errors: Array<Scalars['String']>;
  status: Status;
};

export type ConfigureAhpLinkInput = {
  ahp_link: AhpLinkInput;
  id?: Maybe<Scalars['String']>;
};

export type ConfigureExtensionInput = {
  extension_key: Scalars['String'];
  pathway_id: Scalars['String'];
  properties: Array<ExtensionPropertyInput>;
};

export type ConfigureExtensionPayload = Payload & {
  __typename?: 'ConfigureExtensionPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  extension: ExtensionConfiguration;
  success: Scalars['Boolean'];
};

export type ConnectStepsInput = {
  destination_connector_id: TransitionConnectorId;
  destination_step_id: Scalars['String'];
  origin_connector_id: TransitionConnectorId;
  origin_step_id: Scalars['String'];
  track_id: Scalars['String'];
};

export type ConnectStepsPayload = Payload & {
  __typename?: 'ConnectStepsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  origin_step: Step;
  success: Scalars['Boolean'];
  transition: Transition;
};

export type Constant = {
  __typename?: 'Constant';
  id: Scalars['ID'];
  label: Scalars['String'];
  obfuscated: Scalars['Boolean'];
  readonly?: Maybe<Scalars['Boolean']>;
  value: Scalars['String'];
};

export type ConstantInput = {
  constant_id: Scalars['String'];
  pathway_id: Scalars['String'];
};

export type ConstantPayload = Payload & {
  __typename?: 'ConstantPayload';
  code: Scalars['String'];
  constant?: Maybe<Constant>;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type ConstantsPayload = Payload & {
  __typename?: 'ConstantsPayload';
  code: Scalars['String'];
  constants: Array<Constant>;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type Coordinates = {
  __typename?: 'Coordinates';
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type CoordinatesInput = {
  x: Scalars['Float'];
  y: Scalars['Float'];
};

export type CreateDynamicVariableInput = {
  dynamic_variable?: Maybe<DynamicVariableInput>;
  pathway_id: Scalars['String'];
};

export type CreateDynamicVariablePayload = Payload & {
  __typename?: 'CreateDynamicVariablePayload';
  code: Scalars['String'];
  dynamic_variable: DynamicVariable;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type CreateFormInput = {
  title?: Maybe<Scalars['String']>;
};

export type CreateFormPayload = Payload & {
  __typename?: 'CreateFormPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  form: Form;
  success: Scalars['Boolean'];
};

export type CreateMessagePayload = Payload & {
  __typename?: 'CreateMessagePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  message: Message;
  success: Scalars['Boolean'];
};

export type CreatePathwayCaseInput = {
  pathway_id: Scalars['String'];
  title: Scalars['String'];
};

export type CreatePathwayCasePayload = Payload & {
  __typename?: 'CreatePathwayCasePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway_case: PathwayCaseSummary;
  success: Scalars['Boolean'];
};

export type CreatePathwayInput = {
  title: Scalars['String'];
};

export type CreatePathwayPayload = Payload & {
  __typename?: 'CreatePathwayPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type CreateTransitionInput = {
  destination: TransitionNodeInput;
  origin: TransitionNodeInput;
  track_id: Scalars['String'];
};

export type CreateTransitionPayload = Payload & {
  __typename?: 'CreateTransitionPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  transition: Transition;
};

export type CreatedByGraphQlType = {
  __typename?: 'CreatedByGraphQLType';
  at: Scalars['String'];
  by: Scalars['String'];
};

export type CustomAction = {
  __typename?: 'CustomAction';
  category: Scalars['String'];
  created: AuditTrail;
  fields: Array<CustomActionField>;
  id: Scalars['ID'];
  last_updated?: Maybe<AuditTrail>;
  name: Scalars['String'];
  type: ActionType;
};

export type CustomActionField = {
  __typename?: 'CustomActionField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  mandatory?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  options?: Maybe<Array<CustomActionFieldOption>>;
  readonly?: Maybe<Scalars['Boolean']>;
  type: CustomActionFieldType;
  value?: Maybe<Scalars['String']>;
};

export type CustomActionFieldInput = {
  description?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  mandatory?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  options?: Maybe<Array<CustomActionFieldOptionInput>>;
  readonly?: Maybe<Scalars['Boolean']>;
  type: CustomActionFieldType;
  value?: Maybe<Scalars['String']>;
};

export type CustomActionFieldOption = {
  __typename?: 'CustomActionFieldOption';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type CustomActionFieldOptionInput = {
  label: Scalars['String'];
  value: Scalars['String'];
};

export enum CustomActionFieldType {
  Select = 'SELECT',
  Text = 'TEXT'
}

export type CustomActionInput = {
  category: Scalars['String'];
  fields: Array<CustomActionFieldInput>;
  name: Scalars['String'];
  type: ActionType;
};

export type DataCatalogInput = {
  pathway_definition_id: Scalars['String'];
};

export type DataCatalogItem = {
  __typename?: 'DataCatalogItem';
  category: DataPointSourceType;
  collection_contexts: Array<CollectionContext>;
  /** Additonal context on data point */
  data_point_metadata?: Maybe<Array<DataPointMetaDataItem>>;
  /** Flag that is true when the data value is defined in ICD Classificaton Standard */
  icd_classification?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  jsonPath?: Maybe<Scalars['String']>;
  /** For json valueType we can use this field to specify schema of json object */
  jsonSchemaSource?: Maybe<JsonSchemaSourceGraphqlType>;
  key: Scalars['String'];
  optional?: Maybe<Scalars['Boolean']>;
  /** Personally identifiable information */
  pii?: Maybe<Scalars['Boolean']>;
  possibleValues?: Maybe<Array<DataPointPossibleValue>>;
  range?: Maybe<Range>;
  source_definition_id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  valueType: DataPointValueType;
};

export type DataCatalogJsonPayload = Payload & {
  __typename?: 'DataCatalogJsonPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  json: Scalars['String'];
  success: Scalars['Boolean'];
};

export type DataCatalogPayload = Payload & {
  __typename?: 'DataCatalogPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  items: Array<DataCatalogItem>;
  success: Scalars['Boolean'];
};

export type DataPointCollectedActivationReference = TriggerActivationReference & {
  __typename?: 'DataPointCollectedActivationReference';
  data_point_definition_id?: Maybe<Scalars['ID']>;
  type?: Maybe<TriggerActivationReferenceType>;
};

export type DataPointCollectedTriggerSettings = TriggerSettings & {
  __typename?: 'DataPointCollectedTriggerSettings';
  data_point_definition_id?: Maybe<Scalars['ID']>;
  rule?: Maybe<Rule>;
  rule_id?: Maybe<Scalars['ID']>;
  type?: Maybe<TriggerType>;
};

export type DataPointDefinition = {
  __typename?: 'DataPointDefinition';
  category: DataPointSourceType;
  /** Additonal context on data point */
  data_point_metadata?: Maybe<Array<DataPointMetaDataItem>>;
  /** Flag that is true when the data value is defined in ICD Classificaton Standard */
  icd_classification?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  jsonPath?: Maybe<Scalars['String']>;
  /** For json valueType we can use this field to specify schema of json object */
  jsonSchemaSource?: Maybe<JsonSchemaSourceGraphqlType>;
  key: Scalars['String'];
  optional?: Maybe<Scalars['Boolean']>;
  /** Personally identifiable information */
  pii?: Maybe<Scalars['Boolean']>;
  possibleValues?: Maybe<Array<DataPointPossibleValue>>;
  range?: Maybe<Range>;
  source_definition_id: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  unit?: Maybe<Scalars['String']>;
  valueType: DataPointValueType;
};

export type DataPointDefinitionInput = {
  key: Scalars['String'];
  optional?: Maybe<Scalars['Boolean']>;
  possibleValues?: Maybe<Array<DataPointPossibleValueInput>>;
  range?: Maybe<RangeInput>;
  unit?: Maybe<Scalars['String']>;
  valueType: DataPointValueType;
};

export type DataPointDefinitionPayload = Payload & {
  __typename?: 'DataPointDefinitionPayload';
  code: Scalars['String'];
  data_point_definition: DataPointDefinition;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type DataPointDefinitionsInput = {
  categories?: Maybe<Array<DataPointSourceType>>;
  pathway_definition_id: Scalars['String'];
  valueTypes?: Maybe<Array<DataPointValueType>>;
};

export type DataPointDefinitionsPayload = Payload & {
  __typename?: 'DataPointDefinitionsPayload';
  code: Scalars['String'];
  data_point_definitions: Array<DataPointDefinition>;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type DataPointMapping = {
  __typename?: 'DataPointMapping';
  data_point_definition_id: Scalars['String'];
  incoming_data_point_key: Scalars['String'];
};

export type DataPointMetaDataInputType = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type DataPointMetaDataItem = {
  __typename?: 'DataPointMetaDataItem';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type DataPointNotCollectedTriggerSettings = TriggerSettings & {
  __typename?: 'DataPointNotCollectedTriggerSettings';
  data_point_definition_id?: Maybe<Scalars['ID']>;
  rule?: Maybe<Rule>;
  rule_id?: Maybe<Scalars['ID']>;
  type?: Maybe<TriggerType>;
};

export type DataPointPossibleValue = {
  __typename?: 'DataPointPossibleValue';
  label?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export type DataPointPossibleValueInput = {
  label?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

export enum DataPointPropertyType {
  CollectionDate = 'COLLECTION_DATE',
  Label = 'LABEL',
  Value = 'VALUE'
}

export type DataPointReferenceTimerConfig = TimerConfig & {
  __typename?: 'DataPointReferenceTimerConfig';
  data_point_definition_id: Scalars['String'];
  data_point_key: Scalars['String'];
  description: Scalars['String'];
  hour?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  offsetDirection?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
  type: TimerType;
  usePatientTimezone?: Maybe<Scalars['Boolean']>;
};

export enum DataPointSourceType {
  ApiCall = 'API_CALL',
  ApiCallStatus = 'API_CALL_STATUS',
  Calculation = 'CALCULATION',
  DataPoint = 'DATA_POINT',
  Decision = 'DECISION',
  ExtensionAction = 'EXTENSION_ACTION',
  ExtensionWebhook = 'EXTENSION_WEBHOOK',
  Form = 'FORM',
  Pathway = 'PATHWAY',
  PatientIdentifier = 'PATIENT_IDENTIFIER',
  PatientProfile = 'PATIENT_PROFILE',
  Step = 'STEP',
  Track = 'TRACK'
}

export enum DataPointValueType {
  Attachment = 'ATTACHMENT',
  AttachmentsArray = 'ATTACHMENTS_ARRAY',
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Json = 'JSON',
  Number = 'NUMBER',
  NumbersArray = 'NUMBERS_ARRAY',
  String = 'STRING',
  StringsArray = 'STRINGS_ARRAY',
  Telephone = 'TELEPHONE'
}

export type DateConfig = {
  __typename?: 'DateConfig';
  allowed_dates?: Maybe<AllowedDatesOptions>;
  include_date_of_response?: Maybe<Scalars['Boolean']>;
};

export type DateConfigInput = {
  /** The type of allowed dates for the question. One of: PAST, FUTURE, ALL. */
  allowed_dates?: Maybe<AllowedDatesOptions>;
  /** Enable this flag to allow the user to pick the current date when filling out the form. */
  include_date_of_response?: Maybe<Scalars['Boolean']>;
};

export type DateField = ExtensionActionField & {
  __typename?: 'DateField';
  delay?: Maybe<Delay>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  type: ExtensionActionFieldType;
  /** Date in ISO 8601 format */
  value?: Maybe<Scalars['String']>;
};

export type DateQuestionConfigInput = {
  /** The new date specific configuration for the question. */
  date: DateConfigInput;
};

export type Decision = {
  __typename?: 'Decision';
  coordinates?: Maybe<Coordinates>;
  decision: Scalars['String'];
  id: Scalars['ID'];
  inputs: Array<DecisionInput>;
  outcomes: Array<Scalars['String']>;
  type: DecisionType;
};

export type DecisionInput = {
  __typename?: 'DecisionInput';
  identifier: Scalars['String'];
  text: Scalars['String'];
  type: DecisionInputType;
};

export type DecisionInputInput = {
  identifier: Scalars['String'];
  text: Scalars['String'];
  type: DecisionInputType;
};

export enum DecisionInputType {
  ActionableProperty = 'ACTIONABLE_PROPERTY',
  DataPoint = 'DATA_POINT',
  OntologyResource = 'ONTOLOGY_RESOURCE',
  Patient = 'PATIENT'
}

export type DecisionOutputsInput = {
  activity_id: Scalars['String'];
  pathway_case_id: Scalars['String'];
};

export type DecisionOutputsPayload = Payload & {
  __typename?: 'DecisionOutputsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  outputs: Scalars['String'];
  success: Scalars['Boolean'];
};

export type DecisionPayload = Payload & {
  __typename?: 'DecisionPayload';
  code: Scalars['String'];
  decision: Decision;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export enum DecisionType {
  DecisionTable = 'DECISION_TABLE',
  ExpressionList = 'EXPRESSION_LIST'
}

export type DecisionsPayload = Payload & {
  __typename?: 'DecisionsPayload';
  code: Scalars['String'];
  decisions: Array<Decision>;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type Delay = {
  __typename?: 'Delay';
  amount: Scalars['Int'];
  unit: DelayUnitType;
};

export type DelayInput = {
  amount: Scalars['Int'];
  unit: DelayUnitType;
};

export type DelayTimerConfig = TimerConfig & {
  __typename?: 'DelayTimerConfig';
  delay: TimerDelay;
  description: Scalars['String'];
  type: TimerType;
};

export enum DelayUnitType {
  Day = 'DAY',
  Hour = 'HOUR',
  Minute = 'MINUTE',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type DeleteDecisionInput = {
  decision_id: Scalars['String'];
};

export type DeletePathwayCaseInput = {
  pathway_case_id: Scalars['String'];
};

export type DeletePathwayCasePayload = Payload & {
  __typename?: 'DeletePathwayCasePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type DeleteTimerInput = {
  timer_id: Scalars['String'];
};

export type DerivedDataPointDefinitionInput = {
  jsonPath?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  source_id: Scalars['String'];
  valueType: DataPointValueType;
};

export type DesignatedTriggerTimer = TriggerSettings & {
  __typename?: 'DesignatedTriggerTimer';
  delay?: Maybe<Delay>;
  id: Scalars['ID'];
  reference?: Maybe<TriggerActivationReference>;
  rule?: Maybe<Rule>;
  type?: Maybe<TriggerType>;
};

export type DestinationInput = {
  connector_id?: Maybe<Scalars['String']>;
  node_id: Scalars['String'];
  type: TransitionNodeType;
};

/**
 * We use event-sourcing in our app. In event-sourcing event are used to record state changes.
 * Event is a record of some state change that is a result of executing a command.
 * Events are always named with a verb in past tense, is something that already happened. (StepCreated, StepUpdated …)
 * Event is a record of state change with all the information that is necessary to replay it.
 * Events are immutable and stored into event store which is append only.
 * By being immutable and sequentially stored makes them suitable for audit log and history.
 * You must never delete an event.
 */
export type DomainEvent = {
  __typename?: 'DomainEvent';
  aggregate_class: Scalars['String'];
  aggregate_id: Scalars['String'];
  data?: Maybe<Scalars['String']>;
  event_type: Scalars['String'];
  id: Scalars['ID'];
  metadata: DomainEventMetadata;
  stream: Scalars['String'];
};

export type DomainEventMetadata = {
  __typename?: 'DomainEventMetadata';
  caused_by?: Maybe<Scalars['String']>;
  saga_id?: Maybe<Scalars['String']>;
  timestamp: Scalars['String'];
  user_id: Scalars['String'];
  version: Scalars['Float'];
};

export type DomainEventsPayload = Payload & {
  __typename?: 'DomainEventsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  events: Array<DomainEvent>;
  success: Scalars['Boolean'];
};

export type DueDate = {
  __typename?: 'DueDate';
  delay?: Maybe<Delay>;
  reference?: Maybe<Scalars['String']>;
};

export type DueDateInput = {
  delay?: Maybe<DelayInput>;
  reference?: Maybe<Scalars['String']>;
};

export type DuplicateCareflowInput = {
  careflow_id: Scalars['String'];
};

export type DuplicateCareflowPayload = Payload & {
  __typename?: 'DuplicateCareflowPayload';
  careflow_id: Scalars['String'];
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type DuplicateQuestionInput = {
  form_id: Scalars['String'];
  question_id: Scalars['String'];
};

export type DuplicateQuestionPayload = Payload & {
  __typename?: 'DuplicateQuestionPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  form: Form;
  question: Question;
  success: Scalars['Boolean'];
};

export type DuplicateStepInput = {
  step_id: Scalars['String'];
};

export type DuplicateStepPayload = Payload & {
  __typename?: 'DuplicateStepPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export type DurationGraphQlObjectType = {
  __typename?: 'DurationGraphQlObjectType';
  days?: Maybe<Scalars['Float']>;
  hours?: Maybe<Scalars['Float']>;
  minutes?: Maybe<Scalars['Float']>;
  months?: Maybe<Scalars['Float']>;
  weeks?: Maybe<Scalars['Float']>;
  years?: Maybe<Scalars['Float']>;
};

export type DynamicVariable = {
  __typename?: 'DynamicVariable';
  data_point_definition?: Maybe<DynamicVariableReference>;
  data_point_definition_id?: Maybe<Scalars['String']>;
  data_point_property?: Maybe<DataPointPropertyType>;
  fallback?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_actionable_property: Scalars['Boolean'];
  is_hyperlink?: Maybe<Scalars['Boolean']>;
  label?: Maybe<Scalars['String']>;
  status: ConfigurationStatus;
  transformations?: Maybe<Array<Transformation>>;
};

export type DynamicVariableInput = {
  data_point_definition_id: Scalars['String'];
  data_point_property?: Maybe<DataPointPropertyType>;
  fallback?: Maybe<Scalars['String']>;
  is_hyperlink?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  transformations?: Maybe<Array<TransformationInput>>;
};

export type DynamicVariablePayload = Payload & {
  __typename?: 'DynamicVariablePayload';
  code: Scalars['String'];
  dynamic_variable: DynamicVariable;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type DynamicVariableReference = {
  __typename?: 'DynamicVariableReference';
  category?: Maybe<DataPointSourceType>;
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  valueType: Scalars['String'];
};

export type DynamicVariablesPayload = Payload & {
  __typename?: 'DynamicVariablesPayload';
  code: Scalars['String'];
  dynamic_variables: Array<DynamicVariable>;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type Element = {
  __typename?: 'Element';
  activity_type?: Maybe<ActionType>;
  context: PathwayContext;
  end_date?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label?: Maybe<ActivityLabel>;
  name: Scalars['String'];
  parent_id?: Maybe<Scalars['ID']>;
  stakeholders: Array<ElementStakeholder>;
  start_date: Scalars['String'];
  status: ElementStatus;
  type: ElementType;
};

export type ElementStakeholder = {
  __typename?: 'ElementStakeholder';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export enum ElementStatus {
  Active = 'ACTIVE',
  Discarded = 'DISCARDED',
  Done = 'DONE',
  Postponed = 'POSTPONED',
  Scheduled = 'SCHEDULED',
  Stopped = 'STOPPED'
}

export enum ElementType {
  Action = 'ACTION',
  Pathway = 'PATHWAY',
  Step = 'STEP',
  Track = 'TRACK',
  Trigger = 'TRIGGER'
}

export type ElementsPayload = Payload & {
  __typename?: 'ElementsPayload';
  code: Scalars['String'];
  elements: Array<Element>;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type EmptyPayload = Payload & {
  __typename?: 'EmptyPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type EmrBodyInput = {
  html: Scalars['String'];
  plaintext: Scalars['String'];
  slate: Scalars['String'];
};

/**
 * EMR Reports are templates that use data points to create EMR report instances.
 * EMR Reports that use 0 data points are called static EMR reports.
 *
 * A EMR report instance is created by filling a EMR report template with data point instances
 *
 * An illustrative example:
 * - EMR report:
 *   - report template: "Hello |patient_first_name|"
 *   - data points:
 *     - patient_first_name is a data point of type patient with property first name
 * If a patient has a first name Ben then the report instance would be "Hello Ben"
 *
 * Explanation of EMR report field:
 *  - body: The content that goes in the report
 */
export type EmrReport = {
  __typename?: 'EmrReport';
  body?: Maybe<Scalars['String']>;
  html_body?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type EmrReportMetadataField = {
  __typename?: 'EmrReportMetadataField';
  id: Scalars['ID'];
  label: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type EmrReportPayload = Payload & {
  __typename?: 'EmrReportPayload';
  code: Scalars['String'];
  emr_report: EmrReport;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type EndTrackAfterStepInput = {
  destination_connector_id: TransitionConnectorId;
  origin_connector_id: TransitionConnectorId;
  origin_step_id: Scalars['String'];
  track_id: Scalars['String'];
};

export type EndTrackAfterStepPayload = Payload & {
  __typename?: 'EndTrackAfterStepPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  origin_step: Step;
  success: Scalars['Boolean'];
  transition: Transition;
};

export type EnrollmentTrigger = {
  __typename?: 'EnrollmentTrigger';
  careflow_definition_id: Scalars['String'];
  created: Audit;
  data_point_mapping: Array<DataPointMapping>;
  documentation: Scalars['String'];
  enabled: Scalars['Boolean'];
  incoming_source_id: Scalars['String'];
  incoming_source_type: Scalars['String'];
  last_updated: Audit;
  name: Scalars['String'];
  organization_slug: Scalars['String'];
  tenant_id: Scalars['String'];
};

export type EnrollmentTriggerPayload = {
  __typename?: 'EnrollmentTriggerPayload';
  code: Scalars['String'];
  enrollmentTriggers: Array<EnrollmentTrigger>;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type Error = {
  __typename?: 'Error';
  message: Scalars['String'];
  stack: Scalars['String'];
};

export type ErrorObject = {
  __typename?: 'ErrorObject';
  code: Scalars['String'];
  message: Scalars['String'];
};

export type EvaluateFormRulesInput = {
  answers: Array<AnswerInput>;
  form_id: Scalars['String'];
};

export type EvaluateFormRulesPayload = {
  __typename?: 'EvaluateFormRulesPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  results: Array<QuestionRuleResult>;
  success: Scalars['Boolean'];
};

export type EvaluatedRule = {
  __typename?: 'EvaluatedRule';
  conditions: Array<EvaluatedRuleCondition>;
  satisfied: Scalars['Boolean'];
};

export type EvaluatedRuleCondition = {
  __typename?: 'EvaluatedRuleCondition';
  id: Scalars['String'];
  operand?: Maybe<Operand>;
  operator: ConditionOperator;
  reference: EvaluatedRuleReference;
  satisfied: Scalars['Boolean'];
};

export type EvaluatedRulePayload = Payload & {
  __typename?: 'EvaluatedRulePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  evaluatedRule: EvaluatedRule;
  success: Scalars['Boolean'];
};

export type EvaluatedRuleReference = {
  __typename?: 'EvaluatedRuleReference';
  data_point_definition_id: Scalars['String'];
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type EventBasedTriggerSettings = TriggerSettings & {
  __typename?: 'EventBasedTriggerSettings';
  event_config?: Maybe<TriggerEventConfig>;
  event_suggestions?: Maybe<Array<TriggerEventSuggestion>>;
  rule?: Maybe<Rule>;
  type?: Maybe<TriggerType>;
};

export type EventBasedTriggerSettingsInput = {
  description: Scalars['String'];
  expression: Scalars['String'];
  shelly_thread_id: Scalars['String'];
  source: Scalars['String'];
  type: Scalars['String'];
};

export type EventsInput = {
  event_type?: Maybe<Scalars['String']>;
  saga_id?: Maybe<Scalars['String']>;
  stream?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['String']>;
};

export type ExclusiveOptionConfig = {
  __typename?: 'ExclusiveOptionConfig';
  enabled?: Maybe<Scalars['Boolean']>;
  option_id?: Maybe<Scalars['String']>;
};

export type ExclusiveOptionConfigInput = {
  enabled?: Maybe<Scalars['Boolean']>;
};

export type ExecuteTestApiCallInput = {
  api_call_id: Scalars['String'];
  pathway_definition_id: Scalars['String'];
  variable_inputs: Array<GraphqlTestApiCallVariableDataPoint>;
};

export type ExecuteTestApiCallPayload = Payload & {
  __typename?: 'ExecuteTestApiCallPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  mappings: Array<GraphqlTestApiCallMapping>;
  metadata: GraphqlTestApiCallMetadata;
  request: GraphqlTestApiCallRequest;
  response: GraphqlTestApiCallResponse;
  success: Scalars['Boolean'];
};

export type ExistingWebhookInput = {
  enabled: Scalars['Boolean'];
  endpoint: Scalars['String'];
  endpoint_test?: Maybe<Scalars['String']>;
  headers?: Maybe<Array<WebhookHeaderInput>>;
  id: Scalars['String'];
  name: Scalars['String'];
  subscribed_events?: Maybe<Array<Scalars['String']>>;
};

export type ExportPathwayEventsCount = {
  __typename?: 'ExportPathwayEventsCount';
  event_count: Scalars['Float'];
  exported_count: Scalars['Float'];
};

export type ExportPathwayInput = {
  pathway_id: Scalars['String'];
};

export type ExportPathwayStatus = {
  __typename?: 'ExportPathwayStatus';
  content?: Maybe<Scalars['String']>;
  count?: Maybe<ExportPathwayEventsCount>;
  message: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type Extension = {
  __typename?: 'Extension';
  actions: Array<ExtensionActionBlueprint>;
  /** Key and description of the webhooks defined in the extension repository */
  available_webhooks?: Maybe<Array<AvailableWebhook>>;
  category: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  icon_url?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  settings?: Maybe<ExtensionSettings>;
  title: Scalars['String'];
  webhooks: Array<ExtensionWebhook>;
};


export type ExtensionWebhooksArgs = {
  pathway_id: Scalars['String'];
};

export type ExtensionAction = Action & {
  __typename?: 'ExtensionAction';
  clinical_note_id?: Maybe<Scalars['ID']>;
  created: AuditTrail;
  due_date?: Maybe<DueDate>;
  emr_report_id?: Maybe<Scalars['ID']>;
  extension_action_key?: Maybe<Scalars['ID']>;
  extension_data_point_mappings?: Maybe<Array<ExtensionDataPointMapping>>;
  extension_data_points?: Maybe<Array<ExtensionDataPointDefinition>>;
  extension_fields: Array<ExtensionActionField>;
  extension_key?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  idle_time?: Maybe<Delay>;
  last_updated?: Maybe<AuditTrail>;
  locations?: Maybe<Array<Location>>;
  message_id?: Maybe<Scalars['ID']>;
  plugin_key?: Maybe<Scalars['String']>;
  stakeholders: Array<Stakeholder>;
  stakeholders_select_settings?: Maybe<StakeholdersSelectSettings>;
  status: ConfigurationStatus;
  step: Step;
  title?: Maybe<Scalars['String']>;
  type: ActionType;
};

export type ExtensionActionBlueprint = {
  __typename?: 'ExtensionActionBlueprint';
  category: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  fields: Array<ExtensionActionField>;
  icon?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  stakeholders_select_settings?: Maybe<StakeholdersSelectSettings>;
  title: Scalars['String'];
};

export type ExtensionActionField = {
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  type: ExtensionActionFieldType;
};

export type ExtensionActionFieldInput = {
  id: Scalars['String'];
  options?: Maybe<ExtensionOptionsInput>;
  value?: Maybe<Scalars['String']>;
};

export enum ExtensionActionFieldType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Html = 'HTML',
  Json = 'JSON',
  Numeric = 'NUMERIC',
  NumericArray = 'NUMERIC_ARRAY',
  String = 'STRING',
  StringArray = 'STRING_ARRAY',
  Text = 'TEXT'
}

export type ExtensionActionInput = {
  extension_action_key: Scalars['String'];
  extension_key: Scalars['String'];
};

export type ExtensionActivityField = {
  __typename?: 'ExtensionActivityField';
  id: Scalars['ID'];
  label: Scalars['String'];
  type: ExtensionActionFieldType;
  value: Scalars['String'];
};

export type ExtensionActivityRecord = {
  __typename?: 'ExtensionActivityRecord';
  activity_id: Scalars['String'];
  data_points: Array<ExtensionDataPoint>;
  date: Scalars['String'];
  fields: Array<ExtensionActivityField>;
  id: Scalars['ID'];
  pathway_id: Scalars['String'];
  plugin_action_key: Scalars['String'];
  plugin_key: Scalars['String'];
  settings?: Maybe<Array<PluginActionSettingsProperty>>;
};

export type ExtensionActivityRecordPayload = Payload & {
  __typename?: 'ExtensionActivityRecordPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  record: ExtensionActivityRecord;
  success: Scalars['Boolean'];
};

export type ExtensionConfiguration = {
  __typename?: 'ExtensionConfiguration';
  extension_key: Scalars['String'];
  properties?: Maybe<Array<ExtensionProperty>>;
};

export type ExtensionConfigurationsPayload = Payload & {
  __typename?: 'ExtensionConfigurationsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  extensions?: Maybe<Array<ExtensionConfiguration>>;
  success: Scalars['Boolean'];
};

export type ExtensionConstantInput = {
  label: Scalars['String'];
  obfuscated: Scalars['Boolean'];
  value: Scalars['String'];
};

export type ExtensionDataPoint = {
  __typename?: 'ExtensionDataPoint';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type ExtensionDataPointDefinition = {
  __typename?: 'ExtensionDataPointDefinition';
  /** For data points derived from objects or arrays, we use this prop to specify the path to the scalar value. */
  jsonPath?: Maybe<Scalars['String']>;
  /** Complex data points are stored in json format but require a schema to validate them. This prop specifies the source of the schema. */
  jsonSchemaSource?: Maybe<JsonSchemaSourceGraphqlType>;
  key: Scalars['String'];
  valueType: DataPointValueType;
};

export type ExtensionDataPointMapping = {
  __typename?: 'ExtensionDataPointMapping';
  data_point_definition_id?: Maybe<Scalars['String']>;
  extension_data_point_key?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type ExtensionDateFieldDelayInput = {
  amount: Scalars['Int'];
  unit: DelayUnitType;
};

export type ExtensionDropdownOption = {
  __typename?: 'ExtensionDropdownOption';
  label: Scalars['String'];
  value: Scalars['String'];
};

export type ExtensionOptionsInput = {
  delay?: Maybe<ExtensionDateFieldDelayInput>;
  slate?: Maybe<Scalars['String']>;
};

export type ExtensionProperty = {
  __typename?: 'ExtensionProperty';
  constant: Constant;
  key: Scalars['String'];
};

export type ExtensionPropertyInput = {
  constant: ExtensionConstantInput;
  key: Scalars['String'];
};

export type ExtensionResourceId = {
  __typename?: 'ExtensionResourceId';
  key: Scalars['String'];
  type: Scalars['String'];
};

export type ExtensionResourceIdInput = {
  key: Scalars['String'];
  type: Scalars['String'];
};

export type ExtensionResourceUpdatedTimerConfig = TimerConfig & {
  __typename?: 'ExtensionResourceUpdatedTimerConfig';
  action_definition_id: Scalars['String'];
  action_key: Scalars['String'];
  description: Scalars['String'];
  extension_key: Scalars['String'];
  resource_id: ExtensionResourceId;
  timer_key: Scalars['String'];
  type: TimerType;
};

export type ExtensionSettings = {
  __typename?: 'ExtensionSettings';
  description: Scalars['String'];
  fields: Array<ExtensionSettingsField>;
};

export type ExtensionSettingsField = {
  __typename?: 'ExtensionSettingsField';
  description?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  label: Scalars['String'];
  obfuscated: Scalars['Boolean'];
  required?: Maybe<Scalars['Boolean']>;
};

export type ExtensionWebhook = {
  __typename?: 'ExtensionWebhook';
  data_point_definitions: Array<DataPointDefinition>;
  endpoint_url: Scalars['String'];
  id: Scalars['String'];
  webhook_key: Scalars['String'];
};

export type ExtensionWebhookTriggerSettings = TriggerSettings & {
  __typename?: 'ExtensionWebhookTriggerSettings';
  rule?: Maybe<Rule>;
  rule_id?: Maybe<Scalars['ID']>;
  type?: Maybe<TriggerType>;
  webhook_id?: Maybe<Scalars['ID']>;
};

export type ExtensionsPayload = Payload & {
  __typename?: 'ExtensionsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  extensions: Array<Extension>;
  success: Scalars['Boolean'];
};

export type FileQuestionConfigInput = {
  /** Comma-separated list of accepted file types (e.g., "image/*,.pdf,.doc"). */
  accepted_file_types?: Maybe<Scalars['String']>;
  /** The ID of the file storage destination where files will be stored. */
  file_storage_config_slug?: Maybe<Scalars['String']>;
  /** The ID of the form the question to update belongs to. */
  form_id: Scalars['String'];
  /** The ID of the question to update. */
  question_id: Scalars['String'];
};

export type FileStorageConfig = {
  __typename?: 'FileStorageConfig';
  bucket_name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  type: Scalars['String'];
};

export type FileStorageConfigPayload = Payload & {
  __typename?: 'FileStorageConfigPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  fileStorageConfigs: Array<FileStorageConfig>;
  success: Scalars['Boolean'];
};

export type FileStorageQuestionConfig = {
  __typename?: 'FileStorageQuestionConfig';
  accepted_file_types?: Maybe<Array<Scalars['String']>>;
  file_storage_config_slug?: Maybe<Scalars['String']>;
};

export type FilterSearch = {
  property?: Maybe<Scalars['String']>;
  token: Scalars['String'];
};

/**
 * Forms are a set of questions
 * Example of types of questions:
 *   - Text field (stored as string)
 *   - Multiple choice question (stored as array of answer ids)
 *   - image field (stored as string that is an URL where the image is stored)
 *   - Scale question (stored as a floating point number between 0 and 1)
 *   - ...
 * Forms are usually filled in by caregivers or patients.
 * When filled in, forms generate:
 *   - answer data points
 *   - metadata such as submission date, user id of user doing submission
 */
export type Form = {
  __typename?: 'Form';
  created?: Maybe<AuditTrail>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  last_updated?: Maybe<AuditTrail>;
  metadata?: Maybe<FormattedJson>;
  pathways_used_in: Array<Pathway>;
  permissions: Permissions;
  question_ids: Array<Scalars['String']>;
  questions: Array<Question>;
  team_ids: Array<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  trademark?: Maybe<Scalars['String']>;
};

export type FormAction = Action & {
  __typename?: 'FormAction';
  clinical_note_id?: Maybe<Scalars['ID']>;
  created: AuditTrail;
  due_date?: Maybe<DueDate>;
  emr_report_id?: Maybe<Scalars['ID']>;
  form_display_mode?: Maybe<Scalars['String']>;
  form_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  idle_time?: Maybe<Delay>;
  last_updated?: Maybe<AuditTrail>;
  locations?: Maybe<Array<Location>>;
  message_id?: Maybe<Scalars['ID']>;
  plugin_key?: Maybe<Scalars['String']>;
  stakeholders: Array<Stakeholder>;
  status: ConfigurationStatus;
  step: Step;
  title?: Maybe<Scalars['String']>;
  type: ActionType;
};

export type FormDataPointDefinitionsInput = {
  form_definition_id: Scalars['String'];
};

export type FormPayload = Payload & {
  __typename?: 'FormPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  form: Form;
  success: Scalars['Boolean'];
};

export type FormResponse = {
  __typename?: 'FormResponse';
  answers: Array<Answer>;
};

export type FormResponseInput = {
  activity_id: Scalars['String'];
  pathway_case_id: Scalars['String'];
};

export type FormResponsePayload = Payload & {
  __typename?: 'FormResponsePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  response: FormResponse;
  success: Scalars['Boolean'];
};

export type FormatDateParameters = {
  __typename?: 'FormatDateParameters';
  format?: Maybe<Scalars['String']>;
};

export type FormatDateTransformation = Transformation & {
  __typename?: 'FormatDateTransformation';
  id: Scalars['ID'];
  parameters?: Maybe<FormatDateParameters>;
  type: TransformationType;
};

export type FormatPhoneNumberParameters = {
  __typename?: 'FormatPhoneNumberParameters';
  format?: Maybe<Scalars['String']>;
};

export type FormatPhoneNumberTransformation = Transformation & {
  __typename?: 'FormatPhoneNumberTransformation';
  id: Scalars['ID'];
  parameters?: Maybe<FormatPhoneNumberParameters>;
  type: TransformationType;
};

export type FormattedJson = {
  __typename?: 'FormattedJson';
  plaintext: Scalars['String'];
  slate: Scalars['String'];
};

export type FormattedJsonInput = {
  plaintext: Scalars['String'];
  slate: Scalars['String'];
};

export type FormattedText = {
  __typename?: 'FormattedText';
  content: TranslatedText;
  format: Scalars['String'];
};

export type FormattedTextInput = {
  content: TranslatedTextInput;
  format: Scalars['String'];
};

export type FormsPayload = Payload & {
  __typename?: 'FormsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  forms: Array<Form>;
  success: Scalars['Boolean'];
};

export type GeneratedClinicalNoteContextField = {
  __typename?: 'GeneratedClinicalNoteContextField';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type GeneratedClinicalNoteNarrative = {
  __typename?: 'GeneratedClinicalNoteNarrative';
  body: Scalars['String'];
  id: Scalars['ID'];
  key: Scalars['String'];
  title: Scalars['String'];
};

export type GeneratedClinicalNotePayload = Payload & {
  __typename?: 'GeneratedClinicalNotePayload';
  clinical_note: PreviewClinicalNote;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type GeneratedEmrReportPayload = Payload & {
  __typename?: 'GeneratedEmrReportPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  report: PreviewEmrReport;
  success: Scalars['Boolean'];
};

export type GeneratedMessagePayload = Payload & {
  __typename?: 'GeneratedMessagePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  message: PreviewMessage;
  success: Scalars['Boolean'];
};

export type GraphqlTestApiCallMapping = {
  __typename?: 'GraphqlTestApiCallMapping';
  data_point_definition_id: Scalars['String'];
  response_key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  value_type: Scalars['String'];
};

export type GraphqlTestApiCallMetadata = {
  __typename?: 'GraphqlTestApiCallMetadata';
  response_size: Scalars['Float'];
  response_time: Scalars['Float'];
};

export type GraphqlTestApiCallRequest = {
  __typename?: 'GraphqlTestApiCallRequest';
  body?: Maybe<Scalars['String']>;
  endpoint: Scalars['String'];
  headers: Array<ApiCallHeader>;
  method: Scalars['String'];
};

export type GraphqlTestApiCallResponse = {
  __typename?: 'GraphqlTestApiCallResponse';
  body: Scalars['String'];
  date: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  headers: Scalars['String'];
  status: Scalars['Float'];
};

export type GraphqlTestApiCallVariableDataPoint = {
  data_point_definition_id: Scalars['String'];
  value: Scalars['String'];
  value_type: Scalars['String'];
};

export type HtmlField = ExtensionActionField & {
  __typename?: 'HtmlField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  slate?: Maybe<Scalars['String']>;
  type: ExtensionActionFieldType;
  value?: Maybe<Scalars['String']>;
};

export type ImportPathwayEventsCount = {
  __typename?: 'ImportPathwayEventsCount';
  event_count: Scalars['Float'];
  event_id: Scalars['String'];
  imported_count: Scalars['Float'];
};

export type ImportPathwayInput = {
  base64Json: Scalars['String'];
  tenant_id: Scalars['String'];
};

export type ImportPathwayStatus = {
  __typename?: 'ImportPathwayStatus';
  count?: Maybe<ImportPathwayEventsCount>;
  message: Scalars['String'];
};

export type InvalidStatusReason = {
  __typename?: 'InvalidStatusReason';
  error?: Maybe<Scalars['String']>;
  type: PathwayValidationStatusReasonType;
};

export type IsSlugUniquePayload = {
  __typename?: 'IsSlugUniquePayload';
  is_slug_unique: Scalars['Boolean'];
};


export type JsonField = ExtensionActionField & {
  __typename?: 'JsonField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** For json fields we can use this prop to specify schema of json object */
  jsonSchemaSource?: Maybe<JsonSchemaSourceGraphqlType>;
  label: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  slate?: Maybe<Scalars['String']>;
  type: ExtensionActionFieldType;
  value?: Maybe<Scalars['String']>;
};

export type JsonPathPayload = Payload & {
  __typename?: 'JsonPathPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  jsonPath: Scalars['String'];
  mockObject: Scalars['String'];
  success: Scalars['Boolean'];
};

export type JsonSchemaSourceGraphqlType = {
  __typename?: 'JsonSchemaSourceGraphqlType';
  qualifiedKey: Scalars['String'];
  system: JsonSchemaSourceSystem;
};

export type JsonSchemaSourceGraphqlTypeInput = {
  qualifiedKey: Scalars['String'];
  system: JsonSchemaSourceSystem;
};

export enum JsonSchemaSourceSystem {
  Awell = 'AWELL',
  Extension = 'EXTENSION',
  FhirProxy = 'FHIR_PROXY'
}

export type Label = {
  __typename?: 'Label';
  color: Scalars['String'];
  created: AuditTrail;
  id: Scalars['ID'];
  last_updated?: Maybe<AuditTrail>;
  text: Scalars['String'];
};

export type LabelInput = {
  color: Scalars['String'];
  text: Scalars['String'];
};

export enum Language {
  Dutch = 'DUTCH',
  English = 'ENGLISH',
  Estonian = 'ESTONIAN',
  French = 'FRENCH',
  Polish = 'POLISH',
  Romanian = 'ROMANIAN',
  Russian = 'RUSSIAN',
  Spanish = 'SPANISH'
}

/**
 * Some actions are performed in specific locations.
 * Pathway studio allows pathway builders to create and assign locations to each action.
 * Example:
 * - Filling an intake form during the doctor's visit
 * - Filling a follow-up questionnaire at home
 * ...
 */
export type Location = {
  __typename?: 'Location';
  id: Scalars['ID'];
  label: Scalars['String'];
};

export type LocationsPayload = Payload & {
  __typename?: 'LocationsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  locations: Array<Location>;
  success: Scalars['Boolean'];
};

export type MappingDataPointDefinitionInput = {
  data_point_definition_id?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  new_data_point_definition?: Maybe<NewMappingDataPointDefinitionInput>;
};

export type MarkReleaseAsLiveInput = {
  definition_id: Scalars['String'];
  release_id: Scalars['String'];
};

export type MarkReleaseAsLivePayload = Payload & {
  __typename?: 'MarkReleaseAsLivePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type MassResetTeamInput = {
  team_id: Scalars['String'];
};

/**
 * Messages are templates that use data points to create message instances.
 * Messages that use 0 data points are called static messages.
 *
 * A message instance is created by filling a message template with data point instances
 *
 * An illustrative example:
 * - Message:
 *   - message template: "Hello |patient_first_name|"
 *   - data points:
 *     - patient_first_name is a data point of type patient with property first name
 * If a patient has a first name Ben then the message instance would be "Hello Ben"
 *
 * Explanation of Message fields:
 *  - subject: Short explanation of what goes in the message
 *  - body: The content that goes in the message
 */
export type Message = {
  __typename?: 'Message';
  attachments?: Maybe<Array<MessageAttachment>>;
  body?: Maybe<Scalars['String']>;
  format: MessageFormat;
  html_body?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  subject?: Maybe<Scalars['String']>;
};

export type MessageAction = Action & {
  __typename?: 'MessageAction';
  clinical_note_id?: Maybe<Scalars['ID']>;
  created: AuditTrail;
  due_date?: Maybe<DueDate>;
  emr_report_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  idle_time?: Maybe<Delay>;
  last_updated?: Maybe<AuditTrail>;
  locations?: Maybe<Array<Location>>;
  message?: Maybe<Message>;
  message_id?: Maybe<Scalars['ID']>;
  plugin_key?: Maybe<Scalars['String']>;
  stakeholders: Array<Stakeholder>;
  status: ConfigurationStatus;
  step: Step;
  title?: Maybe<Scalars['String']>;
  type: ActionType;
};

export type MessageAttachment = {
  __typename?: 'MessageAttachment';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: MessageAttachmentType;
  url: Scalars['String'];
};

export type MessageAttachmentInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  type: MessageAttachmentType;
  url: Scalars['String'];
};

export enum MessageAttachmentType {
  File = 'FILE',
  Link = 'LINK',
  Video = 'VIDEO'
}

export type MessageBodyInput = {
  html: Scalars['String'];
  slate: Scalars['String'];
};

export type MessageBodyTemplate = {
  __typename?: 'MessageBodyTemplate';
  html: Scalars['String'];
  plaintext: Scalars['String'];
  slate: Scalars['String'];
};

export enum MessageFormat {
  Html = 'HTML',
  Slate = 'SLATE'
}

export type MessagePayload = Payload & {
  __typename?: 'MessagePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  message: Message;
  success: Scalars['Boolean'];
};

export type MultipleSelectConfig = {
  __typename?: 'MultipleSelectConfig';
  exclusive_option?: Maybe<ExclusiveOptionConfig>;
  range?: Maybe<ChoiceRangeConfig>;
};

export type MultipleSelectConfigInput = {
  exclusive_option?: Maybe<ExclusiveOptionConfigInput>;
  /** The range configuration limits the number of choices the user can make. */
  range?: Maybe<ChoiceRangeConfigInput>;
};

export type MultipleSelectQuestionConfigInput = {
  /** The multiple-select specific configuration for the question. */
  multiple_select: MultipleSelectConfigInput;
};

export type Mutation = {
  __typename?: 'Mutation';
  AddChecklistToStep: AddChecklistToStepPayload;
  AddMessageToStep: AddMessageToStepPayload;
  activateTrigger: ActivateTriggerPayload;
  addActionToStep: AddActionToStepPayload;
  addConditionToRule: AddConditionToRulePayload;
  addConstant: AddConstantPayload;
  addCustomActionToPathway: EmptyPayload;
  addCustomFieldToPathway: EmptyPayload;
  addDataPointDefinitionToPathway: AddDataPointDefinitionToPathwayPayload;
  addDataPointMappingToExtensionAction: AddDataPointMappingToExtensionActionPayload;
  addDecisionToTrack: DecisionPayload;
  addDerivedDataPointDefinition: AddDerivedDataPointDefinitionPayload;
  addLabelToStep: AddLabelToStepPayload;
  addLocationToPathway: AddLocationToPathwayPayload;
  addMappingToApiCall: AddMappingToApiCallPayload;
  addMessageAttachment: MessagePayload;
  addNarrativeToClinicalNote: ClinicalNotePayload;
  /**
   * Adds a single question to a specific form. By default, the question will be added at the end of the form. If the previous_question_id is provided, the question will be added after the question with that ID.
   *
   * Tags: form-design.
   */
  addQuestionToForm: AddQuestionToFormPayload;
  /**
   * Adds multiple questions to a specific form in a batch operation.
   *
   * Tags: form-design.
   * AI hint: Use this method to efficiently update a form with multiple questions, optimizing performance and consistency. The questions will be added to the form in the order they are provided in the input. Use a succinct but descriptive value in camelCase for each question key that is easy to understand and remember by humans.
   */
  addQuestionsToForm: AddQuestionsToFormPayload;
  addRuleToQuestion: AddRuleToQuestionPayload;
  addStepFromLibraryToTrack: AddStepFromLibraryToTrackPayload;
  addStepFromTemplate: StepPayload;
  addStepToLibrary: AddStepToLibraryPayload;
  addStepToTrack: AddStepToTrackPayload;
  addStickyNoteToTrack: AddStickyNoteToTrackPayload;
  addTimerToTrack: TimerPayload;
  addTrack: EmptyPayload;
  addTrackToPathway: AddTrackToPathwayPayload;
  addTransformationToDynamicVariable: AddTransformationToDynamicVariablePayload;
  addTriggerTimer: TriggerPayload;
  addTriggerToPathway: AddTriggerToPathwayPayload;
  addTriggerToTrack: AddTriggerToTrackPayload;
  addWebhook: AddWebhookPayload;
  clearActionDueDate: ClearActionDueDatePayload;
  clearActionIdleTime: ClearActionIdleTimePayload;
  clearTimingDelay: ClearTimingDelayPayload;
  clearTimingReference: ClearTimingReferencePayload;
  clearViewModels: EmptyPayload;
  configureAhpLink: AhpLinkPayload;
  configureExtension: ConfigureExtensionPayload;
  connectSteps: ConnectStepsPayload;
  createDynamicVariable: CreateDynamicVariablePayload;
  createForm: CreateFormPayload;
  createMessage: CreateMessagePayload;
  createPathway: CreatePathwayPayload;
  /**
   * Creates pathway case for a pathway.
   *       Pathway Case represents a single execution course in a pathway
   *       that is determined by evaluating transition and timing conditions
   *       based on provided input by the stakeholders of a pathway (patient, doctors, etc.)
   */
  createPathwayCase: CreatePathwayCasePayload;
  createTransition: CreateTransitionPayload;
  deleteDecision: EmptyPayload;
  /** Delete the pathway case */
  deletePathwayCase: DeletePathwayCasePayload;
  deleteTimer: EmptyPayload;
  duplicateCareflow: DuplicateCareflowPayload;
  duplicateQuestion: DuplicateQuestionPayload;
  duplicateStep: DuplicateStepPayload;
  endTrackAfterStep: EndTrackAfterStepPayload;
  evaluateFormRules: EvaluateFormRulesPayload;
  executeTestApiCall: ExecuteTestApiCallPayload;
  exportPathway: EmptyPayload;
  importPathway: EmptyPayload;
  markReleaseAsLive: MarkReleaseAsLivePayload;
  massResetTeam: EmptyPayload;
  publishPathway: PublishPathwayPayload;
  readMessage: ReadMessagePayload;
  rebuildGraph: EmptyPayload;
  rebuildGraphs: RebuildViewModelsPayload;
  rebuildViewModels: RebuildViewModelsPayload;
  recomputeCalculationDataSources: EmptyPayload;
  refreshDefinitionReferences: EmptyPayload;
  refreshExtension: RefreshExtensionPayload;
  removeActionFromStep: RemoveActionFromStepPayload;
  removeClinicalNoteNarrative: RemoveClinicalNoteNarrativePayload;
  removeConditionFromRule: RemoveConditionFromRulePayload;
  removeConstant: RemoveConstantPayload;
  removeDataPointDefinitionFromPathway: EmptyPayload;
  removeDataPointMappingFromExtensionAction: RemoveDataPointMappingFromExtensionActionPayload;
  removeDynamicVariable: EmptyPayload;
  removeExtensionConfiguration: EmptyPayload;
  removeLabelsFromStep: RemoveLabelsFromStepPayload;
  removeMappingFromApiCall: RemoveMappingFromApiCallPayload;
  removeMessageAttachment: MessagePayload;
  /**
   * Removes one specific question from a form.
   *
   * Tags: form-design.
   */
  removeQuestionFromForm: RemoveQuestionFromFormPayload;
  removeRuleFromQuestion: RemoveRuleFromQuestionPayload;
  removeStepFromLibrary: EmptyPayload;
  removeStepFromTrack: RemoveStepFromTrackPayload;
  removeStickyNoteFromTrack: EmptyPayload;
  removeTrackFromPathway: RemoveTrackFromPathwayPayload;
  removeTransformationsFromDynamicVariable: RemoveTransformationsFromDynamicVariablePayload;
  removeTransition: RemoveTransitionPayload;
  removeTriggerFromPathway: EmptyPayload;
  removeTriggerFromTrack: EmptyPayload;
  removeTriggerTimer: TriggerPayload;
  removeWebhook: RemoveWebhookPayload;
  reorderActions: ReorderActionsPayload;
  reorderMessageAttachments: MessagePayload;
  /**
   * Reorders one specific question within a form. When previous_question_id is provided, the question will be reordered after the question with that ID. When previous_question_id is not provided, the question will be reordered to the first position.
   *
   * Tags: form-design.
   */
  reorderQuestions: ReorderQuestionsPayload;
  repairPathways: RepairPathwaysPayload;
  resetPatientProfileDataPointDefinitions: EmptyPayload;
  resetPreview: ResetPreviewPayload;
  restoreCheckpoint: CheckpointPayload;
  retryAllFailedWebhookCalls: EmptyPayload;
  retryAllWebhookCalls: EmptyPayload;
  retryApiCall: EmptyPayload;
  retryWebhookCall: RetryWebhookCallPayload;
  saveCheckpoint: CheckpointPayload;
  saveMessage: MessagePayload;
  setActionCalculationId: SetActionCalculationIdPayload;
  setActionCalculationInput: SetActionCalculationInputPayload;
  setActionChecklistItems: SetActionChecklistItemsPayload;
  setActionDueDate: SetActionDueDatePayload;
  setActionFormDisplayMode: SetActionFormDisplayModePayload;
  setActionFormId: SetActionFormIdPayload;
  setActionIdleTime: SetActionIdleTimePayload;
  setActionMessageId: SetActionMessageIdPayload;
  setActionStakeholders: SetActionStakeholdersPayload;
  setActionTitle: SetActionTitlePayload;
  setApiCallBody: SetApiCallBodyPayload;
  setApiCallEndpoint: SetApiCallEndpointPayload;
  setApiCallHeaders: SetApiCallHeadersPayload;
  setApiCallMethod: SetApiCallMethodPayload;
  setBrandingAccentColor: SetBrandingAccentColorPayload;
  setBrandingCustomTheme: SetBrandingCustomThemePayload;
  setBrandingHostedPageAutoProgress: SetBrandingHostedPageAutoProgressPayload;
  setBrandingHostedPageAutosave: SetBrandingHostedPageAutosavePayload;
  setBrandingHostedPageTitle: SetBrandingHostedPageTitlePayload;
  setBrandingLogoUrl: SetBrandingLogoUrlPayload;
  setClinicalNoteNarrativeTitle: ClinicalNotePayload;
  setConditionOperand: SetConditionOperandPayload;
  setConditionOperator: SetConditionOperatorPayload;
  setConditionReference: SetConditionReferencePayload;
  setCustomActionField: SetCustomActionFieldPayload;
  setDataPointDefinitionMetaDataField: SetDataPointDefinitionMetaDataFieldPayload;
  setDataPointDefinitionOptionalField: SetDataPointDefinitionOptionalFieldPayload;
  setDataPointDefinitionPiiField: SetDataPointDefinitionPiiFieldPayload;
  /**
   * Updates the configuration for a date-type question in a form.
   *
   * Tags: form-design.
   */
  setDateQuestionConfig: SetDateQuestionConfigPayload;
  setDecisionCoordinates: DecisionPayload;
  setDecisionInputs: DecisionPayload;
  setDynamicVariableDataPointDefinition: SetDynamicVariableDataPointDefinitionPayload;
  setDynamicVariableDataPointProperty: SetDynamicVariableDataPointPropertyPayload;
  setDynamicVariableFallback: SetDynamicVariableFallbackPayload;
  setDynamicVariableLabel: SetDynamicVariableLabelPayload;
  setEmrReportBody: EmrReportPayload;
  setExtensionActionDataPointMappingDataPoint: SetExtensionActionDataPointMappingDataPointPayload;
  setExtensionActionDataPointMappingKey: SetExtensionActionDataPointMappingKeyPayload;
  setExtensionActionDataPointMappings: SetExtensionActionDataPointMappingsPayload;
  setExtensionActionField: SetExtensionActionFieldPayload;
  /**
   * Updates the configuration for a file-type question in a form.
   *
   * Tags: form-design.
   */
  setFileQuestionConfig: SetFileQuestionConfigPayload;
  /**
   * Updates the unique key associated with a form.
   *
   * Tags: form-design.
   * AI hint: Use a succinct but descriptive key that is easy to understand and remember by humans. It should be in camelCase.
   */
  setFormKey: SetFormKeyPayload;
  setFormMetadata: SetFormMetadataPayload;
  /**
   * Updates the title of a form.
   *
   * Tags: form-design.
   * AI hint: Use a name that is associated with the content of the form and has no more than 4 words.
   */
  setFormTitle: SetFormTitlePayload;
  setFormTrademark: SetFormTrademarkPayload;
  setMessageAttachmentName: MessagePayload;
  setMessageAttachmentUrl: MessagePayload;
  setMessageBody: MessagePayload;
  setMessageSubject: MessagePayload;
  /**
   * Updates the configuration for a multiple-select question in a form.
   *
   * Tags: form-design.
   * AI hint: Use the range configuration to limit the number of choices the user can make. Do not use the exclusive option configuration as it is deprecated.
   */
  setMultipleSelectQuestionConfig: SetMultipleSelectQuestionConfigPayload;
  /**
   * Updates the configuration for a number-type question in a form.
   *
   * Tags: form-design.
   */
  setNumberQuestionConfig: SetNumberQuestionConfigPayload;
  setPathwayCaseTitle: SetPathwayCaseTitlePayload;
  setPathwayTitle: SetPathwayTitlePayload;
  /**
   * Updates the configuration for a phone-type question in a form.
   *
   * Tags: form-design.
   */
  setPhoneQuestionConfig: SetPhoneQuestionConfigPayload;
  /**
   * Updates the general configuration for a single question in a form.
   *
   * Tags: form-design.
   */
  setQuestionConfig: SetQuestionConfigPayload;
  /**
   * Updates the unique key associated with a question in a form.
   *
   * Tags: form-design.
   * AI hint: Use a succinct but descriptive key that is easy to understand and remember by humans. It should be in camelCase.
   */
  setQuestionKey: SetQuestionKeyPayload;
  setQuestionMetadata: SetQuestionMetadataPayload;
  /** Updates the value type for a specific question. The value type determines the type of data that will be collected for this question when a user answers it. Note that changing the value type is a potentially destructive operation as it invalidates all the existing usage of the answer in transitions, variables, conditions etc. */
  setQuestionOptionValueType: SetQuestionOptionValueTypePayload;
  /**
   * Updates the general configuration for multiple questions in a form.
   *
   * Tags: form-design.
   * AI hint: Use this method to efficiently make multiple questions mandatory or optional.
   */
  setQuestionsConfig: SetQuestionsConfigPayload;
  setRemindersAmount: SetRemindersAmountPayload;
  setRemindersDelay: SetRemindersDelayPayload;
  setRuleBooleanOperator: SetRuleBooleanOperatorPayload;
  setRuleType: SetRuleTypePayload;
  /**
   * Updates the configuration for a slider-type question in a form.
   *
   * Tags: form-design.
   */
  setSliderQuestionConfig: SetSliderQuestionConfigPayload;
  setStakeholderEmails: SetStakeholderEmailsPayload;
  setStepCoordinates: SetStepCoordinatesPayload;
  setStepDocumentation: SetStepDocumentationPayload;
  setStepTitle: SetStepTitlePayload;
  setStickyNoteCoordinates: SetStickyNoteCoordinatesPayload;
  setTimerCoordinates: TimerPayload;
  setTimingDelay: SetTimingDelayPayload;
  setTimingReference: SetTimingReferencePayload;
  setTrackEndCoordinates: SetTrackEndCoordinatesPayload;
  setTrackStartCoordinates: SetTrackStartCoordinatesPayload;
  /** Setting track title */
  setTrackTitle: SetTrackTitlePayload;
  setTransformationParams: SetTransformationParamsPayload;
  setTransformationType: SetTransformationTypePayload;
  setTransitionDestination: SetTransitionDestinationPayload;
  setTriggerDataPointDefinition: TriggerPayload;
  setTriggerDelay: TriggerPayload;
  setTriggerSettings: TriggerPayload;
  setTriggerStep: TriggerPayload;
  setTriggerTimerDataPointDefinition: TriggerPayload;
  setTriggerTimerStep: TriggerPayload;
  setTriggerTimerTrack: TriggerPayload;
  setTriggerTimerType: TriggerPayload;
  setTriggerTrack: TriggerPayload;
  setTriggerType: TriggerPayload;
  shareForms: EmptyPayload;
  sharePathways: EmptyPayload;
  startPreview: StartPreviewPayload;
  startTrackFromStep: StartTrackFromStepPayload;
  startTransitionFromStep: StartTransitionFromStepPayload;
  submitChecklist: SubmitChecklistPayload;
  submitFormResponse: SubmitFormResponsePayload;
  testCareFlowSourceControlSettings: TestSourceControlPayload;
  transferPathway: EmptyPayload;
  trashPathway: EmptyPayload;
  triggerApiCall: TriggerApiCallPayload;
  updateApiCallMappingDataPoint: UpdateApiCallMappingDataPointPayload;
  updateApiCallMappingFirstMatchOnly: UpdateApiCallMappingFirstMatchOnlyPayload;
  updateApiCallMappingKey: UpdateApiCallMappingKeyPayload;
  updateCareFlowApiCallRetrySettings: UpdateCareFlowApiCallRetrySettingsPayload;
  updateCareFlowSourceControlSettings: UpdateCareFlowSourceControlSettingsPayload;
  updateClinicalNoteContext: UpdateClinicalNoteContextPayload;
  updateClinicalNoteNarrativeBody: ClinicalNotePayload;
  updateConstant: UpdateConstantPayload;
  updateDataPointDefinition: UpdateDataPointDefinitionPayload;
  updateDecision: DecisionPayload;
  updateDynamicVariable: UpdateDynamicVariablePayload;
  updateEmailNotificationStatus: UpdateEmailNotificationStatusPayload;
  updateLabelText: UpdateLabelTextPayload;
  /**
   * Updates the title and options for a question in a form.
   *
   * Tags: form-design.
   */
  updateQuestion: UpdateQuestionPayload;
  updateStickyNoteBody: UpdateStickyNoteBodyPayload;
  updateTimerConfig: TimerPayload;
  updateWebhook: UpdateWebhookPayload;
  updateWebhookEndpoint: UpdateWebhookEndpointPayload;
  updateWebhookHeaders: UpdateWebhookHeadersPayload;
  updateWebhookName: UpdateWebhookNamePayload;
  updateWebhookStatus: UpdateWebhookStatusPayload;
  updateWebhookSubscribedEvents: UpdateWebhookSubscribedEventsPayload;
  updateWebhookTestEndpoint: UpdateWebhookTestEndpointPayload;
};


export type MutationAddChecklistToStepArgs = {
  input: AddChecklistToStepInput;
};


export type MutationAddMessageToStepArgs = {
  input: AddMessageToStepInput;
};


export type MutationActivateTriggerArgs = {
  input: ActivateTriggerInput;
};


export type MutationAddActionToStepArgs = {
  input: AddActionToStepInput;
};


export type MutationAddConditionToRuleArgs = {
  input: AddConditionToRuleInput;
};


export type MutationAddConstantArgs = {
  input: AddConstantInput;
};


export type MutationAddCustomActionToPathwayArgs = {
  input: AddCustomActionToPathwayInput;
};


export type MutationAddCustomFieldToPathwayArgs = {
  input: AddCustomFieldToPathwayInput;
};


export type MutationAddDataPointDefinitionToPathwayArgs = {
  input: AddDataPointDefinitionToPathwayInput;
};


export type MutationAddDataPointMappingToExtensionActionArgs = {
  input: AddDataPointMappingToExtensionActionInput;
};


export type MutationAddDecisionToTrackArgs = {
  input: AddDecisionToTrackInput;
};


export type MutationAddDerivedDataPointDefinitionArgs = {
  input: AddDerivedDataPointDefinitionInput;
};


export type MutationAddLabelToStepArgs = {
  input: AddLabelToStepInput;
};


export type MutationAddLocationToPathwayArgs = {
  input: AddLocationToPathwayInput;
};


export type MutationAddMappingToApiCallArgs = {
  input: AddMappingToApiCallInput;
};


export type MutationAddMessageAttachmentArgs = {
  input: AddMessageAttachmentInput;
};


export type MutationAddNarrativeToClinicalNoteArgs = {
  input: AddNarrativeToClinicalNoteInput;
};


export type MutationAddQuestionToFormArgs = {
  input: AddQuestionToFormInput;
};


export type MutationAddQuestionsToFormArgs = {
  input: AddQuestionsToFormInput;
};


export type MutationAddRuleToQuestionArgs = {
  input: AddRuleToQuestionInput;
};


export type MutationAddStepFromLibraryToTrackArgs = {
  input: AddStepFromLibraryToTrackInput;
};


export type MutationAddStepFromTemplateArgs = {
  input: AddStepFromTemplateInput;
};


export type MutationAddStepToLibraryArgs = {
  input: AddStepToLibraryInput;
};


export type MutationAddStepToTrackArgs = {
  input: AddStepToTrackInput;
};


export type MutationAddStickyNoteToTrackArgs = {
  input: AddStickyNoteToTrackInput;
};


export type MutationAddTimerToTrackArgs = {
  input: AddTimerToTrackInput;
};


export type MutationAddTrackArgs = {
  input: AddTrackInput;
};


export type MutationAddTrackToPathwayArgs = {
  input: AddTrackToPathwayInput;
};


export type MutationAddTransformationToDynamicVariableArgs = {
  input: AddTransformationToDynamicVariableInput;
};


export type MutationAddTriggerTimerArgs = {
  input: AddTriggerTimerInput;
};


export type MutationAddTriggerToPathwayArgs = {
  input: AddTriggerToPathwayInput;
};


export type MutationAddTriggerToTrackArgs = {
  input: AddTriggerToTrackInput;
};


export type MutationAddWebhookArgs = {
  input: AddWebhookInput;
};


export type MutationClearActionDueDateArgs = {
  input: ClearActionDueDateInput;
};


export type MutationClearActionIdleTimeArgs = {
  input: ClearActionIdleTimeInput;
};


export type MutationClearTimingDelayArgs = {
  input: ClearTimingDelayInput;
};


export type MutationClearTimingReferenceArgs = {
  input: ClearTimingReferenceInput;
};


export type MutationConfigureAhpLinkArgs = {
  input: ConfigureAhpLinkInput;
};


export type MutationConfigureExtensionArgs = {
  input: ConfigureExtensionInput;
};


export type MutationConnectStepsArgs = {
  input: ConnectStepsInput;
};


export type MutationCreateDynamicVariableArgs = {
  input: CreateDynamicVariableInput;
};


export type MutationCreateFormArgs = {
  input: CreateFormInput;
};


export type MutationCreatePathwayArgs = {
  input: CreatePathwayInput;
};


export type MutationCreatePathwayCaseArgs = {
  input: CreatePathwayCaseInput;
};


export type MutationCreateTransitionArgs = {
  input: CreateTransitionInput;
};


export type MutationDeleteDecisionArgs = {
  input: DeleteDecisionInput;
};


export type MutationDeletePathwayCaseArgs = {
  input: DeletePathwayCaseInput;
};


export type MutationDeleteTimerArgs = {
  input: DeleteTimerInput;
};


export type MutationDuplicateCareflowArgs = {
  input: DuplicateCareflowInput;
};


export type MutationDuplicateQuestionArgs = {
  input: DuplicateQuestionInput;
};


export type MutationDuplicateStepArgs = {
  input: DuplicateStepInput;
};


export type MutationEndTrackAfterStepArgs = {
  input: EndTrackAfterStepInput;
};


export type MutationEvaluateFormRulesArgs = {
  input: EvaluateFormRulesInput;
};


export type MutationExecuteTestApiCallArgs = {
  input: ExecuteTestApiCallInput;
};


export type MutationExportPathwayArgs = {
  input: ExportPathwayInput;
};


export type MutationImportPathwayArgs = {
  input: ImportPathwayInput;
};


export type MutationMarkReleaseAsLiveArgs = {
  input: MarkReleaseAsLiveInput;
};


export type MutationMassResetTeamArgs = {
  input: MassResetTeamInput;
};


export type MutationPublishPathwayArgs = {
  input: PublishPathwayInput;
};


export type MutationReadMessageArgs = {
  input: ReadMessageInput;
};


export type MutationRebuildGraphArgs = {
  pathway_definition_id: Scalars['String'];
};


export type MutationRebuildGraphsArgs = {
  input: RebuildInput;
};


export type MutationRebuildViewModelsArgs = {
  input: RebuildInput;
};


export type MutationRefreshExtensionArgs = {
  input: RefreshExtensionInput;
};


export type MutationRemoveActionFromStepArgs = {
  input: RemoveActionFromStepInput;
};


export type MutationRemoveClinicalNoteNarrativeArgs = {
  input: RemoveClinicalNoteNarrativeInput;
};


export type MutationRemoveConditionFromRuleArgs = {
  input: RemoveConditionFromRuleInput;
};


export type MutationRemoveConstantArgs = {
  input: RemoveConstantInput;
};


export type MutationRemoveDataPointDefinitionFromPathwayArgs = {
  input: RemoveDataPointDefinitionFromPathwayInput;
};


export type MutationRemoveDataPointMappingFromExtensionActionArgs = {
  input: RemoveDataPointMappingFromExtensionActionInput;
};


export type MutationRemoveDynamicVariableArgs = {
  input: RemoveDynamicVariableInput;
};


export type MutationRemoveExtensionConfigurationArgs = {
  input: RemoveExtensionConfigurationInput;
};


export type MutationRemoveLabelsFromStepArgs = {
  input: RemoveLabelsFromStepInput;
};


export type MutationRemoveMappingFromApiCallArgs = {
  input: RemoveMappingFromApiCallInput;
};


export type MutationRemoveMessageAttachmentArgs = {
  input: RemoveMessageAttachmentInput;
};


export type MutationRemoveQuestionFromFormArgs = {
  input: RemoveQuestionFromFormInput;
};


export type MutationRemoveRuleFromQuestionArgs = {
  input: RemoveRuleFromQuestionInput;
};


export type MutationRemoveStepFromLibraryArgs = {
  input: RemoveStepFromLibraryInput;
};


export type MutationRemoveStepFromTrackArgs = {
  input: RemoveStepFromTrackInput;
};


export type MutationRemoveStickyNoteFromTrackArgs = {
  input: RemoveStickyNoteFromTrackInput;
};


export type MutationRemoveTrackFromPathwayArgs = {
  input: RemoveTrackFromPathwayInput;
};


export type MutationRemoveTransformationsFromDynamicVariableArgs = {
  input: RemoveTransformationsFromDynamicVariableInput;
};


export type MutationRemoveTransitionArgs = {
  input: RemoveTransitionInput;
};


export type MutationRemoveTriggerFromPathwayArgs = {
  input: RemoveTriggerFromPathwayInput;
};


export type MutationRemoveTriggerFromTrackArgs = {
  input: RemoveTriggerFromTrackInput;
};


export type MutationRemoveTriggerTimerArgs = {
  input: RemoveTriggerTimerInput;
};


export type MutationRemoveWebhookArgs = {
  input: RemoveWebhookInput;
};


export type MutationReorderActionsArgs = {
  input: ReorderActionsInput;
};


export type MutationReorderMessageAttachmentsArgs = {
  input: ReorderMessageAttachmentsInput;
};


export type MutationReorderQuestionsArgs = {
  input: ReorderQuestionsInput;
};


export type MutationRepairPathwaysArgs = {
  input: RepairPathwaysInput;
};


export type MutationResetPatientProfileDataPointDefinitionsArgs = {
  input: ResetPatientProfileDataPointDefinitionsInput;
};


export type MutationResetPreviewArgs = {
  input: ResetPreviewInput;
};


export type MutationRestoreCheckpointArgs = {
  input: RestoreCheckpointInput;
};


export type MutationRetryAllFailedWebhookCallsArgs = {
  input: RetryAllFailedWebhookCallsInput;
};


export type MutationRetryAllWebhookCallsArgs = {
  input: RetryAllWebhookCallsInput;
};


export type MutationRetryApiCallArgs = {
  input: RetryApiCallInput;
};


export type MutationRetryWebhookCallArgs = {
  input: RetryWebhookCallInput;
};


export type MutationSaveCheckpointArgs = {
  input: SaveCheckpointInput;
};


export type MutationSaveMessageArgs = {
  input: SaveMessageInput;
};


export type MutationSetActionCalculationIdArgs = {
  input: SetActionCalculationIdInput;
};


export type MutationSetActionCalculationInputArgs = {
  input: SetCalculationDataPointInput;
};


export type MutationSetActionChecklistItemsArgs = {
  input: SetActionChecklistItemsInput;
};


export type MutationSetActionDueDateArgs = {
  input: ActionDueDateInput;
};


export type MutationSetActionFormDisplayModeArgs = {
  input: SetActionFormDisplayModeInput;
};


export type MutationSetActionFormIdArgs = {
  input: SetActionFormIdInput;
};


export type MutationSetActionIdleTimeArgs = {
  input: SetActionIdleTimeInput;
};


export type MutationSetActionMessageIdArgs = {
  input: SetActionMessageIdInput;
};


export type MutationSetActionStakeholdersArgs = {
  input: SetActionStakeholdersInput;
};


export type MutationSetActionTitleArgs = {
  input: SetActionTitleInput;
};


export type MutationSetApiCallBodyArgs = {
  input: SetApiCallBodyInput;
};


export type MutationSetApiCallEndpointArgs = {
  input: SetApiCallEndpointInput;
};


export type MutationSetApiCallHeadersArgs = {
  input: SetApiCallHeadersInput;
};


export type MutationSetApiCallMethodArgs = {
  input: SetApiCallMethodInput;
};


export type MutationSetBrandingAccentColorArgs = {
  input: SetBrandingAccentColorInput;
};


export type MutationSetBrandingCustomThemeArgs = {
  input: SetBrandingCustomThemeInput;
};


export type MutationSetBrandingHostedPageAutoProgressArgs = {
  input: SetBrandingHostedPageAutoProgressInput;
};


export type MutationSetBrandingHostedPageAutosaveArgs = {
  input: SetBrandingHostedPageAutosaveInput;
};


export type MutationSetBrandingHostedPageTitleArgs = {
  input: SetBrandingHostedPageTitleInput;
};


export type MutationSetBrandingLogoUrlArgs = {
  input: SetBrandingLogoUrlInput;
};


export type MutationSetClinicalNoteNarrativeTitleArgs = {
  input: SetClinicalNoteNarrativeTitleInput;
};


export type MutationSetConditionOperandArgs = {
  input: SetConditionOperandInput;
};


export type MutationSetConditionOperatorArgs = {
  input: SetConditionOperatorInput;
};


export type MutationSetConditionReferenceArgs = {
  input: SetConditionReferenceInput;
};


export type MutationSetCustomActionFieldArgs = {
  input: SetCustomActionFieldInput;
};


export type MutationSetDataPointDefinitionMetaDataFieldArgs = {
  input: SetDataPointDefinitionMetaDataFieldInput;
};


export type MutationSetDataPointDefinitionOptionalFieldArgs = {
  input: SetDataPointDefinitionOptionalFieldInput;
};


export type MutationSetDataPointDefinitionPiiFieldArgs = {
  input: SetDataPointDefinitionPiiFieldInput;
};


export type MutationSetDateQuestionConfigArgs = {
  input: SetDateQuestionConfigInput;
};


export type MutationSetDecisionCoordinatesArgs = {
  input: SetDecisionCoordinatesInput;
};


export type MutationSetDecisionInputsArgs = {
  input: SetDecisionInputsInput;
};


export type MutationSetDynamicVariableDataPointDefinitionArgs = {
  input: SetDynamicVariableDataPointDefinitionInput;
};


export type MutationSetDynamicVariableDataPointPropertyArgs = {
  input: SetDynamicVariableDataPointPropertyInput;
};


export type MutationSetDynamicVariableFallbackArgs = {
  input: SetDynamicVariableFallbackInput;
};


export type MutationSetDynamicVariableLabelArgs = {
  input: SetDynamicVariableLabelInput;
};


export type MutationSetEmrReportBodyArgs = {
  input: SetEmrReportBodyInput;
};


export type MutationSetExtensionActionDataPointMappingDataPointArgs = {
  input: SetExtensionActionDataPointMappingDataPointInput;
};


export type MutationSetExtensionActionDataPointMappingKeyArgs = {
  input: SetExtensionActionDataPointMappingKeyInput;
};


export type MutationSetExtensionActionDataPointMappingsArgs = {
  input: SetExtensionActionDataPointMappingsInput;
};


export type MutationSetExtensionActionFieldArgs = {
  input: SetExtensionActionFieldInput;
};


export type MutationSetFileQuestionConfigArgs = {
  input: FileQuestionConfigInput;
};


export type MutationSetFormKeyArgs = {
  input: SetFormKeyInput;
};


export type MutationSetFormMetadataArgs = {
  input: SetFormMetadataInput;
};


export type MutationSetFormTitleArgs = {
  input: SetFormTitleInput;
};


export type MutationSetFormTrademarkArgs = {
  input: SetFormTrademarkInput;
};


export type MutationSetMessageAttachmentNameArgs = {
  input: SetMessageAttachmentNameInput;
};


export type MutationSetMessageAttachmentUrlArgs = {
  input: SetMessageAttachmentUrlInput;
};


export type MutationSetMessageBodyArgs = {
  input: SetMessageBodyInput;
};


export type MutationSetMessageSubjectArgs = {
  input: SetMessageSubjectInput;
};


export type MutationSetMultipleSelectQuestionConfigArgs = {
  input: SetMultipleSelectQuestionConfigInput;
};


export type MutationSetNumberQuestionConfigArgs = {
  input: SetNumberQuestionConfigInput;
};


export type MutationSetPathwayCaseTitleArgs = {
  input: SetPathwayCaseTitleInput;
};


export type MutationSetPathwayTitleArgs = {
  input: SetPathwayTitleInput;
};


export type MutationSetPhoneQuestionConfigArgs = {
  input: SetPhoneQuestionConfigInput;
};


export type MutationSetQuestionConfigArgs = {
  input: SetQuestionConfigInput;
};


export type MutationSetQuestionKeyArgs = {
  input: SetQuestionKeyInput;
};


export type MutationSetQuestionMetadataArgs = {
  input: SetQuestionMetadataInput;
};


export type MutationSetQuestionOptionValueTypeArgs = {
  input: SetQuestionOptionValueTypeInput;
};


export type MutationSetQuestionsConfigArgs = {
  input: SetQuestionsConfigInput;
};


export type MutationSetRemindersAmountArgs = {
  input: SetRemindersAmountInput;
};


export type MutationSetRemindersDelayArgs = {
  input: SetRemindersDelayInput;
};


export type MutationSetRuleBooleanOperatorArgs = {
  input: SetRuleBooleanOperatorInput;
};


export type MutationSetRuleTypeArgs = {
  input: SetRuleTypeInput;
};


export type MutationSetSliderQuestionConfigArgs = {
  input: SetSliderQuestionConfigInput;
};


export type MutationSetStakeholderEmailsArgs = {
  input: SetStakeholderEmailsInput;
};


export type MutationSetStepCoordinatesArgs = {
  input: SetStepCoordinatesInput;
};


export type MutationSetStepDocumentationArgs = {
  input: SetStepDocumentationInput;
};


export type MutationSetStepTitleArgs = {
  input: SetStepTitleInput;
};


export type MutationSetStickyNoteCoordinatesArgs = {
  input: SetStickyNoteCoordinatesInput;
};


export type MutationSetTimerCoordinatesArgs = {
  input: SetTimerCoordinatesInput;
};


export type MutationSetTimingDelayArgs = {
  input: SetTimingDelayInput;
};


export type MutationSetTimingReferenceArgs = {
  input: SetTimingReferenceInput;
};


export type MutationSetTrackEndCoordinatesArgs = {
  input: SetTrackEndCoordinatesInput;
};


export type MutationSetTrackStartCoordinatesArgs = {
  input: SetTrackStartCoordinatesInput;
};


export type MutationSetTrackTitleArgs = {
  input: SetTrackTitleInput;
};


export type MutationSetTransformationParamsArgs = {
  input: SetTransformationParamsInput;
};


export type MutationSetTransformationTypeArgs = {
  input: SetTransformationTypeInput;
};


export type MutationSetTransitionDestinationArgs = {
  input: SetTransitionDestinationInput;
};


export type MutationSetTriggerDataPointDefinitionArgs = {
  input: SetTriggerDataPointDefinitionInput;
};


export type MutationSetTriggerDelayArgs = {
  input: SetTriggerDelayInput;
};


export type MutationSetTriggerSettingsArgs = {
  input: SetTriggerSettingsInput;
};


export type MutationSetTriggerStepArgs = {
  input: SetTriggerStepInput;
};


export type MutationSetTriggerTimerDataPointDefinitionArgs = {
  input: SetTriggerTimerDataPointDefinitionInput;
};


export type MutationSetTriggerTimerStepArgs = {
  input: SetTriggerTimerStepInput;
};


export type MutationSetTriggerTimerTrackArgs = {
  input: SetTriggerTimerTrackInput;
};


export type MutationSetTriggerTimerTypeArgs = {
  input: SetTriggerTimerTypeInput;
};


export type MutationSetTriggerTrackArgs = {
  input: SetTriggerTrackInput;
};


export type MutationSetTriggerTypeArgs = {
  input: SetTriggerTypeInput;
};


export type MutationShareFormsArgs = {
  input: ShareFormsInput;
};


export type MutationSharePathwaysArgs = {
  input: SharePathwaysInput;
};


export type MutationStartPreviewArgs = {
  input: StartPreviewInput;
};


export type MutationStartTrackFromStepArgs = {
  input: StartTrackFromStepInput;
};


export type MutationStartTransitionFromStepArgs = {
  input: StartTransitionFromStepInput;
};


export type MutationSubmitChecklistArgs = {
  input: SubmitChecklistInput;
};


export type MutationSubmitFormResponseArgs = {
  input: SubmitFormResponseInput;
};


export type MutationTestCareFlowSourceControlSettingsArgs = {
  input: TestSourceControlInput;
};


export type MutationTransferPathwayArgs = {
  input: TransferPathwayInput;
};


export type MutationTrashPathwayArgs = {
  input: TrashPathwayInput;
};


export type MutationTriggerApiCallArgs = {
  input: TriggerApiCallInput;
};


export type MutationUpdateApiCallMappingDataPointArgs = {
  input: UpdateApiCallMappingDataPointInput;
};


export type MutationUpdateApiCallMappingFirstMatchOnlyArgs = {
  input: UpdateApiCallMappingFirstMatchOnlyInput;
};


export type MutationUpdateApiCallMappingKeyArgs = {
  input: UpdateApiCallMappingKeyInput;
};


export type MutationUpdateCareFlowApiCallRetrySettingsArgs = {
  input: UpdateCareFlowApiCallRetrySettingsInput;
};


export type MutationUpdateCareFlowSourceControlSettingsArgs = {
  input: UpdateCareFlowSourceControlSettingsInput;
};


export type MutationUpdateClinicalNoteContextArgs = {
  input: UpdateClinicalNoteContextInput;
};


export type MutationUpdateClinicalNoteNarrativeBodyArgs = {
  input: UpdateClinicalNoteNarrativeBodyInput;
};


export type MutationUpdateConstantArgs = {
  input: UpdateConstantInput;
};


export type MutationUpdateDataPointDefinitionArgs = {
  input: UpdateDataPointDefinitionInput;
};


export type MutationUpdateDecisionArgs = {
  input: UpdateDecisionInput;
};


export type MutationUpdateDynamicVariableArgs = {
  input: UpdateDynamicVariableInput;
};


export type MutationUpdateEmailNotificationStatusArgs = {
  input: UpdateEmailNotificationStatusInput;
};


export type MutationUpdateLabelTextArgs = {
  input: UpdateLabelTextInput;
};


export type MutationUpdateQuestionArgs = {
  input: UpdateQuestionInput;
};


export type MutationUpdateStickyNoteBodyArgs = {
  input: UpdateStickyNoteBodyInput;
};


export type MutationUpdateTimerConfigArgs = {
  input: UpdateTimerConfigInput;
};


export type MutationUpdateWebhookArgs = {
  input: UpdateWebhookInput;
};


export type MutationUpdateWebhookEndpointArgs = {
  input: UpdateWebhookEndpointInput;
};


export type MutationUpdateWebhookHeadersArgs = {
  input: UpdateWebhookHeadersInput;
};


export type MutationUpdateWebhookNameArgs = {
  input: UpdateWebhookNameInput;
};


export type MutationUpdateWebhookStatusArgs = {
  input: UpdateWebhookStatusInput;
};


export type MutationUpdateWebhookSubscribedEventsArgs = {
  input: UpdateWebhookSubscribedEventsInput;
};


export type MutationUpdateWebhookTestEndpointArgs = {
  input: UpdateWebhookTestEndpointInput;
};

export type NewConstantInput = {
  label: Scalars['String'];
  obfuscated: Scalars['Boolean'];
  readonly?: Maybe<Scalars['Boolean']>;
  value: Scalars['String'];
};

export type NewMappingDataPointDefinitionInput = {
  key: Scalars['String'];
  valueType: DataPointValueType;
};

export type NewWebhookInput = {
  enabled?: Scalars['Boolean'];
  endpoint: Scalars['String'];
  endpoint_test?: Maybe<Scalars['String']>;
  headers?: Array<WebhookHeaderInput>;
  name: Scalars['String'];
  subscribed_events?: Maybe<Array<Scalars['String']>>;
};

export type NotSupportedExtensionAction = {
  __typename?: 'NotSupportedExtensionAction';
  action: UsageContext;
  step: UsageContext;
  track: UsageContext;
};

export type NumberConfig = {
  __typename?: 'NumberConfig';
  range?: Maybe<RangeConfig>;
};

export type NumberConfigInput = {
  /** The range configuration limits the acceptable numerical answers for the question. */
  range?: Maybe<NumberRangeConfigInput>;
};

export type NumberQuestionConfigInput = {
  /** The number-specific configuration for the question. */
  number: NumberConfigInput;
};

export type NumberRangeConfigInput = {
  /** Enable this flag to limit the acceptable numerical answers for the question. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The maximum acceptable numerical answer for the question. */
  max?: Maybe<Scalars['Float']>;
  /** The minimum acceptable numerical answer for the question. */
  min?: Maybe<Scalars['Float']>;
};

export type NumericArrayField = ExtensionActionField & {
  __typename?: 'NumericArrayField';
  description?: Maybe<Scalars['String']>;
  dropdownOptions?: Maybe<Array<ExtensionDropdownOption>>;
  id: Scalars['ID'];
  label: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  type: ExtensionActionFieldType;
  /** Value is kept as string because it can contain data point definition id in handlebars template, otherwise it is just "array of numbers" */
  value?: Maybe<Scalars['String']>;
};

export type NumericField = ExtensionActionField & {
  __typename?: 'NumericField';
  description?: Maybe<Scalars['String']>;
  dropdownOptions?: Maybe<Array<ExtensionDropdownOption>>;
  id: Scalars['ID'];
  label: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  type: ExtensionActionFieldType;
  /** Value is kept as string because it can contain data point definition id in handlebars template, otherwise it is just "number" */
  value?: Maybe<Scalars['String']>;
};

export type OntologyEdge = {
  __typename?: 'OntologyEdge';
  id: Scalars['String'];
  source: Scalars['String'];
  target: Scalars['String'];
  type: OntologyEdgeType;
};

export enum OntologyEdgeType {
  Contains = 'CONTAINS',
  HasChildResource = 'HAS_CHILD_RESOURCE',
  HasProperty = 'HAS_PROPERTY',
  References = 'REFERENCES'
}

export type OntologyNode = {
  __typename?: 'OntologyNode';
  actionable_properties?: Maybe<Array<OntologyNode>>;
  children?: Maybe<Array<OntologyNode>>;
  id: Scalars['String'];
  /** Plain text representation of this node */
  text: Scalars['String'];
  type: OntologyNodeType;
  valueType?: Maybe<DataPointValueType>;
};

export type OntologyNodePayload = Payload & {
  __typename?: 'OntologyNodePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  node?: Maybe<OntologyNode>;
  success: Scalars['Boolean'];
};

export enum OntologyNodeType {
  Action = 'Action',
  ActionableProperty = 'ActionableProperty',
  CareFlow = 'CareFlow',
  ObservationDefinition = 'ObservationDefinition',
  ObservationDefinitionComponent = 'ObservationDefinitionComponent',
  Questionnaire = 'Questionnaire',
  QuestionnaireItem = 'QuestionnaireItem',
  SemanticProperty = 'SemanticProperty',
  Step = 'Step',
  Track = 'Track'
}

export type OntologyPayload = Payload & {
  __typename?: 'OntologyPayload';
  code: Scalars['String'];
  edges: Array<OntologyEdge>;
  error?: Maybe<ErrorObject>;
  nodes: Array<OntologyNode>;
  success: Scalars['Boolean'];
};

export enum OntologyResourceType {
  Action = 'Action',
  CareFlow = 'CareFlow',
  ObservationDefinition = 'ObservationDefinition',
  ObservationDefinitionComponent = 'ObservationDefinitionComponent',
  Step = 'Step',
  Track = 'Track'
}

export type Operand = {
  __typename?: 'Operand';
  type: ConditionOperandType;
  value: Scalars['String'];
};

export type OperandInput = {
  type: ConditionOperandType;
  value: Scalars['String'];
};

export type Option = {
  __typename?: 'Option';
  id: Scalars['ID'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type OptionInput = {
  /** The ID of the option. */
  id?: Maybe<Scalars['String']>;
  /** The label of the option which will be displayed to the user. */
  label?: Maybe<Scalars['String']>;
  /** The value of the option which will be saved as a data point. */
  value?: Maybe<Scalars['String']>;
};

export type PaginationAndSortingPayload = {
  code: Scalars['String'];
  pagination?: Maybe<PaginationOutput>;
  sorting?: Maybe<SortingOutput>;
  success: Scalars['Boolean'];
};

export type PaginationOutput = {
  __typename?: 'PaginationOutput';
  count?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  total_count?: Maybe<Scalars['Int']>;
};

export type PaginationParams = {
  count: Scalars['Int'];
  offset: Scalars['Int'];
};

/**
 * A pathway is a set of tracks.
 * Transitions join tracks together (see definition of a transition at |TBD|)
 * A pathway starts with a start node and ends with an end nodeS
 *
 * A patient in a pathway generates a pathway instance
 */
export type Pathway = {
  __typename?: 'Pathway';
  created: AuditTrail;
  custom_actions?: Maybe<Array<CustomAction>>;
  id: Scalars['ID'];
  is_archived?: Maybe<Scalars['Boolean']>;
  labels?: Maybe<Array<Label>>;
  last_published_version?: Maybe<PathwayVersion>;
  last_updated?: Maybe<AuditTrail>;
  locations?: Maybe<Array<Location>>;
  publishing_status: PublishingStatus;
  readonly?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<PathwaySettings>;
  stakeholders: Array<Stakeholder>;
  team_ids?: Maybe<Array<Scalars['String']>>;
  title: Scalars['String'];
  track_ids?: Maybe<Array<Scalars['String']>>;
  tracks?: Maybe<Array<Track>>;
  trashed?: Maybe<Trashed>;
  triggers: Array<Trigger>;
};

/** A pathway case, potentially including any activities and swimlanes. */
export type PathwayCase = {
  __typename?: 'PathwayCase';
  activities: Array<Activity>;
  created_by: PathwayCollaborator;
  id: Scalars['ID'];
  last_modification_date?: Maybe<Scalars['SafeDate']>;
  last_modified_by?: Maybe<PathwayCollaborator>;
  pathway_id: Scalars['String'];
  start_date?: Maybe<Scalars['SafeDate']>;
  status: PathwayCaseStatus;
  title: Scalars['String'];
};

export type PathwayCaseApiCall = {
  __typename?: 'PathwayCaseApiCall';
  created_at: Scalars['String'];
  id: Scalars['ID'];
  request: ApiCallRequest;
  responses: Array<ApiCallResponse>;
  status: ApiCallStatus;
  title: Scalars['String'];
};

export type PathwayCaseApiCallPayload = Payload & {
  __typename?: 'PathwayCaseApiCallPayload';
  api_call: PathwayCaseApiCall;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type PathwayCaseApiCallsPayload = Payload & {
  __typename?: 'PathwayCaseApiCallsPayload';
  api_calls: Array<PathwayCaseApiCall>;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type PathwayCasePayload = Payload & {
  __typename?: 'PathwayCasePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway_case: PathwayCase;
  success: Scalars['Boolean'];
};

export enum PathwayCaseStatus {
  Active = 'ACTIVE',
  Blank = 'BLANK',
  Completed = 'COMPLETED',
  MissingBaselineDatapoints = 'MISSING_BASELINE_DATAPOINTS',
  WaitingForManualTrigger = 'WAITING_FOR_MANUAL_TRIGGER'
}

export type PathwayCaseStepActivitiesInput = {
  pathway_case_id: Scalars['String'];
  step_id: Scalars['String'];
};

/** A summary of a pathway case, which excludes activities (and swimlanes). Used for list views. */
export type PathwayCaseSummary = {
  __typename?: 'PathwayCaseSummary';
  created_by: PathwayCollaborator;
  id: Scalars['ID'];
  last_modification_date?: Maybe<Scalars['SafeDate']>;
  last_modified_by?: Maybe<PathwayCollaborator>;
  pathway_id: Scalars['String'];
  start_date?: Maybe<Scalars['SafeDate']>;
  status: PathwayCaseStatus;
  title: Scalars['String'];
};

export type PathwayCaseWebhookCall = {
  __typename?: 'PathwayCaseWebhookCall';
  created_at: Scalars['String'];
  event_type: Scalars['String'];
  id: Scalars['ID'];
  pathway?: Maybe<ApiPathwayContext>;
  request: WebhookCallRequest;
  responses: Array<WebhookCallResponse>;
  status: Scalars['String'];
  webhook_id: Scalars['String'];
  webhook_name: Scalars['String'];
};

export type PathwayCaseWebhookCallPayload = Payload & {
  __typename?: 'PathwayCaseWebhookCallPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  webhook_call: PathwayCaseWebhookCall;
};

export type PathwayCaseWebhookCallsPayload = Payload & {
  __typename?: 'PathwayCaseWebhookCallsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  webhook_calls: Array<PathwayCaseWebhookCall>;
};

export type PathwayCasesInput = {
  pathway_id: Scalars['String'];
};

export type PathwayCasesPayload = Payload & {
  __typename?: 'PathwayCasesPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway_cases: Array<PathwayCaseSummary>;
  success: Scalars['Boolean'];
};

export type PathwayCollaborator = {
  __typename?: 'PathwayCollaborator';
  user_email?: Maybe<Scalars['String']>;
  user_id: Scalars['String'];
};

export type PathwayComponentLink = {
  __typename?: 'PathwayComponentLink';
  text: Scalars['String'];
  url: Scalars['String'];
};

export type PathwayContext = {
  __typename?: 'PathwayContext';
  action_id?: Maybe<Scalars['String']>;
  instance_id: Scalars['String'];
  pathway_id: Scalars['String'];
  step_id?: Maybe<Scalars['String']>;
  track_id?: Maybe<Scalars['String']>;
};

/** Diff between two care flows. Used to help understand how care flows have changed over time. */
export type PathwayDiff = {
  __typename?: 'PathwayDiff';
  newPathway?: Maybe<Scalars['String']>;
  oldPathway?: Maybe<Scalars['String']>;
};

export type PathwayDiffPayload = Payload & {
  __typename?: 'PathwayDiffPayload';
  code: Scalars['String'];
  diff: PathwayDiff;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type PathwayPayload = Payload & {
  __typename?: 'PathwayPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type PathwaySettings = {
  __typename?: 'PathwaySettings';
  allow_email_notifications?: Maybe<Scalars['Boolean']>;
  api_call_retry_settings?: Maybe<ApiCallRetrySettings>;
  branding?: Maybe<BrandingSettings>;
  constants?: Maybe<Array<Constant>>;
  dashboard_ids?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  reminders?: Maybe<RemindersSettings>;
  source_control?: Maybe<SourceControlSettings>;
  stakeholder_notification_language?: Maybe<Scalars['String']>;
  stakeholders?: Maybe<Array<StakeholderEmail>>;
  webhooks?: Maybe<Array<Webhook>>;
};

export type PathwaySettingsPayload = Payload & {
  __typename?: 'PathwaySettingsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type PathwayStartedActivationReference = TriggerActivationReference & {
  __typename?: 'PathwayStartedActivationReference';
  type?: Maybe<TriggerActivationReferenceType>;
};

export type PathwayStartedTriggerSettings = TriggerSettings & {
  __typename?: 'PathwayStartedTriggerSettings';
  rule?: Maybe<Rule>;
  type?: Maybe<TriggerType>;
};

export type PathwayValidationStatus = {
  __typename?: 'PathwayValidationStatus';
  components: Array<PathwayValidationStatusComponent>;
  status: PathwayValidationStatusType;
};

export type PathwayValidationStatusComponent = {
  __typename?: 'PathwayValidationStatusComponent';
  id: Scalars['String'];
  link?: Maybe<PathwayComponentLink>;
  reason?: Maybe<InvalidStatusReason>;
  status: PathwayValidationStatusType;
  type: PathwayValidationStatusComponentType;
};

export enum PathwayValidationStatusComponentType {
  Cycle = 'CYCLE',
  DefinitionNavigationGraph = 'DEFINITION_NAVIGATION_GRAPH',
  Rule = 'RULE',
  Transition = 'TRANSITION',
  Trigger = 'TRIGGER'
}

export type PathwayValidationStatusPayload = Payload & {
  __typename?: 'PathwayValidationStatusPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  status: PathwayValidationStatus;
  success: Scalars['Boolean'];
};

export enum PathwayValidationStatusReasonType {
  CycleDetected = 'CYCLE_DETECTED',
  Duplicate = 'DUPLICATE',
  IncompleteCondition = 'INCOMPLETE_CONDITION',
  IncompleteTiming = 'INCOMPLETE_TIMING',
  InvalidTrigger = 'INVALID_TRIGGER',
  MissingActions = 'MISSING_ACTIONS',
  MissingDestination = 'MISSING_DESTINATION',
  MissingPathwayStartedTrigger = 'MISSING_PATHWAY_STARTED_TRIGGER',
  MissingSteps = 'MISSING_STEPS',
  MissingTrigger = 'MISSING_TRIGGER',
  NotFound = 'NOT_FOUND',
  Unknown = 'UNKNOWN'
}

export enum PathwayValidationStatusType {
  Error = 'ERROR',
  Valid = 'VALID',
  Warning = 'WARNING'
}

/**
 * Pathway Version stores information related to publishing.
 * It contains version/publish information of all components (Pathway/Step/Transition)
 * in a pathway.
 */
export type PathwayVersion = {
  __typename?: 'PathwayVersion';
  checkpoint_id?: Maybe<Scalars['String']>;
  comments?: Maybe<FormattedText>;
  commit_link?: Maybe<Scalars['String']>;
  created_at: Scalars['SafeDate'];
  created_by: PathwayCollaborator;
  id: Scalars['ID'];
  version: Scalars['Float'];
  version_status: VersionStatus;
};

export type PathwayVersionPayload = Payload & {
  __typename?: 'PathwayVersionPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  version: PathwayVersion;
};

export type PathwayVersionsPayload = Payload & {
  __typename?: 'PathwayVersionsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  versions: Array<PathwayVersion>;
};

export type PathwaysPayload = Payload & {
  __typename?: 'PathwaysPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathways: Array<Pathway>;
  success: Scalars['Boolean'];
};

export type Payload = {
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type Permissions = {
  __typename?: 'Permissions';
  write: Scalars['Boolean'];
};

export type PhoneConfig = {
  __typename?: 'PhoneConfig';
  available_countries?: Maybe<Array<Scalars['String']>>;
  default_country?: Maybe<Scalars['String']>;
};

export type PhoneConfigInput = {
  /** The available country codes for the phone number. */
  available_countries?: Maybe<Array<Scalars['String']>>;
  /** The default country code for the phone number. */
  default_country?: Maybe<Scalars['String']>;
};

export type PhoneQuestionConfigInput = {
  /** The phone-specific configuration for the question. */
  phone: PhoneConfigInput;
};

export type PluginActionSettingsProperty = {
  __typename?: 'PluginActionSettingsProperty';
  key: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type PreviewClinicalNote = {
  __typename?: 'PreviewClinicalNote';
  context: Array<GeneratedClinicalNoteContextField>;
  id: Scalars['ID'];
  narratives: Array<GeneratedClinicalNoteNarrative>;
};

export type PreviewEmrReport = {
  __typename?: 'PreviewEmrReport';
  id: Scalars['ID'];
  message_html: Scalars['String'];
  metadata?: Maybe<Array<EmrReportMetadataField>>;
};

export type PreviewMessage = {
  __typename?: 'PreviewMessage';
  attachments?: Maybe<Array<MessageAttachment>>;
  body: Scalars['String'];
  format: MessageFormat;
  id: Scalars['ID'];
  subject?: Maybe<Scalars['String']>;
};

export type PublishPathwayInput = {
  comments?: Maybe<FormattedTextInput>;
  pathway_id: Scalars['String'];
};

export type PublishPathwayPayload = Payload & {
  __typename?: 'PublishPathwayPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  published_version: PathwayVersion;
  success: Scalars['Boolean'];
};

export enum PublishingStatus {
  HasUnpublishedChanges = 'HAS_UNPUBLISHED_CHANGES',
  NoUnpublishedChanges = 'NO_UNPUBLISHED_CHANGES'
}

export type PushToEmrAction = Action & {
  __typename?: 'PushToEmrAction';
  clinical_note_id?: Maybe<Scalars['ID']>;
  created: AuditTrail;
  custom_fields?: Maybe<Array<CustomActionField>>;
  due_date?: Maybe<DueDate>;
  emr_report?: Maybe<EmrReport>;
  emr_report_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  idle_time?: Maybe<Delay>;
  last_updated?: Maybe<AuditTrail>;
  locations?: Maybe<Array<Location>>;
  message_id?: Maybe<Scalars['ID']>;
  plugin_key?: Maybe<Scalars['String']>;
  stakeholders: Array<Stakeholder>;
  status: ConfigurationStatus;
  step: Step;
  title?: Maybe<Scalars['String']>;
  type: ActionType;
};

export type Query = {
  __typename?: 'Query';
  action: ActionPayload;
  adHocTracks: TracksPayload;
  ahp_link: AhpLinkPayload;
  ahp_links: AhpLinkPayloads;
  baseline_info: BaselineInfoPayload;
  calculation_results: CalculationResultPayload;
  calculations: CalculationsPayload;
  careflowCheckpoints?: Maybe<CheckpointsPayload>;
  careflow_components: CareflowComponentsPayload;
  clinical_note: ClinicalNotePayload;
  constant: ConstantPayload;
  constants: ConstantsPayload;
  data_catalog: DataCatalogPayload;
  /** Export data point definitions in JSON format */
  data_catalog_json: DataCatalogJsonPayload;
  data_point_definition: DataPointDefinitionPayload;
  data_point_definitions: DataPointDefinitionsPayload;
  decision: DecisionPayload;
  decision_outputs: DecisionOutputsPayload;
  decisions: DecisionsPayload;
  dynamic_variable: DynamicVariablePayload;
  dynamic_variables: DynamicVariablesPayload;
  emr_report: EmrReportPayload;
  enrollmentTriggers: EnrollmentTriggerPayload;
  evaluatedRule: EvaluatedRulePayload;
  events: DomainEventsPayload;
  extensionActivityRecord: ExtensionActivityRecordPayload;
  extension_configurations: ExtensionConfigurationsPayload;
  extensions: ExtensionsPayload;
  fileStorageConfigs: FileStorageConfigPayload;
  form: FormPayload;
  form_data_point_definitions: DataPointDefinitionsPayload;
  form_response: FormResponsePayload;
  forms: FormsPayload;
  generated_clinical_note: GeneratedClinicalNotePayload;
  generated_emr_report: GeneratedEmrReportPayload;
  generated_message: GeneratedMessagePayload;
  /** Get a specific node from the ontology */
  getOntologyNode: OntologyNodePayload;
  isPathwayReadyForPreview: PathwayValidationStatusPayload;
  is_slug_unique: IsSlugUniquePayload;
  jsonPathFromMockObject: JsonPathPayload;
  jsonSchema: SchemaPayload;
  listForms: FormsPayload;
  listPathways: PathwaysPayload;
  locations: LocationsPayload;
  message: MessagePayload;
  pathway: PathwayPayload;
  pathwayCaseActivities: ActivitiesPayload;
  pathwayCaseApiCall: PathwayCaseApiCallPayload;
  pathwayCaseApiCalls: PathwayCaseApiCallsPayload;
  pathwayCaseElements: ElementsPayload;
  pathwayCaseStepActivities: ActivitiesPayload;
  pathwayCaseTriggerActivation: TriggerActivationPayload;
  pathwayCaseTriggerActivations: TriggerActivationsPayload;
  pathwayCaseWebhookCall: PathwayCaseWebhookCallPayload;
  pathwayCaseWebhookCalls: PathwayCaseWebhookCallsPayload;
  pathwayDiffTree: PathwayDiffPayload;
  pathwayDiffs: PathwayDiffPayload;
  pathwayExtensions: ExtensionsPayload;
  pathwaySettings: PathwaySettingsPayload;
  pathwaySteps: StepsPayload;
  pathway_case: PathwayCasePayload;
  pathway_cases: PathwayCasesPayload;
  pathway_version: PathwayVersionPayload;
  pathway_versions: PathwayVersionsPayload;
  pathways: PathwaysPayload;
  rule: RulePayload;
  /** Searches the ontology */
  searchOntology: OntologyPayload;
  stakeholders: StakeholdersPayload;
  step: StepPayload;
  stepLibrary: StepLibraryPayload;
  stepLibraryForPathway: StepLibraryPayload;
  steps: StepsPayload;
  stickyNote: StickyNotePayload;
  stickyNotes: StickyNotesPayload;
  tenant: TenantPayload;
  tenants: TenantsPayload;
  timer: TimerPayload;
  timers: TimersPayload;
  timing: TimingPayload;
  track: TrackPayload;
  transition: TransitionPayload;
  transitions: TransitionsPayload;
  user: UserPayload;
  webhook: WebhookPayload;
  webhookEndpoints: WebhookEndpointsPayload;
};


export type QueryActionArgs = {
  id: Scalars['String'];
};


export type QueryAdHocTracksArgs = {
  pathway_definition_id: Scalars['String'];
};


export type QueryAhp_LinkArgs = {
  id: Scalars['String'];
};


export type QueryAhp_LinksArgs = {
  careflow_id: Scalars['String'];
};


export type QueryBaseline_InfoArgs = {
  pathway_case_id: Scalars['String'];
};


export type QueryCalculation_ResultsArgs = {
  input: CalculationResultInput;
};


export type QueryCareflowCheckpointsArgs = {
  careflow_id: Scalars['String'];
};


export type QueryCareflow_ComponentsArgs = {
  id: Scalars['String'];
};


export type QueryClinical_NoteArgs = {
  id: Scalars['String'];
};


export type QueryConstantArgs = {
  input: ConstantInput;
};


export type QueryConstantsArgs = {
  pathway_id: Scalars['String'];
};


export type QueryData_CatalogArgs = {
  input: DataCatalogInput;
};


export type QueryData_Catalog_JsonArgs = {
  input: DataCatalogInput;
};


export type QueryData_Point_DefinitionArgs = {
  id: Scalars['String'];
};


export type QueryData_Point_DefinitionsArgs = {
  input: DataPointDefinitionsInput;
};


export type QueryDecisionArgs = {
  id: Scalars['ID'];
};


export type QueryDecision_OutputsArgs = {
  input: DecisionOutputsInput;
};


export type QueryDecisionsArgs = {
  track_id: Scalars['String'];
};


export type QueryDynamic_VariableArgs = {
  id: Scalars['String'];
};


export type QueryDynamic_VariablesArgs = {
  pathway_id: Scalars['String'];
};


export type QueryEmr_ReportArgs = {
  id: Scalars['String'];
};


export type QueryEnrollmentTriggersArgs = {
  careflow_id: Scalars['String'];
};


export type QueryEvaluatedRuleArgs = {
  id: Scalars['String'];
};


export type QueryEventsArgs = {
  input: EventsInput;
};


export type QueryExtensionActivityRecordArgs = {
  id: Scalars['String'];
};


export type QueryExtension_ConfigurationsArgs = {
  pathway_id: Scalars['String'];
};


export type QueryFormArgs = {
  id: Scalars['String'];
};


export type QueryForm_Data_Point_DefinitionsArgs = {
  input: FormDataPointDefinitionsInput;
};


export type QueryForm_ResponseArgs = {
  input: FormResponseInput;
};


export type QueryGenerated_Clinical_NoteArgs = {
  id: Scalars['String'];
};


export type QueryGenerated_Emr_ReportArgs = {
  id: Scalars['String'];
};


export type QueryGenerated_MessageArgs = {
  id: Scalars['String'];
};


export type QueryGetOntologyNodeArgs = {
  id: Scalars['String'];
};


export type QueryIsPathwayReadyForPreviewArgs = {
  pathway_definition_id: Scalars['String'];
};


export type QueryIs_Slug_UniqueArgs = {
  id?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};


export type QueryJsonPathFromMockObjectArgs = {
  jsonPath: Scalars['String'];
  mockObject: Scalars['String'];
};


export type QueryJsonSchemaArgs = {
  source: JsonSchemaSourceGraphqlTypeInput;
};


export type QueryLocationsArgs = {
  pathway_id: Scalars['String'];
};


export type QueryMessageArgs = {
  id: Scalars['String'];
};


export type QueryPathwayArgs = {
  id: Scalars['String'];
};


export type QueryPathwayCaseActivitiesArgs = {
  pagination?: Maybe<PaginationParams>;
  pathway_case_id: Scalars['String'];
  sorting?: Maybe<SortingParams>;
};


export type QueryPathwayCaseApiCallArgs = {
  id: Scalars['String'];
};


export type QueryPathwayCaseApiCallsArgs = {
  pathway_case_id: Scalars['String'];
};


export type QueryPathwayCaseElementsArgs = {
  pathway_case_id: Scalars['String'];
};


export type QueryPathwayCaseStepActivitiesArgs = {
  input: PathwayCaseStepActivitiesInput;
};


export type QueryPathwayCaseTriggerActivationArgs = {
  id: Scalars['String'];
};


export type QueryPathwayCaseTriggerActivationsArgs = {
  pathway_case_id: Scalars['String'];
};


export type QueryPathwayCaseWebhookCallArgs = {
  webhook_call_id: Scalars['String'];
};


export type QueryPathwayCaseWebhookCallsArgs = {
  pathway_case_id: Scalars['String'];
};


export type QueryPathwayDiffTreeArgs = {
  pathway_id: Scalars['String'];
};


export type QueryPathwayDiffsArgs = {
  pathway_id: Scalars['String'];
};


export type QueryPathwayExtensionsArgs = {
  pathway_id: Scalars['String'];
};


export type QueryPathwaySettingsArgs = {
  input: Scalars['String'];
};


export type QueryPathwayStepsArgs = {
  pathway_id: Scalars['String'];
};


export type QueryPathway_CaseArgs = {
  id: Scalars['String'];
  pagination?: Maybe<PaginationParams>;
  sorting?: Maybe<SortingParams>;
};


export type QueryPathway_CasesArgs = {
  input: PathwayCasesInput;
};


export type QueryPathway_VersionArgs = {
  id: Scalars['String'];
};


export type QueryPathway_VersionsArgs = {
  pathway_id: Scalars['String'];
};


export type QueryRuleArgs = {
  id: Scalars['String'];
};


export type QuerySearchOntologyArgs = {
  args: SearchOntologyArgs;
};


export type QueryStepArgs = {
  id: Scalars['String'];
};


export type QueryStepLibraryForPathwayArgs = {
  pathway_id: Scalars['String'];
};


export type QueryStepsArgs = {
  track_id: Scalars['String'];
};


export type QueryStickyNoteArgs = {
  id: Scalars['String'];
};


export type QueryStickyNotesArgs = {
  input: StickyNotesInput;
};


export type QueryTimerArgs = {
  id: Scalars['ID'];
};


export type QueryTimersArgs = {
  track_id: Scalars['String'];
};


export type QueryTimingArgs = {
  id: Scalars['String'];
};


export type QueryTrackArgs = {
  id: Scalars['String'];
};


export type QueryTransitionArgs = {
  id: Scalars['String'];
};


export type QueryTransitionsArgs = {
  input: TransitionsInput;
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['String']>;
};


export type QueryWebhookArgs = {
  input: WebhookInput;
};

export type Question = {
  __typename?: 'Question';
  dataPointValueType?: Maybe<DataPointValueType>;
  form_id?: Maybe<Scalars['ID']>;
  id: Scalars['ID'];
  key?: Maybe<Scalars['String']>;
  metadata?: Maybe<FormattedJson>;
  options?: Maybe<Array<Option>>;
  questionConfig?: Maybe<QuestionConfig>;
  questionType?: Maybe<QuestionType>;
  rule_id?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  userQuestionType?: Maybe<UserQuestionType>;
};

export type QuestionConfig = {
  __typename?: 'QuestionConfig';
  date?: Maybe<DateConfig>;
  file_storage?: Maybe<FileStorageQuestionConfig>;
  mandatory: Scalars['Boolean'];
  multiple_select?: Maybe<MultipleSelectConfig>;
  number?: Maybe<NumberConfig>;
  phone?: Maybe<PhoneConfig>;
  recode_enabled?: Maybe<Scalars['Boolean']>;
  slider?: Maybe<SliderConfig>;
  use_select?: Maybe<Scalars['Boolean']>;
};

export type QuestionConfigInput = {
  /** Enable this flag to make the question mandatory. */
  mandatory?: Maybe<Scalars['Boolean']>;
  /** Enable this flag to use a select input when this questionnaire is rendered in a hosted sesion or in Care. */
  use_select?: Maybe<Scalars['Boolean']>;
};

export type QuestionInput = {
  /** The key of the question. */
  key: Scalars['String'];
  /** The value type of the question options. Only applicable for multiple choice and multiple select questions. */
  option_value_type?: Maybe<Scalars['String']>;
  /** The options for the question. Only applicable for multiple choice and multiple select questions. */
  options?: Maybe<Array<OptionInput>>;
  /** The title of the question. */
  title: Scalars['String'];
  /** The type of the question. */
  user_question_type: UserQuestionType;
};

export type QuestionResponseInput = {
  question_id: Scalars['String'];
  value: Scalars['String'];
};

export type QuestionRuleResult = {
  __typename?: 'QuestionRuleResult';
  question_id: Scalars['String'];
  rule_id: Scalars['String'];
  satisfied: Scalars['Boolean'];
};

export enum QuestionType {
  Input = 'INPUT',
  MultipleChoice = 'MULTIPLE_CHOICE',
  NoInput = 'NO_INPUT'
}

export type Range = {
  __typename?: 'Range';
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type RangeConfig = {
  __typename?: 'RangeConfig';
  enabled?: Maybe<Scalars['Boolean']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type RangeInput = {
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
};

export type ReadMessageInput = {
  activity_id: Scalars['String'];
  pathway_case_id: Scalars['String'];
  subject: SubjectInput;
};

export type ReadMessagePayload = Payload & {
  __typename?: 'ReadMessagePayload';
  activity: Activity;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type RebuildGraphStatus = {
  __typename?: 'RebuildGraphStatus';
  event_count: Scalars['Float'];
  pathway_definition_id: Scalars['String'];
  replayed_count: Scalars['Float'];
};

export type RebuildInput = {
  pathway_id?: Maybe<Scalars['String']>;
};

export type RebuildViewModelError = {
  __typename?: 'RebuildViewModelError';
  error: Error;
  event: Scalars['String'];
  id: Scalars['String'];
  projection_name: Scalars['String'];
};

export type RebuildViewModelStatus = {
  __typename?: 'RebuildViewModelStatus';
  event_count: Scalars['Float'];
  event_id: Scalars['String'];
  replayed_count: Scalars['Float'];
};

export type RebuildViewModelsPayload = Payload & {
  __typename?: 'RebuildViewModelsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  event_count: Scalars['Float'];
  success: Scalars['Boolean'];
};

export type RefreshExtensionInput = {
  extension_key: Scalars['String'];
  pathway_id: Scalars['ID'];
};

export type RefreshExtensionPayload = Payload & {
  __typename?: 'RefreshExtensionPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  not_supported_actions?: Maybe<Array<NotSupportedExtensionAction>>;
  success: Scalars['Boolean'];
};

export type ReminderDelayInput = {
  amount: Scalars['Float'];
  unit: DelayUnitType;
};

export type RemindersSettings = {
  __typename?: 'RemindersSettings';
  amount: Scalars['Float'];
  delay: Delay;
};

export type RemoveActionFromStepInput = {
  action_id: Scalars['String'];
  step_id: Scalars['String'];
};

export type RemoveActionFromStepPayload = Payload & {
  __typename?: 'RemoveActionFromStepPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export type RemoveClinicalNoteNarrativeInput = {
  clinical_note_id: Scalars['String'];
  narrative_id: Scalars['String'];
};

export type RemoveClinicalNoteNarrativePayload = Payload & {
  __typename?: 'RemoveClinicalNoteNarrativePayload';
  clinical_note: ClinicalNote;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type RemoveConditionFromRuleInput = {
  condition_id: Scalars['String'];
  rule_id: Scalars['String'];
};

export type RemoveConditionFromRulePayload = Payload & {
  __typename?: 'RemoveConditionFromRulePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  rule: Rule;
  success: Scalars['Boolean'];
};

export type RemoveConstantInput = {
  constant_id: Scalars['String'];
  pathway_id: Scalars['String'];
};

export type RemoveConstantPayload = Payload & {
  __typename?: 'RemoveConstantPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type RemoveDataPointDefinitionFromPathwayInput = {
  data_point_definition_id: Scalars['String'];
  pathway_id: Scalars['String'];
};

export type RemoveDataPointMappingFromExtensionActionInput = {
  action_id: Scalars['String'];
  mapping_id: Scalars['String'];
};

export type RemoveDataPointMappingFromExtensionActionPayload = Payload & {
  __typename?: 'RemoveDataPointMappingFromExtensionActionPayload';
  action: ExtensionAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type RemoveDynamicVariableInput = {
  dynamic_variable_id: Scalars['String'];
};

export type RemoveExtensionConfigurationInput = {
  extension_key: Scalars['String'];
  pathway_id: Scalars['String'];
};

export type RemoveLabelsFromStepInput = {
  step_id: Scalars['String'];
};

export type RemoveLabelsFromStepPayload = Payload & {
  __typename?: 'RemoveLabelsFromStepPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export type RemoveMappingFromApiCallInput = {
  api_call_id: Scalars['String'];
  mapping_id: Scalars['String'];
};

export type RemoveMappingFromApiCallPayload = Payload & {
  __typename?: 'RemoveMappingFromApiCallPayload';
  api_call: ApiCall;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type RemoveMessageAttachmentInput = {
  attachment_id: Scalars['String'];
  message_id: Scalars['String'];
};

export type RemoveQuestionFromFormInput = {
  /** The ID of the form to remove the question from. */
  form_id: Scalars['String'];
  /** The ID of the question to remove from the form. */
  question_id: Scalars['String'];
};

export type RemoveQuestionFromFormPayload = Payload & {
  __typename?: 'RemoveQuestionFromFormPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  form: Form;
  success: Scalars['Boolean'];
};

export type RemoveRuleFromQuestionInput = {
  form_id: Scalars['String'];
  question_id: Scalars['String'];
};

export type RemoveRuleFromQuestionPayload = Payload & {
  __typename?: 'RemoveRuleFromQuestionPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  success: Scalars['Boolean'];
};

export type RemoveStepFromLibraryInput = {
  step_id: Scalars['String'];
};

export type RemoveStepFromTrackInput = {
  step_id: Scalars['String'];
};

export type RemoveStepFromTrackPayload = Payload & {
  __typename?: 'RemoveStepFromTrackPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  track: Track;
};

export type RemoveStickyNoteFromTrackInput = {
  sticky_note_id: Scalars['String'];
};

export type RemoveTrackFromPathwayInput = {
  pathway_id: Scalars['String'];
  track_id: Scalars['String'];
};

export type RemoveTrackFromPathwayPayload = Payload & {
  __typename?: 'RemoveTrackFromPathwayPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type RemoveTransformationsFromDynamicVariableInput = {
  dynamic_variable_id: Scalars['String'];
  transformation_ids: Array<Scalars['String']>;
};

export type RemoveTransformationsFromDynamicVariablePayload = Payload & {
  __typename?: 'RemoveTransformationsFromDynamicVariablePayload';
  code: Scalars['String'];
  dynamic_variable: DynamicVariable;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type RemoveTransitionInput = {
  track_id: Scalars['String'];
  transition_id: Scalars['String'];
};

export type RemoveTransitionPayload = Payload & {
  __typename?: 'RemoveTransitionPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type RemoveTriggerFromPathwayInput = {
  trigger_id: Scalars['String'];
};

export type RemoveTriggerFromTrackInput = {
  trigger_id: Scalars['String'];
};

export type RemoveTriggerTimerInput = {
  timer_id: Scalars['String'];
  trigger_id: Scalars['String'];
};

export type RemoveWebhookInput = {
  pathway_id: Scalars['String'];
  webhook_id: Scalars['String'];
};

export type RemoveWebhookPayload = Payload & {
  __typename?: 'RemoveWebhookPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type ReorderActionsInput = {
  action_ids: Array<Scalars['String']>;
  step_id: Scalars['String'];
};

export type ReorderActionsPayload = Payload & {
  __typename?: 'ReorderActionsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export type ReorderMessageAttachmentsInput = {
  attachments: Array<MessageAttachmentInput>;
  message_id: Scalars['String'];
};

export type ReorderQuestionsInput = {
  /** The ID of the form to reorder the questions in. */
  form_id: Scalars['String'];
  /** The ID of the question that should be before the reordered question. */
  previous_question_id?: Maybe<Scalars['String']>;
  /** The ID of the question to reorder. */
  question_id: Scalars['String'];
};

export type ReorderQuestionsPayload = Payload & {
  __typename?: 'ReorderQuestionsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  form: Form;
  success: Scalars['Boolean'];
};

export type RepairPathwaysInput = {
  pathway_id?: Maybe<Scalars['String']>;
};

export type RepairPathwaysPayload = Payload & {
  __typename?: 'RepairPathwaysPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway_count: Scalars['Float'];
  success: Scalars['Boolean'];
};

export type RepairPathwaysStatus = {
  __typename?: 'RepairPathwaysStatus';
  pathway_count: Scalars['Float'];
  repaired_count: Scalars['Float'];
};

export type ResetPatientProfileDataPointDefinitionsInput = {
  pathway_id?: Maybe<Scalars['String']>;
};

export type ResetPreviewInput = {
  pathway_case_id: Scalars['String'];
};

export type ResetPreviewPayload = Payload & {
  __typename?: 'ResetPreviewPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway_case: PathwayCase;
  success: Scalars['Boolean'];
};

export type RestoreCheckpointInput = {
  checkpoint_id: Scalars['String'];
};

export type RetryAllFailedWebhookCallsInput = {
  pathway_id: Scalars['String'];
};

export type RetryAllWebhookCallsInput = {
  pathway_id: Scalars['String'];
};

export type RetryApiCallInput = {
  api_call_id: Scalars['String'];
};

export type RetryWebhookCallInput = {
  webhook_call_id: Scalars['String'];
};

export type RetryWebhookCallPayload = Payload & {
  __typename?: 'RetryWebhookCallPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  webhook_call: WebhookCall;
};

export type RoundToParameters = {
  __typename?: 'RoundToParameters';
  nbr_decimals?: Maybe<Scalars['Float']>;
};

export type RoundToTransformation = Transformation & {
  __typename?: 'RoundToTransformation';
  id: Scalars['ID'];
  parameters?: Maybe<RoundToParameters>;
  type: TransformationType;
};

/**
 * A rule is mathematical expression that determines will the transition be executed.
 * A rule contains a list of conditions and a boolean operator(AND or OR).
 * Rule will be satisfied if any of the following:
 *  - when there are no conditions
 *  - when boolean operator AND is used and all conditions are satisfied
 *  - when boolean operator OR is used and at least 1 condition is satisfied
 */
export type Rule = {
  __typename?: 'Rule';
  boolean_operator: BooleanOperator;
  conditions: Array<Condition>;
  id: Scalars['ID'];
  status: ConfigurationStatus;
  type?: Maybe<RuleType>;
};

export type RulePayload = Payload & {
  __typename?: 'RulePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  rule: Rule;
  success: Scalars['Boolean'];
};

export enum RuleType {
  DataPointBased = 'DATA_POINT_BASED',
  OntologyBased = 'ONTOLOGY_BASED'
}


export type SaveCheckpointInput = {
  careflow_id: Scalars['String'];
  description: FormattedTextInput;
};

export type SaveMessageInput = {
  body?: Maybe<Scalars['String']>;
  message_id: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
};

export type SchemaPayload = Payload & {
  __typename?: 'SchemaPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  schema: Scalars['String'];
  success: Scalars['Boolean'];
};

export type SearchOntologyArgs = {
  filters?: Maybe<Array<FilterSearch>>;
  resource_type?: Maybe<OntologyResourceType>;
  text?: Maybe<Scalars['String']>;
};

export type SetActionCalculationIdInput = {
  action_id: Scalars['String'];
  calculation_id: Scalars['String'];
};

export type SetActionCalculationIdPayload = Payload & {
  __typename?: 'SetActionCalculationIdPayload';
  action: CalculationAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetActionCalculationInputPayload = Payload & {
  __typename?: 'SetActionCalculationInputPayload';
  action: CalculationAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetActionChecklistItemsInput = {
  action_id: Scalars['String'];
  checklist: Array<Scalars['String']>;
};

export type SetActionChecklistItemsPayload = Payload & {
  __typename?: 'SetActionChecklistItemsPayload';
  action: ChecklistAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetActionDueDatePayload = Payload & {
  __typename?: 'SetActionDueDatePayload';
  action: Action;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetActionFormDisplayModeInput = {
  action_id: Scalars['String'];
  form_display_mode: Scalars['String'];
};

export type SetActionFormDisplayModePayload = Payload & {
  __typename?: 'SetActionFormDisplayModePayload';
  action: FormAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetActionFormIdInput = {
  action_id: Scalars['String'];
  form_id: Scalars['String'];
};

export type SetActionFormIdPayload = Payload & {
  __typename?: 'SetActionFormIdPayload';
  action: FormAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetActionIdleTimeInput = {
  action_id: Scalars['String'];
  idle_time?: Maybe<DelayInput>;
};

export type SetActionIdleTimePayload = Payload & {
  __typename?: 'SetActionIdleTimePayload';
  action: Action;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetActionMessageIdInput = {
  action_id: Scalars['String'];
  message_id: Scalars['String'];
};

export type SetActionMessageIdPayload = Payload & {
  __typename?: 'SetActionMessageIdPayload';
  action: MessageAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetActionStakeholdersInput = {
  action_id: Scalars['String'];
  stakeholder_ids: Array<Scalars['String']>;
};

export type SetActionStakeholdersPayload = Payload & {
  __typename?: 'SetActionStakeholdersPayload';
  action: Action;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetActionTitleInput = {
  action_id: Scalars['String'];
  title: Scalars['String'];
};

export type SetActionTitlePayload = Payload & {
  __typename?: 'SetActionTitlePayload';
  action: Action;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetApiCallBodyInput = {
  api_call_id: Scalars['String'];
  body: ApiCallBodyInput;
};

export type SetApiCallBodyPayload = Payload & {
  __typename?: 'SetApiCallBodyPayload';
  api_call: ApiCall;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetApiCallEndpointInput = {
  api_call_id: Scalars['String'];
  endpoint: Scalars['String'];
};

export type SetApiCallEndpointPayload = Payload & {
  __typename?: 'SetApiCallEndpointPayload';
  api_call: ApiCall;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetApiCallHeadersInput = {
  api_call_id: Scalars['String'];
  headers: Array<ApiCallHeaderInput>;
};

export type SetApiCallHeadersPayload = Payload & {
  __typename?: 'SetApiCallHeadersPayload';
  api_call: ApiCall;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetApiCallMethodInput = {
  api_call_id: Scalars['String'];
  method: Scalars['String'];
};

export type SetApiCallMethodPayload = Payload & {
  __typename?: 'SetApiCallMethodPayload';
  api_call: ApiCall;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetBrandingAccentColorInput = {
  accent_color: Scalars['String'];
  pathway_id: Scalars['String'];
};

export type SetBrandingAccentColorPayload = Payload & {
  __typename?: 'SetBrandingAccentColorPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type SetBrandingCustomThemeInput = {
  custom_theme: FormattedJsonInput;
  pathway_id: Scalars['String'];
};

export type SetBrandingCustomThemePayload = Payload & {
  __typename?: 'SetBrandingCustomThemePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type SetBrandingHostedPageAutoProgressInput = {
  hosted_page_auto_progress: Scalars['Boolean'];
  pathway_id: Scalars['String'];
};

export type SetBrandingHostedPageAutoProgressPayload = Payload & {
  __typename?: 'SetBrandingHostedPageAutoProgressPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type SetBrandingHostedPageAutosaveInput = {
  hosted_page_autosave: Scalars['Boolean'];
  pathway_id: Scalars['String'];
};

export type SetBrandingHostedPageAutosavePayload = Payload & {
  __typename?: 'SetBrandingHostedPageAutosavePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type SetBrandingHostedPageTitleInput = {
  hosted_page_title: Scalars['String'];
  pathway_id: Scalars['String'];
};

export type SetBrandingHostedPageTitlePayload = Payload & {
  __typename?: 'SetBrandingHostedPageTitlePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type SetBrandingLogoUrlInput = {
  logo_url: Scalars['String'];
  pathway_id: Scalars['String'];
};

export type SetBrandingLogoUrlPayload = Payload & {
  __typename?: 'SetBrandingLogoUrlPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type SetCalculationDataPointInput = {
  action_id: Scalars['String'];
  calculation_input_id: Scalars['String'];
  data_point_definition_id: Scalars['String'];
};

export type SetClinicalNoteNarrativeTitleInput = {
  clinical_note_id: Scalars['String'];
  narrative_id: Scalars['String'];
  title: Scalars['String'];
};

export type SetConditionOperandInput = {
  condition_id: Scalars['String'];
  operand: OperandInput;
  rule_id: Scalars['String'];
};

export type SetConditionOperandPayload = Payload & {
  __typename?: 'SetConditionOperandPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  rule: Rule;
  success: Scalars['Boolean'];
};

export type SetConditionOperatorInput = {
  condition_id: Scalars['String'];
  operator: ConditionOperator;
  rule_id: Scalars['String'];
};

export type SetConditionOperatorPayload = Payload & {
  __typename?: 'SetConditionOperatorPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  rule: Rule;
  success: Scalars['Boolean'];
};

export type SetConditionReferenceInput = {
  condition_id: Scalars['String'];
  reference: Scalars['String'];
  rule_id: Scalars['String'];
};

export type SetConditionReferencePayload = Payload & {
  __typename?: 'SetConditionReferencePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  rule: Rule;
  success: Scalars['Boolean'];
};

export type SetCustomActionFieldInput = {
  action_id: Scalars['String'];
  custom_field: ActionCustomFieldInput;
};

export type SetCustomActionFieldPayload = Payload & {
  __typename?: 'SetCustomActionFieldPayload';
  action: Action;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetDataPointDefinitionMetaDataFieldInput = {
  data_point_definition_id: Scalars['String'];
  data_point_metadata: Array<DataPointMetaDataInputType>;
  pathway_id: Scalars['String'];
};

export type SetDataPointDefinitionMetaDataFieldPayload = Payload & {
  __typename?: 'SetDataPointDefinitionMetaDataFieldPayload';
  code: Scalars['String'];
  data_point_definition: DataPointDefinition;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetDataPointDefinitionOptionalFieldInput = {
  data_point_definition_id: Scalars['String'];
  optional: Scalars['Boolean'];
  pathway_id: Scalars['String'];
};

export type SetDataPointDefinitionOptionalFieldPayload = Payload & {
  __typename?: 'SetDataPointDefinitionOptionalFieldPayload';
  code: Scalars['String'];
  data_point_definition: DataPointDefinition;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetDataPointDefinitionPiiFieldInput = {
  data_point_definition_id: Scalars['String'];
  pathway_id: Scalars['String'];
  /** Personally identifiable information */
  pii: Scalars['Boolean'];
};

export type SetDataPointDefinitionPiiFieldPayload = Payload & {
  __typename?: 'SetDataPointDefinitionPiiFieldPayload';
  code: Scalars['String'];
  data_point_definition: DataPointDefinition;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetDateQuestionConfigInput = {
  /** The ID of the form the question to update belongs to. */
  form_id: Scalars['String'];
  /** The new configuration for the question. */
  question_config: DateQuestionConfigInput;
  /** The ID of the question to update. */
  question_id: Scalars['String'];
};

export type SetDateQuestionConfigPayload = Payload & {
  __typename?: 'SetDateQuestionConfigPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  success: Scalars['Boolean'];
};

export type SetDecisionCoordinatesInput = {
  coordinates: CoordinatesInput;
  decision_id: Scalars['String'];
};

export type SetDecisionInputsInput = {
  decision_id: Scalars['String'];
  inputs: Array<DecisionInputInput>;
};

export type SetDynamicVariableDataPointDefinitionInput = {
  data_point_definition_id: Scalars['String'];
  dynamic_variable_id: Scalars['String'];
};

export type SetDynamicVariableDataPointDefinitionPayload = Payload & {
  __typename?: 'SetDynamicVariableDataPointDefinitionPayload';
  code: Scalars['String'];
  dynamic_variable: DynamicVariable;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetDynamicVariableDataPointPropertyInput = {
  data_point_property: DataPointPropertyType;
  dynamic_variable_id: Scalars['String'];
};

export type SetDynamicVariableDataPointPropertyPayload = Payload & {
  __typename?: 'SetDynamicVariableDataPointPropertyPayload';
  code: Scalars['String'];
  dynamic_variable: DynamicVariable;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetDynamicVariableFallbackInput = {
  dynamic_variable_id: Scalars['String'];
  fallback: Scalars['String'];
};

export type SetDynamicVariableFallbackPayload = Payload & {
  __typename?: 'SetDynamicVariableFallbackPayload';
  code: Scalars['String'];
  dynamic_variable: DynamicVariable;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetDynamicVariableLabelInput = {
  dynamic_variable_id: Scalars['String'];
  label: Scalars['String'];
};

export type SetDynamicVariableLabelPayload = Payload & {
  __typename?: 'SetDynamicVariableLabelPayload';
  code: Scalars['String'];
  dynamic_variable: DynamicVariable;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetEmrReportBodyInput = {
  body: EmrBodyInput;
  emr_report_id: Scalars['String'];
};

export type SetExtensionActionDataPointMappingDataPointInput = {
  action_id: Scalars['String'];
  data_point_definition_id: Scalars['String'];
  mapping_id: Scalars['String'];
};

export type SetExtensionActionDataPointMappingDataPointPayload = Payload & {
  __typename?: 'SetExtensionActionDataPointMappingDataPointPayload';
  action: ExtensionAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetExtensionActionDataPointMappingKeyInput = {
  action_id: Scalars['String'];
  key: Scalars['String'];
  mapping_id: Scalars['String'];
};

export type SetExtensionActionDataPointMappingKeyPayload = Payload & {
  __typename?: 'SetExtensionActionDataPointMappingKeyPayload';
  action: ExtensionAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetExtensionActionDataPointMappingsInput = {
  action_id: Scalars['String'];
  mappings: Array<MappingDataPointDefinitionInput>;
};

export type SetExtensionActionDataPointMappingsPayload = Payload & {
  __typename?: 'SetExtensionActionDataPointMappingsPayload';
  action: ExtensionAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetExtensionActionFieldInput = {
  action_id: Scalars['String'];
  field: ExtensionActionFieldInput;
};

export type SetExtensionActionFieldPayload = Payload & {
  __typename?: 'SetExtensionActionFieldPayload';
  action: ExtensionAction;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetFileQuestionConfigPayload = Payload & {
  __typename?: 'SetFileQuestionConfigPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  success: Scalars['Boolean'];
};

export type SetFormKeyInput = {
  /** The ID of the form to update. */
  form_id: Scalars['String'];
  /** The new unique key for the form. */
  key: Scalars['String'];
};

export type SetFormKeyPayload = Payload & {
  __typename?: 'SetFormKeyPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  form: Form;
  success: Scalars['Boolean'];
};

export type SetFormMetadataInput = {
  form_id: Scalars['String'];
  metadata: FormattedJsonInput;
};

export type SetFormMetadataPayload = Payload & {
  __typename?: 'SetFormMetadataPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  form: Form;
  success: Scalars['Boolean'];
};

export type SetFormTitleInput = {
  /** The ID of the form to update. */
  form_id: Scalars['String'];
  /** The new title for the form. */
  title: Scalars['String'];
};

export type SetFormTitlePayload = Payload & {
  __typename?: 'SetFormTitlePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  form: Form;
  success: Scalars['Boolean'];
};

export type SetFormTrademarkInput = {
  form_id: Scalars['String'];
  trademark: Scalars['String'];
};

export type SetFormTrademarkPayload = Payload & {
  __typename?: 'SetFormTrademarkPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  form: Form;
  success: Scalars['Boolean'];
};

export type SetMessageAttachmentNameInput = {
  attachment_id: Scalars['String'];
  message_id: Scalars['String'];
  name: Scalars['String'];
};

export type SetMessageAttachmentUrlInput = {
  attachment_id: Scalars['String'];
  message_id: Scalars['String'];
  url: Scalars['String'];
};

export type SetMessageBodyInput = {
  body: MessageBodyInput;
  message_id: Scalars['String'];
};

export type SetMessageSubjectInput = {
  message_id: Scalars['String'];
  subject?: Maybe<Scalars['String']>;
};

export type SetMultipleSelectQuestionConfigInput = {
  /** The ID of the form the question to update belongs to. */
  form_id: Scalars['String'];
  /** The new configuration for the question. */
  question_config: MultipleSelectQuestionConfigInput;
  /** The ID of the question to update. */
  question_id: Scalars['String'];
};

export type SetMultipleSelectQuestionConfigPayload = Payload & {
  __typename?: 'SetMultipleSelectQuestionConfigPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  success: Scalars['Boolean'];
};

export type SetNumberQuestionConfigInput = {
  /** The ID of the form the question to update belongs to. */
  form_id: Scalars['String'];
  /** The new configuration for the question. */
  question_config: NumberQuestionConfigInput;
  /** The ID of the question to update. */
  question_id: Scalars['String'];
};

export type SetNumberQuestionConfigPayload = Payload & {
  __typename?: 'SetNumberQuestionConfigPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  success: Scalars['Boolean'];
};

export type SetPathwayCaseTitleInput = {
  pathway_case_id: Scalars['String'];
  title: Scalars['String'];
};

export type SetPathwayCaseTitlePayload = Payload & {
  __typename?: 'SetPathwayCaseTitlePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway_case: PathwayCase;
  success: Scalars['Boolean'];
};

export type SetPathwayTitleInput = {
  pathway_id: Scalars['String'];
  title: Scalars['String'];
};

export type SetPathwayTitlePayload = Payload & {
  __typename?: 'SetPathwayTitlePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type SetPhoneQuestionConfigInput = {
  /** The ID of the form the question to update belongs to. */
  form_id: Scalars['String'];
  /** The new configuration for the question. */
  question_config: PhoneQuestionConfigInput;
  /** The ID of the question to update. */
  question_id: Scalars['String'];
};

export type SetPhoneQuestionConfigPayload = Payload & {
  __typename?: 'SetPhoneQuestionConfigPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  success: Scalars['Boolean'];
};

export type SetQuestionConfigInput = {
  /** The ID of the form the question to update belongs to. */
  form_id: Scalars['String'];
  /** The new configuration for the question. */
  question_config: QuestionConfigInput;
  /** The ID of the question to update. */
  question_id: Scalars['String'];
};

export type SetQuestionConfigPayload = Payload & {
  __typename?: 'SetQuestionConfigPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  success: Scalars['Boolean'];
};

export type SetQuestionKeyInput = {
  /** The ID of the form containing the question to update. */
  form_id: Scalars['String'];
  /** The new unique key for the question. */
  key: Scalars['String'];
  /** The ID of the question to update. */
  question_id: Scalars['String'];
};

export type SetQuestionKeyPayload = Payload & {
  __typename?: 'SetQuestionKeyPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  success: Scalars['Boolean'];
};

export type SetQuestionMetadataInput = {
  form_id: Scalars['String'];
  metadata: FormattedJsonInput;
  question_id: Scalars['String'];
};

export type SetQuestionMetadataPayload = Payload & {
  __typename?: 'SetQuestionMetadataPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  success: Scalars['Boolean'];
};

export type SetQuestionOptionValueTypeInput = {
  /** The new value type for the question. Only string and strings_array are supported. */
  dataPointValueType: DataPointValueType;
  /** The ID of the form containing the question to update. */
  form_id: Scalars['String'];
  /** The ID of the question to update. */
  question_id: Scalars['String'];
};

export type SetQuestionOptionValueTypePayload = Payload & {
  __typename?: 'SetQuestionOptionValueTypePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  success: Scalars['Boolean'];
};

export type SetQuestionsConfigInput = {
  /** The ID of the form containing the questions to update. */
  form_id: Scalars['String'];
  /** The new configuration for the questions. */
  question_config: QuestionConfigInput;
  /** The IDs of the questions to update. */
  question_ids: Array<Scalars['String']>;
};

export type SetQuestionsConfigPayload = Payload & {
  __typename?: 'SetQuestionsConfigPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  questions: Array<Question>;
  success: Scalars['Boolean'];
};

export type SetRemindersAmountInput = {
  amount: Scalars['Float'];
  pathway_id: Scalars['String'];
};

export type SetRemindersAmountPayload = Payload & {
  __typename?: 'SetRemindersAmountPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type SetRemindersDelayInput = {
  delay: ReminderDelayInput;
  pathway_id: Scalars['String'];
};

export type SetRemindersDelayPayload = Payload & {
  __typename?: 'SetRemindersDelayPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type SetRuleBooleanOperatorInput = {
  boolean_operator: BooleanOperator;
  rule_id: Scalars['String'];
};

export type SetRuleBooleanOperatorPayload = Payload & {
  __typename?: 'SetRuleBooleanOperatorPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  rule: Rule;
  success: Scalars['Boolean'];
};

export type SetRuleTypeInput = {
  rule_id: Scalars['String'];
  type: Scalars['String'];
};

export type SetRuleTypePayload = Payload & {
  __typename?: 'SetRuleTypePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  rule: Rule;
  success: Scalars['Boolean'];
};

export type SetSliderQuestionConfigInput = {
  /** The ID of the form the question to update belongs to. */
  form_id: Scalars['String'];
  /** The new configuration for the question. */
  question_config: SliderQuestionConfigInput;
  /** The ID of the question to update. */
  question_id: Scalars['String'];
};

export type SetSliderQuestionConfigPayload = Payload & {
  __typename?: 'SetSliderQuestionConfigPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  success: Scalars['Boolean'];
};

export type SetStakeholderEmailsInput = {
  language: Scalars['String'];
  pathway_id: Scalars['String'];
  stakeholders: Array<StakeholderEmailInput>;
};

export type SetStakeholderEmailsPayload = Payload & {
  __typename?: 'SetStakeholderEmailsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type SetStepCoordinatesInput = {
  coordinates: CoordinatesInput;
  step_id: Scalars['String'];
};

export type SetStepCoordinatesPayload = Payload & {
  __typename?: 'SetStepCoordinatesPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export type SetStepDocumentationInput = {
  content: Scalars['String'];
  format: Scalars['String'];
  language: Language;
  step_id: Scalars['String'];
};

export type SetStepDocumentationPayload = Payload & {
  __typename?: 'SetStepDocumentationPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export type SetStepTitleInput = {
  step_id: Scalars['String'];
  title: Scalars['String'];
};

export type SetStepTitlePayload = Payload & {
  __typename?: 'SetStepTitlePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export type SetStickyNoteCoordinatesInput = {
  coordinates: CoordinatesInput;
  sticky_note_id: Scalars['String'];
};

export type SetStickyNoteCoordinatesPayload = Payload & {
  __typename?: 'SetStickyNoteCoordinatesPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  stickyNote: StickyNote;
  success: Scalars['Boolean'];
};

export type SetTimerCoordinatesInput = {
  coordinates: CoordinatesInput;
  timer_id: Scalars['String'];
};

export type SetTimingDelayInput = {
  delay: DelayInput;
  timing_id: Scalars['String'];
};

export type SetTimingDelayPayload = Payload & {
  __typename?: 'SetTimingDelayPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  timing: Timing;
};

export type SetTimingReferenceInput = {
  reference: Scalars['String'];
  timing_id: Scalars['String'];
};

export type SetTimingReferencePayload = Payload & {
  __typename?: 'SetTimingReferencePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  timing: Timing;
};

export type SetTrackEndCoordinatesInput = {
  coordinates: CoordinatesInput;
  track_id: Scalars['String'];
};

export type SetTrackEndCoordinatesPayload = Payload & {
  __typename?: 'SetTrackEndCoordinatesPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  track: Track;
};

export type SetTrackStartCoordinatesInput = {
  coordinates: CoordinatesInput;
  track_id: Scalars['String'];
};

export type SetTrackStartCoordinatesPayload = Payload & {
  __typename?: 'SetTrackStartCoordinatesPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  track: Track;
};

export type SetTrackTitleInput = {
  title: Scalars['String'];
  track_id: Scalars['String'];
};

export type SetTrackTitlePayload = Payload & {
  __typename?: 'SetTrackTitlePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  track: Track;
};

export type SetTransformationParamsInput = {
  dynamic_variable_id: Scalars['String'];
  transformation_id: Scalars['String'];
  value: Scalars['String'];
};

export type SetTransformationParamsPayload = Payload & {
  __typename?: 'SetTransformationParamsPayload';
  code: Scalars['String'];
  dynamic_variable: DynamicVariable;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetTransformationTypeInput = {
  dynamic_variable_id: Scalars['String'];
  transformation_id: Scalars['String'];
  type: TransformationType;
};

export type SetTransformationTypePayload = Payload & {
  __typename?: 'SetTransformationTypePayload';
  code: Scalars['String'];
  dynamic_variable: DynamicVariable;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SetTransitionDestinationInput = {
  destination: DestinationInput;
  track_id: Scalars['String'];
  transition_id: Scalars['String'];
};

export type SetTransitionDestinationPayload = Payload & {
  __typename?: 'SetTransitionDestinationPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  transition: Transition;
};

export type SetTriggerDataPointDefinitionInput = {
  data_point_definition_id: Scalars['String'];
  trigger_id: Scalars['String'];
};

export type SetTriggerDelayInput = {
  delay: TriggerDelayInput;
  timer_id: Scalars['String'];
  trigger_id: Scalars['String'];
};

export type SetTriggerSettingsInput = {
  event_based_settings: EventBasedTriggerSettingsInput;
  trigger_id: Scalars['String'];
};

export type SetTriggerStepInput = {
  step_id: Scalars['String'];
  trigger_id: Scalars['String'];
};

export type SetTriggerTimerDataPointDefinitionInput = {
  data_point_definition_id: Scalars['String'];
  timer_id: Scalars['String'];
  trigger_id: Scalars['String'];
};

export type SetTriggerTimerStepInput = {
  step_id: Scalars['String'];
  timer_id: Scalars['String'];
  trigger_id: Scalars['String'];
};

export type SetTriggerTimerTrackInput = {
  timer_id: Scalars['String'];
  track_id: Scalars['String'];
  trigger_id: Scalars['String'];
};

export type SetTriggerTimerTypeInput = {
  timer_id: Scalars['String'];
  trigger_id: Scalars['String'];
  type: TriggerActivationReferenceType;
};

export type SetTriggerTrackInput = {
  track_id: Scalars['String'];
  trigger_id: Scalars['String'];
};

export type SetTriggerTypeInput = {
  trigger_id: Scalars['String'];
  type: TriggerType;
};

export type ShareFormsInput = {
  form_ids: Array<Scalars['String']>;
  team_id: Scalars['String'];
};

export type SharePathwaysInput = {
  pathway_ids: Array<Scalars['String']>;
  team_id: Scalars['String'];
};

export type SliderConfig = {
  __typename?: 'SliderConfig';
  display_marks: Scalars['Boolean'];
  is_value_tooltip_on: Scalars['Boolean'];
  max: Scalars['Float'];
  max_label: Scalars['String'];
  min: Scalars['Float'];
  min_label: Scalars['String'];
  show_min_max_values: Scalars['Boolean'];
  step_value: Scalars['Float'];
};

export type SliderConfigInput = {
  /** Whether the marks should be displayed on the slider. */
  display_marks?: Maybe<Scalars['Boolean']>;
  /** Whether the value tooltip should be displayed on the slider. */
  is_value_tooltip_on?: Maybe<Scalars['Boolean']>;
  /** The maximum value the user can select. */
  max?: Maybe<Scalars['Float']>;
  /** The label for the maximum value of the slider. */
  max_label?: Maybe<Scalars['String']>;
  /** The minimum value the user can select. */
  min?: Maybe<Scalars['Float']>;
  /** The label for the minimum value of the slider. */
  min_label?: Maybe<Scalars['String']>;
  /** Whether the minimum and maximum values should be displayed on the slider. */
  show_min_max_values?: Maybe<Scalars['Boolean']>;
  /** The step value defines the distance between each tick on the slider. It is used to control the granularity of the slider. */
  step_value?: Maybe<Scalars['Float']>;
};

export type SliderQuestionConfigInput = {
  /** The new slider specific configuration for the question. */
  slider: SliderConfigInput;
};

export type SortingOutput = {
  __typename?: 'SortingOutput';
  direction: Scalars['String'];
  field: Scalars['String'];
};

export type SortingParams = {
  direction: Scalars['String'];
  field: Scalars['String'];
};

export type SourceControlInput = {
  access_token: Scalars['String'];
  branch_name: Scalars['String'];
  repo_url: Scalars['String'];
};

export type SourceControlSettings = {
  __typename?: 'SourceControlSettings';
  access_token: Scalars['String'];
  branch_name: Scalars['String'];
  repo_url: Scalars['String'];
};

/**
 * Stakeholders are humans that are in any way involved in the pathway of the patient
 * Examples of stakeholders:
 *   - caregivers
 *   - doctors
 *   - parents
 *   - friends
 *   - guardians
 *   - paramedics (e.g., revalidation coach)
 *   - ...
 */
export type Stakeholder = {
  __typename?: 'Stakeholder';
  clinical_app_role: StakeholderClinicalAppRole;
  id: Scalars['ID'];
  label: StakeholderLabel;
};

export enum StakeholderClinicalAppRole {
  Caregiver = 'CAREGIVER',
  Patient = 'PATIENT',
  Physician = 'PHYSICIAN'
}

export type StakeholderEmail = {
  __typename?: 'StakeholderEmail';
  definition_id: Scalars['String'];
  email: Scalars['String'];
};

export type StakeholderEmailInput = {
  definition_id: Scalars['String'];
  email: Scalars['String'];
};

export type StakeholderLabel = {
  __typename?: 'StakeholderLabel';
  en: Scalars['String'];
};

export enum StakeholdersMode {
  Multiple = 'multiple',
  Single = 'single',
  Toggle = 'toggle'
}

export type StakeholdersPayload = Payload & {
  __typename?: 'StakeholdersPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  stakeholders: Array<Stakeholder>;
  success: Scalars['Boolean'];
};

export type StakeholdersSelectSettings = {
  __typename?: 'StakeholdersSelectSettings';
  description?: Maybe<Scalars['String']>;
  label: Scalars['String'];
  mode: StakeholdersMode;
};

export type StartPreviewInput = {
  baseline_info?: Maybe<Array<BaselineInfoInput>>;
  pathway_case_id: Scalars['String'];
  pathway_id: Scalars['String'];
};

export type StartPreviewPayload = Payload & {
  __typename?: 'StartPreviewPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway_case: PathwayCase;
  success: Scalars['Boolean'];
};

export type StartTrackFromStepInput = {
  destination_connector_id: TransitionConnectorId;
  destination_step_id: Scalars['String'];
  origin_connector_id: TransitionConnectorId;
  track_id: Scalars['String'];
};

export type StartTrackFromStepPayload = Payload & {
  __typename?: 'StartTrackFromStepPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  transition: Transition;
};

export type StartTransitionFromStepInput = {
  origin_connector_id?: Maybe<Scalars['String']>;
  origin_step_id: Scalars['String'];
  track_id: Scalars['String'];
};

export type StartTransitionFromStepPayload = Payload & {
  __typename?: 'StartTransitionFromStepPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
  transition: Transition;
};

export enum Status {
  Invalid = 'INVALID',
  Valid = 'VALID'
}

/**
 * Step is a sequence of actions.
 * Step exist only within an track.
 * Step represents a single procedure within an track.
 *
 * Examples of steps:
 *
 * 1. Post operation patient follow-up with following actions
 *    - send an email to patient
 *    - patient fills in form
 *    - calculation is done
 *    - alert generated if calculation result is bad
 *
 * 2. Baseline intake with following actions
 *    - sending an email to a patient that he/she is included in the pathway
 *    - sending an email to the clinical team that new patient is included in the pathway
 *    - filling in baseline forms by the patient
 */
export type Step = {
  __typename?: 'Step';
  actions?: Maybe<Array<Action>>;
  coordinates: Coordinates;
  created: AuditTrail;
  description?: Maybe<Scalars['String']>;
  documentation?: Maybe<FormattedText>;
  id: Scalars['ID'];
  label?: Maybe<Label>;
  last_updated?: Maybe<AuditTrail>;
  title?: Maybe<Scalars['String']>;
  track_id?: Maybe<Scalars['ID']>;
  transitions: Array<Transition>;
  type: StepType;
};

export type StepCompletedActivationReference = TriggerActivationReference & {
  __typename?: 'StepCompletedActivationReference';
  step_id?: Maybe<Scalars['ID']>;
  track_id?: Maybe<Scalars['ID']>;
  type?: Maybe<TriggerActivationReferenceType>;
};

export type StepCompletedTriggerSettings = TriggerSettings & {
  __typename?: 'StepCompletedTriggerSettings';
  rule?: Maybe<Rule>;
  rule_id?: Maybe<Scalars['ID']>;
  step_id?: Maybe<Scalars['ID']>;
  track_id?: Maybe<Scalars['ID']>;
  type?: Maybe<TriggerType>;
};

export type StepLibraryPayload = Payload & {
  __typename?: 'StepLibraryPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  steps: Array<Step>;
  success: Scalars['Boolean'];
};

export type StepPayload = Payload & {
  __typename?: 'StepPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  step: Step;
  success: Scalars['Boolean'];
};

export enum StepType {
  Step = 'STEP',
  Template = 'TEMPLATE'
}

export type StepsPayload = Payload & {
  __typename?: 'StepsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  steps: Array<Step>;
  success: Scalars['Boolean'];
};

/**
 * Note represents text for remembrance when building pathway. It can be placed anywhere
 * in a track.
 * Notes exist only within a track for now.
 * Note represents a single post-it note within a track.
 */
export type StickyNote = {
  __typename?: 'StickyNote';
  body?: Maybe<StickyNoteBody>;
  coordinates: Coordinates;
  id: Scalars['ID'];
};

export type StickyNoteBody = {
  __typename?: 'StickyNoteBody';
  format: Scalars['String'];
  text?: Maybe<Scalars['String']>;
};

export type StickyNoteBodyInput = {
  format: Scalars['String'];
  text: Scalars['String'];
};

export type StickyNotePayload = Payload & {
  __typename?: 'StickyNotePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  stickyNote: StickyNote;
  success: Scalars['Boolean'];
};

export type StickyNotesInput = {
  pathway_id: Scalars['String'];
  track_id: Scalars['String'];
};

export type StickyNotesPayload = Payload & {
  __typename?: 'StickyNotesPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  stickyNotes: Array<StickyNote>;
  success: Scalars['Boolean'];
};

export type StringArrayField = ExtensionActionField & {
  __typename?: 'StringArrayField';
  description?: Maybe<Scalars['String']>;
  dropdownOptions?: Maybe<Array<ExtensionDropdownOption>>;
  id: Scalars['ID'];
  label: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  type: ExtensionActionFieldType;
  /** Value is kept as string because it can contain data point definition id in handlebars template, otherwise it is just "array of strings" */
  value?: Maybe<Scalars['String']>;
};

export type StringField = ExtensionActionField & {
  __typename?: 'StringField';
  description?: Maybe<Scalars['String']>;
  dropdownOptions?: Maybe<Array<ExtensionDropdownOption>>;
  id: Scalars['ID'];
  label: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  stringType?: Maybe<StringType>;
  type: ExtensionActionFieldType;
  value?: Maybe<Scalars['String']>;
};

export enum StringType {
  Email = 'EMAIL',
  Phone = 'PHONE',
  Text = 'TEXT',
  Url = 'URL'
}

export type SubActivity = {
  __typename?: 'SubActivity';
  action: ActivityAction;
  date: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  error_category?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  object?: Maybe<ActivityObject>;
  scheduled_date?: Maybe<Scalars['String']>;
  subject: ActivitySubject;
  text?: Maybe<TranslatedText>;
};

export type SubjectInput = {
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SubmitChecklistInput = {
  activity_id: Scalars['String'];
  pathway_case_id: Scalars['String'];
  subject: SubjectInput;
};

export type SubmitChecklistPayload = Payload & {
  __typename?: 'SubmitChecklistPayload';
  activity: Activity;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type SubmitFormResponseInput = {
  activity_id: Scalars['String'];
  pathway_case_id: Scalars['String'];
  response: Array<QuestionResponseInput>;
  subject: SubjectInput;
};

export type SubmitFormResponsePayload = Payload & {
  __typename?: 'SubmitFormResponsePayload';
  activity: Activity;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type Subscription = {
  __typename?: 'Subscription';
  activitiesDeleted: ActivitiesDeleted;
  activityCompleted: Activity;
  activityCreated: Activity;
  activityUpdated: Activity;
  apiCallCreated: PathwayCaseApiCall;
  apiCallUpdated: PathwayCaseApiCall;
  elementCompleted: Element;
  elementCreated: Element;
  elementDeleted: Element;
  elementUpdated: Element;
  eventReplayed: RebuildViewModelStatus;
  onCheckpointRestoredMessage: CheckpointMessage;
  onExportPathwayStatusUpdate: ExportPathwayStatus;
  onImportPathwayStatusUpdate: ImportPathwayStatus;
  pathwayRepaired: RepairPathwaysStatus;
  previewUpdated: PathwayCase;
  projectionError: RebuildViewModelError;
  rebuildGraphStatusUpdated: RebuildGraphStatus;
  triggerActivationCreated: TriggerActivation;
  triggerActivationUpdated: TriggerActivation;
  webhookCallCreated: PathwayCaseWebhookCall;
  webhookCallUpdated: PathwayCaseWebhookCall;
  webhookCallsDeleted: WebhookCallsDeleted;
};


export type SubscriptionActivitiesDeletedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionActivityCompletedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionActivityCreatedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionActivityUpdatedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionApiCallCreatedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionApiCallUpdatedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionElementCompletedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionElementCreatedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionElementDeletedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionElementUpdatedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionOnCheckpointRestoredMessageArgs = {
  careflow_id: Scalars['String'];
};


export type SubscriptionPreviewUpdatedArgs = {
  pathway_case_id: Scalars['ID'];
};


export type SubscriptionRebuildGraphStatusUpdatedArgs = {
  pathway_definition_id: Scalars['String'];
};


export type SubscriptionTriggerActivationCreatedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionTriggerActivationUpdatedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionWebhookCallCreatedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionWebhookCallUpdatedArgs = {
  pathway_case_id: Scalars['String'];
};


export type SubscriptionWebhookCallsDeletedArgs = {
  pathway_case_id: Scalars['String'];
};

export type SubtractDurationParameters = {
  __typename?: 'SubtractDurationParameters';
  duration?: Maybe<DurationGraphQlObjectType>;
};

export type SubtractDurationTransformation = Transformation & {
  __typename?: 'SubtractDurationTransformation';
  id: Scalars['ID'];
  parameters?: Maybe<SubtractDurationParameters>;
  type: TransformationType;
};

export type Team = {
  __typename?: 'Team';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type Tenant = {
  __typename?: 'Tenant';
  accent_color?: Maybe<Scalars['String']>;
  api_call_retry_settings?: Maybe<ApiCallRetrySettings>;
  hosted_page_title?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  is_default: Scalars['Boolean'];
  logo_path?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  public?: Maybe<Scalars['Boolean']>;
};

export type TenantPayload = Payload & {
  __typename?: 'TenantPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  tenant: Tenant;
};

export type TenantsPayload = Payload & {
  __typename?: 'TenantsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  tenants: Array<Tenant>;
};

export type TestSourceControlInput = {
  pathway_id: Scalars['String'];
};

export type TestSourceControlPayload = Payload & {
  __typename?: 'TestSourceControlPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type TextField = ExtensionActionField & {
  __typename?: 'TextField';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  label: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  slate?: Maybe<Scalars['String']>;
  type: ExtensionActionFieldType;
  value?: Maybe<Scalars['String']>;
};

export type Timer = {
  __typename?: 'Timer';
  config?: Maybe<TimerConfig>;
  coordinates?: Maybe<Coordinates>;
  id: Scalars['ID'];
  suggestions: Array<TimerConfig>;
};

export type TimerConfig = {
  description: Scalars['String'];
  type: TimerType;
};

export type TimerDataPointReferenceInput = {
  data_point_definition_id: Scalars['String'];
  data_point_key: Scalars['String'];
  description: Scalars['String'];
  hour?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Float']>;
  offsetDirection?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['String']>;
  usePatientTimezone?: Maybe<Scalars['Boolean']>;
};

export type TimerDelay = {
  __typename?: 'TimerDelay';
  unit: DelayUnitType;
  value: Scalars['Float'];
};

export type TimerDelayInput = {
  unit: DelayUnitType;
  value: Scalars['Float'];
};

export type TimerExtensionResourceUpdatedInput = {
  action_definition_id: Scalars['String'];
  action_key: Scalars['String'];
  description: Scalars['String'];
  extension_key: Scalars['String'];
  resource_id: ExtensionResourceIdInput;
  timer_key: Scalars['String'];
};

export type TimerPayload = Payload & {
  __typename?: 'TimerPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  timer: Timer;
};

/** The type of timer */
export enum TimerType {
  DataPointReference = 'DATA_POINT_REFERENCE',
  Delay = 'DELAY',
  ExtensionResourceUpdated = 'EXTENSION_RESOURCE_UPDATED'
}

export type TimersPayload = Payload & {
  __typename?: 'TimersPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  timers: Array<Timer>;
};

/**
 * Timings exist in the context of a transition.
 * It allows you to add time delays before going from one step to another.
 * Delays can be:
 *   - static
 *   - dynamic
 * Static delays are always the same across pathway instances.
 * Dynamic delays vary based on data point instances.
 */
export type Timing = {
  __typename?: 'Timing';
  delay?: Maybe<Delay>;
  id: Scalars['ID'];
  reference?: Maybe<Scalars['String']>;
  reference_key?: Maybe<Scalars['String']>;
};

export type TimingPayload = Payload & {
  __typename?: 'TimingPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  timing: Timing;
};

/**
 * A box to put steps in, just a set of steps.
 * It is a way to group steps together, to make pathway more understandable.
 * Pathways are made out of tracks. (e.g. Surgery, Hormonal therapy ...)
 * Steps in a track are not executed sequentially.
 * Order of execution of steps within an track is defined by transitions.
 *
 * You can transition for Track A to Track B by linking transition from any step in Track A to track's End node
 * and configuring that transition to go to Track B.
 */
export type Track = {
  __typename?: 'Track';
  created: AuditTrail;
  decisions: Array<Decision>;
  end_coordinates: Coordinates;
  id: Scalars['ID'];
  last_updated?: Maybe<AuditTrail>;
  start_coordinates: Coordinates;
  steps: Array<Step>;
  sticky_notes: Array<StickyNote>;
  timers: Array<Timer>;
  title: Scalars['String'];
  transitions: Array<Transition>;
  triggers: Array<Trigger>;
};

export type TrackCompletedActivationReference = TriggerActivationReference & {
  __typename?: 'TrackCompletedActivationReference';
  track_id?: Maybe<Scalars['ID']>;
  type?: Maybe<TriggerActivationReferenceType>;
};

export type TrackCompletedTriggerSettings = TriggerSettings & {
  __typename?: 'TrackCompletedTriggerSettings';
  rule?: Maybe<Rule>;
  rule_id?: Maybe<Scalars['ID']>;
  track_id?: Maybe<Scalars['ID']>;
  type?: Maybe<TriggerType>;
};

export type TrackPayload = Payload & {
  __typename?: 'TrackPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  /**
   * A box to put steps in, just a set of steps.
   * It is a way to group steps together, to make pathway more understandable.
   * Pathways are made out of tracks. (e.g. Surgery, Hormonal therapy ...)
   * Steps in a track are not executed sequentially.
   * Order of execution of steps within an track is defined by transitions.
   *
   * You can transition for Track A to Track B by linking transition from any step in Track A to track's End node
   * and configuring that transition to go to Track B.
   */
  track: Track;
};

export type TrackStartedActivationReference = TriggerActivationReference & {
  __typename?: 'TrackStartedActivationReference';
  track_id?: Maybe<Scalars['ID']>;
  type?: Maybe<TriggerActivationReferenceType>;
};

export type TracksPayload = Payload & {
  __typename?: 'TracksPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  tracks: Array<Track>;
};

export type TransferPathwayInput = {
  new_user_id: Scalars['String'];
  pathway_id: Scalars['String'];
};

export type Transformation = {
  id: Scalars['ID'];
  type: TransformationType;
};

export type TransformationInput = {
  type: TransformationType;
  value: Scalars['String'];
};

export enum TransformationType {
  AddDuration = 'ADD_DURATION',
  AddPrefix = 'ADD_PREFIX',
  AddSuffix = 'ADD_SUFFIX',
  AddWeekdays = 'ADD_WEEKDAYS',
  FormatDate = 'FORMAT_DATE',
  FormatPhoneNumber = 'FORMAT_PHONE_NUMBER',
  RoundTo = 'ROUND_TO',
  SubtractDuration = 'SUBTRACT_DURATION'
}

export type Transition = {
  __typename?: 'Transition';
  default: Scalars['Boolean'];
  destination?: Maybe<TransitionNode>;
  id: Scalars['ID'];
  origin: TransitionNode;
  rule?: Maybe<Rule>;
  status: ConfigurationStatus;
  timing?: Maybe<Timing>;
  type: TransitionType;
};

export enum TransitionConnectorId {
  Bottom = 'BOTTOM',
  Left = 'LEFT',
  Right = 'RIGHT',
  Top = 'TOP'
}

export type TransitionNode = {
  __typename?: 'TransitionNode';
  connector_id?: Maybe<Scalars['String']>;
  node_id: Scalars['ID'];
  node_title: Scalars['String'];
  type: TransitionNodeType;
};

export type TransitionNodeInput = {
  connector_id?: Maybe<Scalars['String']>;
  node_id: Scalars['String'];
  type: TransitionNodeType;
};

export enum TransitionNodeType {
  Decision = 'DECISION',
  PathwayEnd = 'PATHWAY_END',
  PathwayStart = 'PATHWAY_START',
  Step = 'STEP',
  Timer = 'TIMER',
  Track = 'TRACK',
  TrackEnd = 'TRACK_END',
  TrackStart = 'TRACK_START'
}

export type TransitionPayload = Payload & {
  __typename?: 'TransitionPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  transition: Transition;
};

export enum TransitionType {
  Legacy = 'LEGACY',
  Plain = 'PLAIN'
}

export type TransitionsInput = {
  pathway_id: Scalars['String'];
  track_id: Scalars['String'];
};

export type TransitionsPayload = Payload & {
  __typename?: 'TransitionsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  transitions: Array<Transition>;
};

export type TranslatedText = {
  __typename?: 'TranslatedText';
  en?: Maybe<Scalars['String']>;
};

export type TranslatedTextInput = {
  en?: Maybe<Scalars['String']>;
};

export type TrashPathwayInput = {
  pathway_id: Scalars['String'];
};

/**
 * A pathway can be trashed.
 * When a pathway is trashed it is not shown in the pathways list.
 * A trashed pathway is still editable (TEMPORARY: likely to change).
 * When a pathway is in the trash it can be:
 * - removed from the trash. It becomes available again
 * - permanently deleted
 * NOTE: A pathway can be permanently deleted only if it is in the trash.
 *
 * In summary the state transitions of a pathway:
 * active <-> in trash -> deleted
 */
export type Trashed = {
  __typename?: 'Trashed';
  is_trashed: Scalars['Boolean'];
  trashed_at: Scalars['SafeDate'];
  trashed_by: PathwayCollaborator;
};

export type Trigger = {
  __typename?: 'Trigger';
  id: Scalars['ID'];
  settings?: Maybe<TriggerSettings>;
  target_type?: Maybe<TriggerTargetType>;
  timers?: Maybe<Array<DesignatedTriggerTimer>>;
};

export type TriggerActivation = {
  __typename?: 'TriggerActivation';
  id: Scalars['ID'];
  status: TriggerActivationStatus;
  track: Track;
  trigger: Trigger;
  type: TriggerActivationType;
};

export type TriggerActivationPayload = Payload & {
  __typename?: 'TriggerActivationPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  trigger_activation: TriggerActivation;
};

export type TriggerActivationReference = {
  type?: Maybe<TriggerActivationReferenceType>;
};

export enum TriggerActivationReferenceType {
  AdHoc = 'AD_HOC',
  DataPointCollected = 'DATA_POINT_COLLECTED',
  PathwayStarted = 'PATHWAY_STARTED',
  StepCompleted = 'STEP_COMPLETED',
  TrackCompleted = 'TRACK_COMPLETED',
  TrackStarted = 'TRACK_STARTED'
}

export enum TriggerActivationStatus {
  Activated = 'ACTIVATED',
  Canceled = 'CANCELED',
  Discarded = 'DISCARDED',
  Pending = 'PENDING'
}

export enum TriggerActivationType {
  Automatic = 'AUTOMATIC',
  Manual = 'MANUAL'
}

export type TriggerActivationsPayload = Payload & {
  __typename?: 'TriggerActivationsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  trigger_activations: Array<TriggerActivation>;
};

export type TriggerApiCallInput = {
  activity_id: Scalars['String'];
  mock_response_body?: Maybe<Scalars['String']>;
  mock_response_status?: Maybe<Scalars['Float']>;
};

export type TriggerApiCallPayload = Payload & {
  __typename?: 'TriggerApiCallPayload';
  api_call: PathwayCaseApiCall;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type TriggerDelayInput = {
  amount: Scalars['Int'];
  unit: DelayUnitType;
};

export type TriggerEventConfig = {
  __typename?: 'TriggerEventConfig';
  description: Scalars['String'];
  expression: Scalars['String'];
  shelly_thread_id: Scalars['String'];
  source: Scalars['String'];
  type: Scalars['String'];
};

export type TriggerEventSuggestion = {
  __typename?: 'TriggerEventSuggestion';
  source: Scalars['String'];
  type: Scalars['String'];
};

export type TriggerPayload = Payload & {
  __typename?: 'TriggerPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  trigger: Trigger;
};

export type TriggerSettings = {
  rule?: Maybe<Rule>;
  type?: Maybe<TriggerType>;
};

export enum TriggerTargetType {
  Pathway = 'PATHWAY',
  Track = 'TRACK'
}

export enum TriggerType {
  AdHoc = 'AD_HOC',
  DataPointCollected = 'DATA_POINT_COLLECTED',
  DataPointNotCollected = 'DATA_POINT_NOT_COLLECTED',
  EventBased = 'EVENT_BASED',
  ExtensionWebhook = 'EXTENSION_WEBHOOK',
  PathwayStarted = 'PATHWAY_STARTED',
  StepCompleted = 'STEP_COMPLETED',
  TrackCompleted = 'TRACK_COMPLETED'
}

export type UpdateApiCallMappingDataPointInput = {
  api_call_id: Scalars['String'];
  data_point_definition_id?: Maybe<Scalars['String']>;
  mapping_id: Scalars['String'];
};

export type UpdateApiCallMappingDataPointPayload = Payload & {
  __typename?: 'UpdateApiCallMappingDataPointPayload';
  api_call: ApiCall;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type UpdateApiCallMappingFirstMatchOnlyInput = {
  api_call_id: Scalars['String'];
  first_match_only: Scalars['Boolean'];
  mapping_id: Scalars['String'];
};

export type UpdateApiCallMappingFirstMatchOnlyPayload = Payload & {
  __typename?: 'UpdateApiCallMappingFirstMatchOnlyPayload';
  api_call: ApiCall;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type UpdateApiCallMappingKeyInput = {
  api_call_id: Scalars['String'];
  mapping_id: Scalars['String'];
  response_key: Scalars['String'];
};

export type UpdateApiCallMappingKeyPayload = Payload & {
  __typename?: 'UpdateApiCallMappingKeyPayload';
  api_call: ApiCall;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type UpdateCareFlowApiCallRetrySettingsInput = {
  api_call_retry_settings: ApiCallRetrySettingsInput;
  pathway_id: Scalars['String'];
};

export type UpdateCareFlowApiCallRetrySettingsPayload = Payload & {
  __typename?: 'UpdateCareFlowApiCallRetrySettingsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type UpdateCareFlowSourceControlSettingsInput = {
  pathway_id: Scalars['String'];
  source_control: SourceControlInput;
};

export type UpdateCareFlowSourceControlSettingsPayload = Payload & {
  __typename?: 'UpdateCareFlowSourceControlSettingsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type UpdateClinicalNoteContextInput = {
  clinical_note_id: Scalars['String'];
  context: Array<ClinicalNoteContextFieldInput>;
};

export type UpdateClinicalNoteContextPayload = Payload & {
  __typename?: 'UpdateClinicalNoteContextPayload';
  clinical_note: ClinicalNote;
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type UpdateClinicalNoteNarrativeBodyInput = {
  body: Scalars['String'];
  clinical_note_id: Scalars['String'];
  html_body: Scalars['String'];
  narrative_id: Scalars['ID'];
};

export type UpdateConstantInput = {
  constant_id: Scalars['String'];
  label: Scalars['String'];
  obfuscated: Scalars['Boolean'];
  pathway_id: Scalars['String'];
  readonly?: Maybe<Scalars['Boolean']>;
  value: Scalars['String'];
};

export type UpdateConstantPayload = Payload & {
  __typename?: 'UpdateConstantPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type UpdateDataPointDefinitionInput = {
  data_point_definition_id: Scalars['String'];
  fields: DataPointDefinitionInput;
  pathway_id: Scalars['String'];
};

export type UpdateDataPointDefinitionPayload = Payload & {
  __typename?: 'UpdateDataPointDefinitionPayload';
  code: Scalars['String'];
  data_point_definition: DataPointDefinition;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type UpdateDecisionInput = {
  decision: Scalars['String'];
  decision_id: Scalars['String'];
  outcomes: Array<Scalars['String']>;
};

export type UpdateDynamicVariableInput = {
  data_point_definition_id: Scalars['String'];
  data_point_property?: Maybe<DataPointPropertyType>;
  fallback: Scalars['String'];
  id: Scalars['String'];
  is_hyperlink?: Maybe<Scalars['Boolean']>;
  label: Scalars['String'];
  transformations: Array<TransformationInput>;
};

export type UpdateDynamicVariablePayload = Payload & {
  __typename?: 'UpdateDynamicVariablePayload';
  code: Scalars['String'];
  dynamic_variable: DynamicVariable;
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
};

export type UpdateEmailNotificationStatusInput = {
  allow_email_notifications: Scalars['Boolean'];
  pathway_id: Scalars['String'];
};

export type UpdateEmailNotificationStatusPayload = Payload & {
  __typename?: 'UpdateEmailNotificationStatusPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  settings: PathwaySettings;
  success: Scalars['Boolean'];
};

export type UpdateLabelTextInput = {
  label_id: Scalars['String'];
  pathway_id: Scalars['String'];
  text: Scalars['String'];
};

export type UpdateLabelTextPayload = Payload & {
  __typename?: 'UpdateLabelTextPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  label: Label;
  success: Scalars['Boolean'];
};

export type UpdateQuestionInput = {
  /** The ID of the form containing the question. */
  form_id: Scalars['String'];
  /** The new options for the question. */
  options?: Maybe<Array<OptionInput>>;
  /** The ID of the question to update. */
  question_id: Scalars['String'];
  /** The new title for the question. */
  title: Scalars['String'];
  /** The HTML version of the title for the question. */
  title_html?: Maybe<Scalars['String']>;
};

export type UpdateQuestionPayload = Payload & {
  __typename?: 'UpdateQuestionPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  question: Question;
  success: Scalars['Boolean'];
};

export type UpdateStickyNoteBodyInput = {
  body: StickyNoteBodyInput;
  sticky_note_id: Scalars['String'];
};

export type UpdateStickyNoteBodyPayload = Payload & {
  __typename?: 'UpdateStickyNoteBodyPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  stickyNote: StickyNote;
  success: Scalars['Boolean'];
};

export type UpdateTimerConfigInput = {
  data_point_reference_config?: Maybe<TimerDataPointReferenceInput>;
  delay_config?: Maybe<TimerDelayInput>;
  extension_resource_updated_config?: Maybe<TimerExtensionResourceUpdatedInput>;
  timer_id: Scalars['String'];
};

export type UpdateWebhookEndpointInput = {
  endpoint: Scalars['String'];
  pathway_id: Scalars['String'];
  webhook_id: Scalars['String'];
};

export type UpdateWebhookEndpointPayload = Payload & {
  __typename?: 'UpdateWebhookEndpointPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type UpdateWebhookHeadersInput = {
  headers?: Array<WebhookHeaderInput>;
  pathway_id: Scalars['String'];
  webhook_id: Scalars['String'];
};

export type UpdateWebhookHeadersPayload = Payload & {
  __typename?: 'UpdateWebhookHeadersPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type UpdateWebhookInput = {
  pathway_id: Scalars['String'];
  webhook: ExistingWebhookInput;
};

export type UpdateWebhookNameInput = {
  name: Scalars['String'];
  pathway_id: Scalars['String'];
  webhook_id: Scalars['String'];
};

export type UpdateWebhookNamePayload = Payload & {
  __typename?: 'UpdateWebhookNamePayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type UpdateWebhookPayload = Payload & {
  __typename?: 'UpdateWebhookPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type UpdateWebhookStatusInput = {
  enabled: Scalars['Boolean'];
  pathway_id: Scalars['String'];
  webhook_id: Scalars['String'];
};

export type UpdateWebhookStatusPayload = Payload & {
  __typename?: 'UpdateWebhookStatusPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type UpdateWebhookSubscribedEventsInput = {
  pathway_id: Scalars['String'];
  subscribed_events?: Maybe<Array<Scalars['String']>>;
  webhook_id: Scalars['String'];
};

export type UpdateWebhookSubscribedEventsPayload = Payload & {
  __typename?: 'UpdateWebhookSubscribedEventsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type UpdateWebhookTestEndpointInput = {
  endpoint_test: Scalars['String'];
  pathway_id: Scalars['String'];
  webhook_id: Scalars['String'];
};

export type UpdateWebhookTestEndpointPayload = Payload & {
  __typename?: 'UpdateWebhookTestEndpointPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  pathway: Pathway;
  success: Scalars['Boolean'];
};

export type UsageContext = {
  __typename?: 'UsageContext';
  id: Scalars['ID'];
  title: Scalars['String'];
};

/**
 * A user is a person who is going to login and interact with pathway studio.
 * A user can belong to multiple teams.
 *
 * A team is a:
 * - Group of users, and
 * - Group of resources i.e. Pathways and Forms
 *
 * Inside a team:
 * - Each user has a role or list of roles.
 * - Each user has access to the group of resources.
 *
 * A role is a group of permissions which define how users interact (i.e. create, read, update, delete) with each resource
 *
 * Example permission: "read:pathways", "create:forms"
 * Example role: "editor", "owner", "viewer"
 *
 * The model definitions look like this.
 *
 * Team: {
 *   id: string
 *   name: string
 * }
 *
 * TeamRelations {
 *   team_id: stirng
 *   team_name: string
 *   roles: [string]
 * }
 *
 * User: {
 *   id: string
 *   name: string
 *   ....
 *   teams: [TeamRelations]
 * }
 *
 * Pathway: {
 *   id: string
 *   ....
 *   teams: [{
 *     team_id: string
 *   }]
 * }
 */
export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  id: Scalars['ID'];
  intercom_hash: Scalars['String'];
  name: Scalars['String'];
  pending_invitation_id?: Maybe<Scalars['String']>;
  team?: Maybe<Team>;
  tenant?: Maybe<Tenant>;
};

export type UserPayload = Payload & {
  __typename?: 'UserPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  user: User;
};

export enum UserQuestionType {
  Date = 'DATE',
  Description = 'DESCRIPTION',
  Email = 'EMAIL',
  File = 'FILE',
  Icd10Classification = 'ICD10_CLASSIFICATION',
  Image = 'IMAGE',
  LongText = 'LONG_TEXT',
  MultipleChoice = 'MULTIPLE_CHOICE',
  MultipleChoiceGrid = 'MULTIPLE_CHOICE_GRID',
  MultipleSelect = 'MULTIPLE_SELECT',
  Number = 'NUMBER',
  ShortText = 'SHORT_TEXT',
  Signature = 'SIGNATURE',
  Slider = 'SLIDER',
  Telephone = 'TELEPHONE',
  YesNo = 'YES_NO'
}

export type UsesContextItem = {
  __typename?: 'UsesContextItem';
  definition_id: Scalars['String'];
  id: Scalars['String'];
  key?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export enum VersionStatus {
  Archived = 'Archived',
  Live = 'Live'
}

export type Webhook = {
  __typename?: 'Webhook';
  created_at: Scalars['SafeDate'];
  enabled: Scalars['Boolean'];
  endpoint: Scalars['String'];
  endpoint_test?: Maybe<Scalars['String']>;
  headers?: Maybe<Array<WebhookHeader>>;
  id: Scalars['String'];
  name: Scalars['String'];
  signing_key?: Maybe<Scalars['String']>;
  subscribed_events?: Maybe<Array<Scalars['String']>>;
};

export type WebhookCall = {
  __typename?: 'WebhookCall';
  created_at: Scalars['String'];
  event_type: Scalars['String'];
  id: Scalars['ID'];
  pathway?: Maybe<ApiPathwayContext>;
  request: WebhookCallRequest;
  responses: Array<WebhookCallResponse>;
  status: Scalars['String'];
  webhook_id: Scalars['String'];
  webhook_name: Scalars['String'];
};

export type WebhookCallHeader = {
  __typename?: 'WebhookCallHeader';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type WebhookCallRequest = {
  __typename?: 'WebhookCallRequest';
  body: Scalars['String'];
  endpoint: Scalars['String'];
  headers: Array<WebhookCallHeader>;
  method: Scalars['String'];
};

export type WebhookCallResponse = {
  __typename?: 'WebhookCallResponse';
  body: Scalars['String'];
  date: Scalars['String'];
  status: Scalars['Float'];
};

export type WebhookCallsDeleted = {
  __typename?: 'WebhookCallsDeleted';
  webhook_call_ids: Array<Scalars['String']>;
};

export type WebhookDataPointGraphQlType = {
  __typename?: 'WebhookDataPointGraphQLType';
  key: Scalars['String'];
  valueType: Scalars['String'];
};

export type WebhookEndpoint = {
  __typename?: 'WebhookEndpoint';
  created: CreatedByGraphQlType;
  data_points: Array<WebhookDataPointGraphQlType>;
  extension_slug?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  organization_slug: Scalars['String'];
  resources: Array<Scalars['String']>;
  status: Scalars['String'];
  tenant_id: Scalars['String'];
  type: Scalars['String'];
  url: Scalars['String'];
  webhook_key?: Maybe<Scalars['String']>;
};

export type WebhookEndpointsPayload = {
  __typename?: 'WebhookEndpointsPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  webhookEndpoints: Array<WebhookEndpoint>;
};

export type WebhookHeader = {
  __typename?: 'WebhookHeader';
  key: Scalars['String'];
  value: Scalars['String'];
};

export type WebhookHeaderInput = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type WebhookInput = {
  pathway_id: Scalars['String'];
  webhook_id?: Maybe<Scalars['String']>;
};

export type WebhookPayload = Payload & {
  __typename?: 'WebhookPayload';
  code: Scalars['String'];
  error?: Maybe<ErrorObject>;
  success: Scalars['Boolean'];
  webhook?: Maybe<Webhook>;
};

export type AddCustomActionToPathwayMutationVariables = Exact<{
  input: AddCustomActionToPathwayInput;
}>;


export type AddCustomActionToPathwayMutation = (
  { __typename?: 'Mutation' }
  & { addCustomActionToPathway: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type AddCustomFieldToPathwayMutationVariables = Exact<{
  input: AddCustomFieldToPathwayInput;
}>;


export type AddCustomFieldToPathwayMutation = (
  { __typename?: 'Mutation' }
  & { addCustomFieldToPathway: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type EventsQueryVariables = Exact<{
  input: EventsInput;
}>;


export type EventsQuery = (
  { __typename?: 'Query' }
  & { events: (
    { __typename?: 'DomainEventsPayload' }
    & { events: Array<(
      { __typename?: 'DomainEvent' }
      & Pick<DomainEvent, 'id' | 'stream' | 'event_type' | 'data'>
      & { metadata: (
        { __typename?: 'DomainEventMetadata' }
        & Pick<DomainEventMetadata, 'user_id' | 'caused_by' | 'saga_id' | 'timestamp' | 'version'>
      ) }
    )> }
  ) }
);

export type OnProjectionErrorSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnProjectionErrorSubscription = (
  { __typename?: 'Subscription' }
  & { projectionError: (
    { __typename?: 'RebuildViewModelError' }
    & Pick<RebuildViewModelError, 'id' | 'projection_name' | 'event'>
    & { error: (
      { __typename?: 'Error' }
      & Pick<Error, 'message' | 'stack'>
    ) }
  ) }
);

export type RebuildGraphsMutationVariables = Exact<{
  input: RebuildInput;
}>;


export type RebuildGraphsMutation = (
  { __typename?: 'Mutation' }
  & { rebuildGraphs: (
    { __typename?: 'RebuildViewModelsPayload' }
    & Pick<RebuildViewModelsPayload, 'event_count' | 'success'>
  ) }
);

export type RefreshDefinitionReferencesMutationVariables = Exact<{ [key: string]: never; }>;


export type RefreshDefinitionReferencesMutation = (
  { __typename?: 'Mutation' }
  & { refreshDefinitionReferences: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type OnEventReplayedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnEventReplayedSubscription = (
  { __typename?: 'Subscription' }
  & { eventReplayed: (
    { __typename?: 'RebuildViewModelStatus' }
    & Pick<RebuildViewModelStatus, 'event_count' | 'replayed_count' | 'event_id'>
  ) }
);

export type RepairPathwaysMutationVariables = Exact<{
  input: RepairPathwaysInput;
}>;


export type RepairPathwaysMutation = (
  { __typename?: 'Mutation' }
  & { repairPathways: (
    { __typename?: 'RepairPathwaysPayload' }
    & Pick<RepairPathwaysPayload, 'pathway_count'>
  ) }
);

export type OnPathwayRepairedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnPathwayRepairedSubscription = (
  { __typename?: 'Subscription' }
  & { pathwayRepaired: (
    { __typename?: 'RepairPathwaysStatus' }
    & Pick<RepairPathwaysStatus, 'pathway_count' | 'repaired_count'>
  ) }
);

export type TransferPathwayMutationVariables = Exact<{
  input: TransferPathwayInput;
}>;


export type TransferPathwayMutation = (
  { __typename?: 'Mutation' }
  & { transferPathway: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type ExportPathwayMutationVariables = Exact<{
  input: ExportPathwayInput;
}>;


export type ExportPathwayMutation = (
  { __typename?: 'Mutation' }
  & { exportPathway: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type OnExportPathwayStatusUpdateSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnExportPathwayStatusUpdateSubscription = (
  { __typename?: 'Subscription' }
  & { onExportPathwayStatusUpdate: (
    { __typename?: 'ExportPathwayStatus' }
    & Pick<ExportPathwayStatus, 'message' | 'url' | 'content'>
    & { count?: Maybe<(
      { __typename?: 'ExportPathwayEventsCount' }
      & Pick<ExportPathwayEventsCount, 'event_count' | 'exported_count'>
    )> }
  ) }
);

export type ListFormsQueryVariables = Exact<{ [key: string]: never; }>;


export type ListFormsQuery = (
  { __typename?: 'Query' }
  & { listForms: (
    { __typename?: 'FormsPayload' }
    & { forms: Array<(
      { __typename?: 'Form' }
      & Pick<Form, 'id' | 'title'>
    )> }
  ) }
);

export type ResetTeamMutationVariables = Exact<{
  input: MassResetTeamInput;
}>;


export type ResetTeamMutation = (
  { __typename?: 'Mutation' }
  & { massResetTeam: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type ImportPathwayMutationVariables = Exact<{
  input: ImportPathwayInput;
}>;


export type ImportPathwayMutation = (
  { __typename?: 'Mutation' }
  & { importPathway: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type OnImportPathwayStatusUpdateSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type OnImportPathwayStatusUpdateSubscription = (
  { __typename?: 'Subscription' }
  & { onImportPathwayStatusUpdate: (
    { __typename?: 'ImportPathwayStatus' }
    & Pick<ImportPathwayStatus, 'message'>
    & { count?: Maybe<(
      { __typename?: 'ImportPathwayEventsCount' }
      & Pick<ImportPathwayEventsCount, 'event_count' | 'imported_count' | 'event_id'>
    )> }
  ) }
);

export type ListPathwaysQueryVariables = Exact<{ [key: string]: never; }>;


export type ListPathwaysQuery = (
  { __typename?: 'Query' }
  & { listPathways: (
    { __typename?: 'PathwaysPayload' }
    & { pathways: Array<(
      { __typename?: 'Pathway' }
      & Pick<Pathway, 'id' | 'title'>
    )> }
  ) }
);

export type ClearViewModelsMutationVariables = Exact<{ [key: string]: never; }>;


export type ClearViewModelsMutation = (
  { __typename?: 'Mutation' }
  & { clearViewModels: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type RebuildViewModelsMutationVariables = Exact<{
  input: RebuildInput;
}>;


export type RebuildViewModelsMutation = (
  { __typename?: 'Mutation' }
  & { rebuildViewModels: (
    { __typename?: 'RebuildViewModelsPayload' }
    & Pick<RebuildViewModelsPayload, 'event_count' | 'success'>
  ) }
);

export type RecomputeCalculationDataSourcesMutationVariables = Exact<{ [key: string]: never; }>;


export type RecomputeCalculationDataSourcesMutation = (
  { __typename?: 'Mutation' }
  & { recomputeCalculationDataSources: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type ResetPatientProfileDataPointDefinitionsMutationVariables = Exact<{
  input: ResetPatientProfileDataPointDefinitionsInput;
}>;


export type ResetPatientProfileDataPointDefinitionsMutation = (
  { __typename?: 'Mutation' }
  & { resetPatientProfileDataPointDefinitions: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type ShareFormsMutationVariables = Exact<{
  input: ShareFormsInput;
}>;


export type ShareFormsMutation = (
  { __typename?: 'Mutation' }
  & { shareForms: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type SharePathwaysMutationVariables = Exact<{
  input: SharePathwaysInput;
}>;


export type SharePathwaysMutation = (
  { __typename?: 'Mutation' }
  & { sharePathways: (
    { __typename?: 'EmptyPayload' }
    & Pick<EmptyPayload, 'success'>
  ) }
);

export type GetTenantsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTenantsQuery = (
  { __typename?: 'Query' }
  & { tenants: (
    { __typename?: 'TenantsPayload' }
    & { tenants: Array<(
      { __typename?: 'Tenant' }
      & Pick<Tenant, 'id' | 'name'>
    )> }
  ) }
);


export const AddCustomActionToPathwayDocument = gql`
    mutation AddCustomActionToPathway($input: AddCustomActionToPathwayInput!) {
  addCustomActionToPathway(input: $input) {
    success
  }
}
    `;
export type AddCustomActionToPathwayMutationFn = Apollo.MutationFunction<AddCustomActionToPathwayMutation, AddCustomActionToPathwayMutationVariables>;

/**
 * __useAddCustomActionToPathwayMutation__
 *
 * To run a mutation, you first call `useAddCustomActionToPathwayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustomActionToPathwayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomActionToPathwayMutation, { data, loading, error }] = useAddCustomActionToPathwayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCustomActionToPathwayMutation(baseOptions?: Apollo.MutationHookOptions<AddCustomActionToPathwayMutation, AddCustomActionToPathwayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCustomActionToPathwayMutation, AddCustomActionToPathwayMutationVariables>(AddCustomActionToPathwayDocument, options);
      }
export type AddCustomActionToPathwayMutationHookResult = ReturnType<typeof useAddCustomActionToPathwayMutation>;
export type AddCustomActionToPathwayMutationResult = Apollo.MutationResult<AddCustomActionToPathwayMutation>;
export type AddCustomActionToPathwayMutationOptions = Apollo.BaseMutationOptions<AddCustomActionToPathwayMutation, AddCustomActionToPathwayMutationVariables>;
export const AddCustomFieldToPathwayDocument = gql`
    mutation AddCustomFieldToPathway($input: AddCustomFieldToPathwayInput!) {
  addCustomFieldToPathway(input: $input) {
    success
  }
}
    `;
export type AddCustomFieldToPathwayMutationFn = Apollo.MutationFunction<AddCustomFieldToPathwayMutation, AddCustomFieldToPathwayMutationVariables>;

/**
 * __useAddCustomFieldToPathwayMutation__
 *
 * To run a mutation, you first call `useAddCustomFieldToPathwayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCustomFieldToPathwayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCustomFieldToPathwayMutation, { data, loading, error }] = useAddCustomFieldToPathwayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCustomFieldToPathwayMutation(baseOptions?: Apollo.MutationHookOptions<AddCustomFieldToPathwayMutation, AddCustomFieldToPathwayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCustomFieldToPathwayMutation, AddCustomFieldToPathwayMutationVariables>(AddCustomFieldToPathwayDocument, options);
      }
export type AddCustomFieldToPathwayMutationHookResult = ReturnType<typeof useAddCustomFieldToPathwayMutation>;
export type AddCustomFieldToPathwayMutationResult = Apollo.MutationResult<AddCustomFieldToPathwayMutation>;
export type AddCustomFieldToPathwayMutationOptions = Apollo.BaseMutationOptions<AddCustomFieldToPathwayMutation, AddCustomFieldToPathwayMutationVariables>;
export const EventsDocument = gql`
    query Events($input: EventsInput!) {
  events(input: $input) {
    events {
      id
      stream
      event_type
      data
      metadata {
        user_id
        caused_by
        saga_id
        timestamp
        version
      }
    }
  }
}
    `;

/**
 * __useEventsQuery__
 *
 * To run a query within a React component, call `useEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEventsQuery(baseOptions: Apollo.QueryHookOptions<EventsQuery, EventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
      }
export function useEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventsQuery, EventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventsQuery, EventsQueryVariables>(EventsDocument, options);
        }
export type EventsQueryHookResult = ReturnType<typeof useEventsQuery>;
export type EventsLazyQueryHookResult = ReturnType<typeof useEventsLazyQuery>;
export type EventsQueryResult = Apollo.QueryResult<EventsQuery, EventsQueryVariables>;
export const OnProjectionErrorDocument = gql`
    subscription OnProjectionError {
  projectionError {
    id
    projection_name
    event
    error {
      message
      stack
    }
  }
}
    `;

/**
 * __useOnProjectionErrorSubscription__
 *
 * To run a query within a React component, call `useOnProjectionErrorSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnProjectionErrorSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnProjectionErrorSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnProjectionErrorSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnProjectionErrorSubscription, OnProjectionErrorSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnProjectionErrorSubscription, OnProjectionErrorSubscriptionVariables>(OnProjectionErrorDocument, options);
      }
export type OnProjectionErrorSubscriptionHookResult = ReturnType<typeof useOnProjectionErrorSubscription>;
export type OnProjectionErrorSubscriptionResult = Apollo.SubscriptionResult<OnProjectionErrorSubscription>;
export const RebuildGraphsDocument = gql`
    mutation RebuildGraphs($input: RebuildInput!) {
  rebuildGraphs(input: $input) {
    event_count
    success
  }
}
    `;
export type RebuildGraphsMutationFn = Apollo.MutationFunction<RebuildGraphsMutation, RebuildGraphsMutationVariables>;

/**
 * __useRebuildGraphsMutation__
 *
 * To run a mutation, you first call `useRebuildGraphsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRebuildGraphsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rebuildGraphsMutation, { data, loading, error }] = useRebuildGraphsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRebuildGraphsMutation(baseOptions?: Apollo.MutationHookOptions<RebuildGraphsMutation, RebuildGraphsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RebuildGraphsMutation, RebuildGraphsMutationVariables>(RebuildGraphsDocument, options);
      }
export type RebuildGraphsMutationHookResult = ReturnType<typeof useRebuildGraphsMutation>;
export type RebuildGraphsMutationResult = Apollo.MutationResult<RebuildGraphsMutation>;
export type RebuildGraphsMutationOptions = Apollo.BaseMutationOptions<RebuildGraphsMutation, RebuildGraphsMutationVariables>;
export const RefreshDefinitionReferencesDocument = gql`
    mutation RefreshDefinitionReferences {
  refreshDefinitionReferences {
    success
  }
}
    `;
export type RefreshDefinitionReferencesMutationFn = Apollo.MutationFunction<RefreshDefinitionReferencesMutation, RefreshDefinitionReferencesMutationVariables>;

/**
 * __useRefreshDefinitionReferencesMutation__
 *
 * To run a mutation, you first call `useRefreshDefinitionReferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshDefinitionReferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshDefinitionReferencesMutation, { data, loading, error }] = useRefreshDefinitionReferencesMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshDefinitionReferencesMutation(baseOptions?: Apollo.MutationHookOptions<RefreshDefinitionReferencesMutation, RefreshDefinitionReferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshDefinitionReferencesMutation, RefreshDefinitionReferencesMutationVariables>(RefreshDefinitionReferencesDocument, options);
      }
export type RefreshDefinitionReferencesMutationHookResult = ReturnType<typeof useRefreshDefinitionReferencesMutation>;
export type RefreshDefinitionReferencesMutationResult = Apollo.MutationResult<RefreshDefinitionReferencesMutation>;
export type RefreshDefinitionReferencesMutationOptions = Apollo.BaseMutationOptions<RefreshDefinitionReferencesMutation, RefreshDefinitionReferencesMutationVariables>;
export const OnEventReplayedDocument = gql`
    subscription OnEventReplayed {
  eventReplayed {
    event_count
    replayed_count
    event_id
  }
}
    `;

/**
 * __useOnEventReplayedSubscription__
 *
 * To run a query within a React component, call `useOnEventReplayedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnEventReplayedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnEventReplayedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnEventReplayedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnEventReplayedSubscription, OnEventReplayedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnEventReplayedSubscription, OnEventReplayedSubscriptionVariables>(OnEventReplayedDocument, options);
      }
export type OnEventReplayedSubscriptionHookResult = ReturnType<typeof useOnEventReplayedSubscription>;
export type OnEventReplayedSubscriptionResult = Apollo.SubscriptionResult<OnEventReplayedSubscription>;
export const RepairPathwaysDocument = gql`
    mutation RepairPathways($input: RepairPathwaysInput!) {
  repairPathways(input: $input) {
    pathway_count
  }
}
    `;
export type RepairPathwaysMutationFn = Apollo.MutationFunction<RepairPathwaysMutation, RepairPathwaysMutationVariables>;

/**
 * __useRepairPathwaysMutation__
 *
 * To run a mutation, you first call `useRepairPathwaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRepairPathwaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [repairPathwaysMutation, { data, loading, error }] = useRepairPathwaysMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRepairPathwaysMutation(baseOptions?: Apollo.MutationHookOptions<RepairPathwaysMutation, RepairPathwaysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RepairPathwaysMutation, RepairPathwaysMutationVariables>(RepairPathwaysDocument, options);
      }
export type RepairPathwaysMutationHookResult = ReturnType<typeof useRepairPathwaysMutation>;
export type RepairPathwaysMutationResult = Apollo.MutationResult<RepairPathwaysMutation>;
export type RepairPathwaysMutationOptions = Apollo.BaseMutationOptions<RepairPathwaysMutation, RepairPathwaysMutationVariables>;
export const OnPathwayRepairedDocument = gql`
    subscription OnPathwayRepaired {
  pathwayRepaired {
    pathway_count
    repaired_count
  }
}
    `;

/**
 * __useOnPathwayRepairedSubscription__
 *
 * To run a query within a React component, call `useOnPathwayRepairedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnPathwayRepairedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnPathwayRepairedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnPathwayRepairedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnPathwayRepairedSubscription, OnPathwayRepairedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnPathwayRepairedSubscription, OnPathwayRepairedSubscriptionVariables>(OnPathwayRepairedDocument, options);
      }
export type OnPathwayRepairedSubscriptionHookResult = ReturnType<typeof useOnPathwayRepairedSubscription>;
export type OnPathwayRepairedSubscriptionResult = Apollo.SubscriptionResult<OnPathwayRepairedSubscription>;
export const TransferPathwayDocument = gql`
    mutation TransferPathway($input: TransferPathwayInput!) {
  transferPathway(input: $input) {
    success
  }
}
    `;
export type TransferPathwayMutationFn = Apollo.MutationFunction<TransferPathwayMutation, TransferPathwayMutationVariables>;

/**
 * __useTransferPathwayMutation__
 *
 * To run a mutation, you first call `useTransferPathwayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferPathwayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferPathwayMutation, { data, loading, error }] = useTransferPathwayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTransferPathwayMutation(baseOptions?: Apollo.MutationHookOptions<TransferPathwayMutation, TransferPathwayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TransferPathwayMutation, TransferPathwayMutationVariables>(TransferPathwayDocument, options);
      }
export type TransferPathwayMutationHookResult = ReturnType<typeof useTransferPathwayMutation>;
export type TransferPathwayMutationResult = Apollo.MutationResult<TransferPathwayMutation>;
export type TransferPathwayMutationOptions = Apollo.BaseMutationOptions<TransferPathwayMutation, TransferPathwayMutationVariables>;
export const ExportPathwayDocument = gql`
    mutation ExportPathway($input: ExportPathwayInput!) {
  exportPathway(input: $input) {
    success
  }
}
    `;
export type ExportPathwayMutationFn = Apollo.MutationFunction<ExportPathwayMutation, ExportPathwayMutationVariables>;

/**
 * __useExportPathwayMutation__
 *
 * To run a mutation, you first call `useExportPathwayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportPathwayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportPathwayMutation, { data, loading, error }] = useExportPathwayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useExportPathwayMutation(baseOptions?: Apollo.MutationHookOptions<ExportPathwayMutation, ExportPathwayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportPathwayMutation, ExportPathwayMutationVariables>(ExportPathwayDocument, options);
      }
export type ExportPathwayMutationHookResult = ReturnType<typeof useExportPathwayMutation>;
export type ExportPathwayMutationResult = Apollo.MutationResult<ExportPathwayMutation>;
export type ExportPathwayMutationOptions = Apollo.BaseMutationOptions<ExportPathwayMutation, ExportPathwayMutationVariables>;
export const OnExportPathwayStatusUpdateDocument = gql`
    subscription OnExportPathwayStatusUpdate {
  onExportPathwayStatusUpdate {
    message
    url
    content
    count {
      event_count
      exported_count
    }
  }
}
    `;

/**
 * __useOnExportPathwayStatusUpdateSubscription__
 *
 * To run a query within a React component, call `useOnExportPathwayStatusUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnExportPathwayStatusUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnExportPathwayStatusUpdateSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnExportPathwayStatusUpdateSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnExportPathwayStatusUpdateSubscription, OnExportPathwayStatusUpdateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnExportPathwayStatusUpdateSubscription, OnExportPathwayStatusUpdateSubscriptionVariables>(OnExportPathwayStatusUpdateDocument, options);
      }
export type OnExportPathwayStatusUpdateSubscriptionHookResult = ReturnType<typeof useOnExportPathwayStatusUpdateSubscription>;
export type OnExportPathwayStatusUpdateSubscriptionResult = Apollo.SubscriptionResult<OnExportPathwayStatusUpdateSubscription>;
export const ListFormsDocument = gql`
    query ListForms {
  listForms {
    forms {
      id
      title
    }
  }
}
    `;

/**
 * __useListFormsQuery__
 *
 * To run a query within a React component, call `useListFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListFormsQuery({
 *   variables: {
 *   },
 * });
 */
export function useListFormsQuery(baseOptions?: Apollo.QueryHookOptions<ListFormsQuery, ListFormsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListFormsQuery, ListFormsQueryVariables>(ListFormsDocument, options);
      }
export function useListFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListFormsQuery, ListFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListFormsQuery, ListFormsQueryVariables>(ListFormsDocument, options);
        }
export type ListFormsQueryHookResult = ReturnType<typeof useListFormsQuery>;
export type ListFormsLazyQueryHookResult = ReturnType<typeof useListFormsLazyQuery>;
export type ListFormsQueryResult = Apollo.QueryResult<ListFormsQuery, ListFormsQueryVariables>;
export const ResetTeamDocument = gql`
    mutation ResetTeam($input: MassResetTeamInput!) {
  massResetTeam(input: $input) {
    success
  }
}
    `;
export type ResetTeamMutationFn = Apollo.MutationFunction<ResetTeamMutation, ResetTeamMutationVariables>;

/**
 * __useResetTeamMutation__
 *
 * To run a mutation, you first call `useResetTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetTeamMutation, { data, loading, error }] = useResetTeamMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetTeamMutation(baseOptions?: Apollo.MutationHookOptions<ResetTeamMutation, ResetTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetTeamMutation, ResetTeamMutationVariables>(ResetTeamDocument, options);
      }
export type ResetTeamMutationHookResult = ReturnType<typeof useResetTeamMutation>;
export type ResetTeamMutationResult = Apollo.MutationResult<ResetTeamMutation>;
export type ResetTeamMutationOptions = Apollo.BaseMutationOptions<ResetTeamMutation, ResetTeamMutationVariables>;
export const ImportPathwayDocument = gql`
    mutation ImportPathway($input: ImportPathwayInput!) {
  importPathway(input: $input) {
    success
  }
}
    `;
export type ImportPathwayMutationFn = Apollo.MutationFunction<ImportPathwayMutation, ImportPathwayMutationVariables>;

/**
 * __useImportPathwayMutation__
 *
 * To run a mutation, you first call `useImportPathwayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportPathwayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importPathwayMutation, { data, loading, error }] = useImportPathwayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useImportPathwayMutation(baseOptions?: Apollo.MutationHookOptions<ImportPathwayMutation, ImportPathwayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportPathwayMutation, ImportPathwayMutationVariables>(ImportPathwayDocument, options);
      }
export type ImportPathwayMutationHookResult = ReturnType<typeof useImportPathwayMutation>;
export type ImportPathwayMutationResult = Apollo.MutationResult<ImportPathwayMutation>;
export type ImportPathwayMutationOptions = Apollo.BaseMutationOptions<ImportPathwayMutation, ImportPathwayMutationVariables>;
export const OnImportPathwayStatusUpdateDocument = gql`
    subscription OnImportPathwayStatusUpdate {
  onImportPathwayStatusUpdate {
    message
    count {
      event_count
      imported_count
      event_id
    }
  }
}
    `;

/**
 * __useOnImportPathwayStatusUpdateSubscription__
 *
 * To run a query within a React component, call `useOnImportPathwayStatusUpdateSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnImportPathwayStatusUpdateSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnImportPathwayStatusUpdateSubscription({
 *   variables: {
 *   },
 * });
 */
export function useOnImportPathwayStatusUpdateSubscription(baseOptions?: Apollo.SubscriptionHookOptions<OnImportPathwayStatusUpdateSubscription, OnImportPathwayStatusUpdateSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnImportPathwayStatusUpdateSubscription, OnImportPathwayStatusUpdateSubscriptionVariables>(OnImportPathwayStatusUpdateDocument, options);
      }
export type OnImportPathwayStatusUpdateSubscriptionHookResult = ReturnType<typeof useOnImportPathwayStatusUpdateSubscription>;
export type OnImportPathwayStatusUpdateSubscriptionResult = Apollo.SubscriptionResult<OnImportPathwayStatusUpdateSubscription>;
export const ListPathwaysDocument = gql`
    query ListPathways {
  listPathways {
    pathways {
      id
      title
    }
  }
}
    `;

/**
 * __useListPathwaysQuery__
 *
 * To run a query within a React component, call `useListPathwaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useListPathwaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListPathwaysQuery({
 *   variables: {
 *   },
 * });
 */
export function useListPathwaysQuery(baseOptions?: Apollo.QueryHookOptions<ListPathwaysQuery, ListPathwaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListPathwaysQuery, ListPathwaysQueryVariables>(ListPathwaysDocument, options);
      }
export function useListPathwaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListPathwaysQuery, ListPathwaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListPathwaysQuery, ListPathwaysQueryVariables>(ListPathwaysDocument, options);
        }
export type ListPathwaysQueryHookResult = ReturnType<typeof useListPathwaysQuery>;
export type ListPathwaysLazyQueryHookResult = ReturnType<typeof useListPathwaysLazyQuery>;
export type ListPathwaysQueryResult = Apollo.QueryResult<ListPathwaysQuery, ListPathwaysQueryVariables>;
export const ClearViewModelsDocument = gql`
    mutation ClearViewModels {
  clearViewModels {
    success
  }
}
    `;
export type ClearViewModelsMutationFn = Apollo.MutationFunction<ClearViewModelsMutation, ClearViewModelsMutationVariables>;

/**
 * __useClearViewModelsMutation__
 *
 * To run a mutation, you first call `useClearViewModelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearViewModelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearViewModelsMutation, { data, loading, error }] = useClearViewModelsMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearViewModelsMutation(baseOptions?: Apollo.MutationHookOptions<ClearViewModelsMutation, ClearViewModelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearViewModelsMutation, ClearViewModelsMutationVariables>(ClearViewModelsDocument, options);
      }
export type ClearViewModelsMutationHookResult = ReturnType<typeof useClearViewModelsMutation>;
export type ClearViewModelsMutationResult = Apollo.MutationResult<ClearViewModelsMutation>;
export type ClearViewModelsMutationOptions = Apollo.BaseMutationOptions<ClearViewModelsMutation, ClearViewModelsMutationVariables>;
export const RebuildViewModelsDocument = gql`
    mutation RebuildViewModels($input: RebuildInput!) {
  rebuildViewModels(input: $input) {
    event_count
    success
  }
}
    `;
export type RebuildViewModelsMutationFn = Apollo.MutationFunction<RebuildViewModelsMutation, RebuildViewModelsMutationVariables>;

/**
 * __useRebuildViewModelsMutation__
 *
 * To run a mutation, you first call `useRebuildViewModelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRebuildViewModelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rebuildViewModelsMutation, { data, loading, error }] = useRebuildViewModelsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRebuildViewModelsMutation(baseOptions?: Apollo.MutationHookOptions<RebuildViewModelsMutation, RebuildViewModelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RebuildViewModelsMutation, RebuildViewModelsMutationVariables>(RebuildViewModelsDocument, options);
      }
export type RebuildViewModelsMutationHookResult = ReturnType<typeof useRebuildViewModelsMutation>;
export type RebuildViewModelsMutationResult = Apollo.MutationResult<RebuildViewModelsMutation>;
export type RebuildViewModelsMutationOptions = Apollo.BaseMutationOptions<RebuildViewModelsMutation, RebuildViewModelsMutationVariables>;
export const RecomputeCalculationDataSourcesDocument = gql`
    mutation RecomputeCalculationDataSources {
  recomputeCalculationDataSources {
    success
  }
}
    `;
export type RecomputeCalculationDataSourcesMutationFn = Apollo.MutationFunction<RecomputeCalculationDataSourcesMutation, RecomputeCalculationDataSourcesMutationVariables>;

/**
 * __useRecomputeCalculationDataSourcesMutation__
 *
 * To run a mutation, you first call `useRecomputeCalculationDataSourcesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecomputeCalculationDataSourcesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recomputeCalculationDataSourcesMutation, { data, loading, error }] = useRecomputeCalculationDataSourcesMutation({
 *   variables: {
 *   },
 * });
 */
export function useRecomputeCalculationDataSourcesMutation(baseOptions?: Apollo.MutationHookOptions<RecomputeCalculationDataSourcesMutation, RecomputeCalculationDataSourcesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecomputeCalculationDataSourcesMutation, RecomputeCalculationDataSourcesMutationVariables>(RecomputeCalculationDataSourcesDocument, options);
      }
export type RecomputeCalculationDataSourcesMutationHookResult = ReturnType<typeof useRecomputeCalculationDataSourcesMutation>;
export type RecomputeCalculationDataSourcesMutationResult = Apollo.MutationResult<RecomputeCalculationDataSourcesMutation>;
export type RecomputeCalculationDataSourcesMutationOptions = Apollo.BaseMutationOptions<RecomputeCalculationDataSourcesMutation, RecomputeCalculationDataSourcesMutationVariables>;
export const ResetPatientProfileDataPointDefinitionsDocument = gql`
    mutation ResetPatientProfileDataPointDefinitions($input: ResetPatientProfileDataPointDefinitionsInput!) {
  resetPatientProfileDataPointDefinitions(input: $input) {
    success
  }
}
    `;
export type ResetPatientProfileDataPointDefinitionsMutationFn = Apollo.MutationFunction<ResetPatientProfileDataPointDefinitionsMutation, ResetPatientProfileDataPointDefinitionsMutationVariables>;

/**
 * __useResetPatientProfileDataPointDefinitionsMutation__
 *
 * To run a mutation, you first call `useResetPatientProfileDataPointDefinitionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPatientProfileDataPointDefinitionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPatientProfileDataPointDefinitionsMutation, { data, loading, error }] = useResetPatientProfileDataPointDefinitionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPatientProfileDataPointDefinitionsMutation(baseOptions?: Apollo.MutationHookOptions<ResetPatientProfileDataPointDefinitionsMutation, ResetPatientProfileDataPointDefinitionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPatientProfileDataPointDefinitionsMutation, ResetPatientProfileDataPointDefinitionsMutationVariables>(ResetPatientProfileDataPointDefinitionsDocument, options);
      }
export type ResetPatientProfileDataPointDefinitionsMutationHookResult = ReturnType<typeof useResetPatientProfileDataPointDefinitionsMutation>;
export type ResetPatientProfileDataPointDefinitionsMutationResult = Apollo.MutationResult<ResetPatientProfileDataPointDefinitionsMutation>;
export type ResetPatientProfileDataPointDefinitionsMutationOptions = Apollo.BaseMutationOptions<ResetPatientProfileDataPointDefinitionsMutation, ResetPatientProfileDataPointDefinitionsMutationVariables>;
export const ShareFormsDocument = gql`
    mutation ShareForms($input: ShareFormsInput!) {
  shareForms(input: $input) {
    success
  }
}
    `;
export type ShareFormsMutationFn = Apollo.MutationFunction<ShareFormsMutation, ShareFormsMutationVariables>;

/**
 * __useShareFormsMutation__
 *
 * To run a mutation, you first call `useShareFormsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareFormsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareFormsMutation, { data, loading, error }] = useShareFormsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareFormsMutation(baseOptions?: Apollo.MutationHookOptions<ShareFormsMutation, ShareFormsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ShareFormsMutation, ShareFormsMutationVariables>(ShareFormsDocument, options);
      }
export type ShareFormsMutationHookResult = ReturnType<typeof useShareFormsMutation>;
export type ShareFormsMutationResult = Apollo.MutationResult<ShareFormsMutation>;
export type ShareFormsMutationOptions = Apollo.BaseMutationOptions<ShareFormsMutation, ShareFormsMutationVariables>;
export const SharePathwaysDocument = gql`
    mutation SharePathways($input: SharePathwaysInput!) {
  sharePathways(input: $input) {
    success
  }
}
    `;
export type SharePathwaysMutationFn = Apollo.MutationFunction<SharePathwaysMutation, SharePathwaysMutationVariables>;

/**
 * __useSharePathwaysMutation__
 *
 * To run a mutation, you first call `useSharePathwaysMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSharePathwaysMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sharePathwaysMutation, { data, loading, error }] = useSharePathwaysMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSharePathwaysMutation(baseOptions?: Apollo.MutationHookOptions<SharePathwaysMutation, SharePathwaysMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SharePathwaysMutation, SharePathwaysMutationVariables>(SharePathwaysDocument, options);
      }
export type SharePathwaysMutationHookResult = ReturnType<typeof useSharePathwaysMutation>;
export type SharePathwaysMutationResult = Apollo.MutationResult<SharePathwaysMutation>;
export type SharePathwaysMutationOptions = Apollo.BaseMutationOptions<SharePathwaysMutation, SharePathwaysMutationVariables>;
export const GetTenantsDocument = gql`
    query GetTenants {
  tenants {
    tenants {
      id
      name
    }
  }
}
    `;

/**
 * __useGetTenantsQuery__
 *
 * To run a query within a React component, call `useGetTenantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTenantsQuery(baseOptions?: Apollo.QueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTenantsQuery, GetTenantsQueryVariables>(GetTenantsDocument, options);
      }
export function useGetTenantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTenantsQuery, GetTenantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTenantsQuery, GetTenantsQueryVariables>(GetTenantsDocument, options);
        }
export type GetTenantsQueryHookResult = ReturnType<typeof useGetTenantsQuery>;
export type GetTenantsLazyQueryHookResult = ReturnType<typeof useGetTenantsLazyQuery>;
export type GetTenantsQueryResult = Apollo.QueryResult<GetTenantsQuery, GetTenantsQueryVariables>;